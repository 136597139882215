import { get } from 'config/api/config';

const UTILITY_ENDPOINT = '/viewer/utility';

interface UtilityResponse {
  name: string;
}
/**
 * GET utilities
 * @returns
 */
export const getUtilities = async (responseId: string) => {
  const response = await get<UtilityResponse[]>(
    `${UTILITY_ENDPOINT}/${responseId}`,
  );
  return response?.data;
};
