import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import {
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import _ from 'lodash';

import { TermsAndConditions } from 'assets/files';

const footerLinks = [
  { label: 'Terms & Conditions', route: '#' },
  { label: 'Privacy', route: '#' },
  { label: 'Help', route: '#' },
];

function FooterLinks() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [pageCount, setPageCount] = useState<number>();

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    if (pageCount !== numPages) {
      setPageCount(numPages);
    }
  }

  return (
    <>
      <Flex fontSize="12px" color="blue.500" gap={4} bottom={0}>
        {footerLinks.map(({ label, route }) => (
          <span key={label}>
            <Link
              href={route}
              onClick={label.startsWith('Terms') ? onOpen : () => {}}
            >
              {label}
            </Link>
          </span>
        ))}
      </Flex>
      <Modal
        onClose={onClose}
        size="4xl"
        isOpen={isOpen}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent backgroundColor="white" overflow="hidden">
          <ModalHeader
            backgroundColor="teal.600"
            color="white"
            justifyContent="center"
            alignItems="center"
          >
            <Text size="lg">Terms and Conditions</Text>
          </ModalHeader>
          <ModalCloseButton
            position="absolute"
            right="4"
            top="4"
            color="white.base"
          />

          <ModalBody>
            <Document
              file={TermsAndConditions}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {_.times(pageCount ?? 0, (p) => (
                <Page
                  width={750}
                  renderMode="canvas"
                  pageNumber={p + 1}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                />
              ))}
            </Document>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default FooterLinks;
