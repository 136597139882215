"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentType = void 0;
var PaymentType = /** @class */ (function () {
    function PaymentType() {
        this.id = 0;
        this.name = '';
        this.maxPaymentAmount = 0;
        this.fee = 0;
        this.maxPaymentAmount = 0;
        this.requireCvv = true;
        this.requireBusinessAccount = true;
    }
    return PaymentType;
}());
exports.PaymentType = PaymentType;
