/* istanbul ignore file */
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Flex, List, ListIcon, ListItem, Text } from '@chakra-ui/react';
import { Page, PageType } from '@common/entities';
import { NotificationStatus } from 'enums/notification-status';
import { RootState } from 'state/store';

import { Alert, CheckMark, Circle } from 'components/custom-icons';
import notification from 'components/notification';
import {
  useGetActiveStepIndex,
  useSaveBlankPageData,
  useSaveOptionsPageData,
} from 'hooks';
import { useSaveFloorPlanPageData } from 'hooks/floor-plans';
import { useSaveQuote } from 'hooks/quotes';

interface StepNavigatorProps {
  filteredPages: Page[];
  onClose: any;
}

const ApplicationNavigation: React.FC<StepNavigatorProps> = ({
  filteredPages,
  onClose,
}) => {
  const navigate = useNavigate();
  const getActiveStepIndex = useGetActiveStepIndex();
  const activeStepIndex = getActiveStepIndex();
  const { orgId, propertyId } = useParams();

  const { leasingJourneyErrors } = useSelector(
    (state: RootState) => state.navigation,
  );

  const { formData, addOns, selectedSpecials } = useSelector((state: RootState) => ({
    formData: state.application.form,
    addOns: state.unitSelection.addOns,
    selectedSpecials: state.unitSelection.selectedSpecials,
  }));

  const { pages } = formData;

  const { trigger } = useFormContext();

  const { saveBlankPage } = useSaveBlankPageData({
    activeStepIndex,
  });

  const saveOptionsPage = useSaveOptionsPageData({
    activeStepIndex,
  });

  const saveFloorPlanPage = useSaveFloorPlanPageData({
    activeStepIndex,
  });

  const saveQuotesPage = useSaveQuote({
    activeStepIndex,
  });

  /**
   * Set error based on page type
   * @param isMovingForward
   * @returns
   */
  async function savePageResponses(newPageIndex: number) {
    await trigger();
    const currentStep = pages[activeStepIndex];

    let response;
    switch (currentStep?.type) {
      case PageType.Blank:
        response = await saveBlankPage(newPageIndex);
        return response;
      case PageType.Summary:
        // response = await checkSummaryPage(newPageIndex);
        return response;
      case PageType.Options:
        await saveOptionsPage(newPageIndex, addOns, selectedSpecials);
        return true;
      case PageType.FloorPlans:
        response = await saveFloorPlanPage(newPageIndex);
        return response;
      case PageType.Quotes:
        await saveQuotesPage(newPageIndex);
        return true;
      default:
        return true;
    }
  }

  async function onStepClick(targetPageId: string, newPageIndex: number) {
    const activePageIndex = getActiveStepIndex();
    if (activePageIndex === newPageIndex) return;

    const newStep = pages[newPageIndex];

    if (newStep?.type === PageType.Payment) {
      // Check if all steps are complete
      const incompleteSteps = leasingJourneyErrors.filter(
        (error) => error !== 0,
      );

      if (incompleteSteps.length > 0) {
        notification(
          'Please complete all the steps before making a payment.',
          NotificationStatus.WARNING,
        );
        return;
      }
    }

    onClose();

    if (leasingJourneyErrors[activePageIndex] !== null) {
      await trigger();
    }
    const result = await savePageResponses(activeStepIndex);
    if (result) {
      navigate(`/${orgId}/${propertyId}/${targetPageId}`);
    }
  }

  const renderStepStatus = (pageId: string, index: number) => {
    const stepErrors = leasingJourneyErrors[index] ?? null;

    if (activeStepIndex === index) {
      return (
        <ListIcon
          as={Circle}
          color="#22313C"
          stroke="#22313C"
          mr={1}
          mt={2}
          boxSize="24px"
        />
      );
    }
    if (stepErrors !== null && stepErrors > 0) {
      return (
        <ListIcon as={Alert} color="red.bright" mr={1} mt={2} boxSize="24px" />
      );
    }
    if (stepErrors === 0) {
      return (
        <ListIcon
          as={CheckMark}
          fill="green.500"
          mr={1}
          mt={2}
          boxSize="24px"
        />
      );
    }
    return (
      <ListIcon
        as={Circle}
        color="white.base"
        stroke="gray.700"
        mr={1}
        mt={2}
        boxSize="24px"
      />
    );
  };

  const renderDescription = (page: Page, index: number) => {
    const stepErrors = leasingJourneyErrors[index] ?? null;
    if (stepErrors !== null && stepErrors > 0) {
      return (
        <Text fontSize="8px" fontWeight="normal" mt={1} color="red.800" pb={2}>
          {`${stepErrors} Incomplete fields`}
        </Text>
      );
    }
    return null;
  };

  const getPageTextColor = (index: number) => {
    const stepErrors = leasingJourneyErrors[index] ?? null;
    if (activeStepIndex === index) {
      return 'gray.900';
    }
    if (stepErrors !== null && stepErrors > 0) {
      return 'red.800';
    }
    if (stepErrors === 0) {
      return 'gray.800';
    }
    return 'gray.700';
  };

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Text fontSize="2xl" fontWeight="medium" lineHeight="18px">
          Application
        </Text>
        <Flex alignItems="center" fontSize="11px">
          {`Step ${activeStepIndex + 1} of ${filteredPages.length} `}
          <ChevronUpIcon ml={1} />
        </Flex>
      </Flex>
      <List gap={10}>
        {filteredPages.map((page, index) => (
          <ListItem key={page.id} display="flex" alignItems="start" mb={2}>
            {renderStepStatus(page.id as string, index)}
            <Box
              as="button"
              onClick={() => onStepClick(page.id as string, index)}
              type="button"
              p={2}
              fontWeight={activeStepIndex === index ? '700' : '400'}
              flex={1}
              textAlign="left"
              color={getPageTextColor(index)}
            >
              {page.name}
              {renderDescription(page, index)}
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default ApplicationNavigation;
