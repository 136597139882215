"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultApplicant = exports.Applicant = exports.defaultApplicantApplicationResponse = void 0;
var enums_1 = require("./enums");
var organization_1 = require("./organization");
exports.defaultApplicantApplicationResponse = {
    applicationId: '',
    versionId: '',
    responseId: '',
    entrataApplicationId: 0,
    leaseId: 0,
};
var Applicant = /** @class */ (function () {
    function Applicant() {
        this.email = '';
        this.org = new organization_1.Organization();
        this.propertyId = 0;
        this.customerId = 0;
        this.id = 0;
        this.sid = '';
        this.context = enums_1.ApplicantContext.FormViewer;
        this.authorizedResponseIds = [];
        this.responses = [];
        this.currentResponse = exports.defaultApplicantApplicationResponse;
    }
    return Applicant;
}());
exports.Applicant = Applicant;
exports.defaultApplicant = {
    email: '',
    org: organization_1.defaultOrganization,
    propertyId: 0,
    id: 0,
    customerId: 0,
    context: enums_1.ApplicantContext.FormViewer,
    sid: '',
    responses: [],
    authorizedResponseIds: [],
    currentResponse: exports.defaultApplicantApplicationResponse,
};
