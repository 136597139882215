"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.switchTheme = void 0;
var anatomy_1 = require("@chakra-ui/anatomy");
var react_1 = require("@chakra-ui/react");
var _a = (0, react_1.createMultiStyleConfigHelpers)(anatomy_1.switchAnatomy.keys), definePartsStyle = _a.definePartsStyle, defineMultiStyleConfig = _a.defineMultiStyleConfig;
var baseStyle = definePartsStyle({
    container: {
        fontWeight: '700',
        fontSize: '11px',
        lineHeight: '10px',
        _hover: {
            cursor: 'pointer',
        },
        _before: {
            content: '"No"',
            textAlign: 'right',
            color: 'gray.900',
            position: 'absolute',
            transform: 'translate(-50%,-50%)',
            top: '50%',
            right: '0',
        },
        _checked: {
            _before: {
                content: '""',
            },
            _after: {
                content: '"Yes"',
                color: 'white',
                position: 'absolute',
                transform: 'translate(-50%,-50%)',
                top: '50%',
                left: '15px',
            },
        },
    },
    thumb: {
        borderRadius: '5px',
        padding: '4px',
        height: '20px',
        width: '16px',
        _checked: {
            transform: 'translateX(24px)',
        },
    },
    track: {
        height: '21px',
        width: '40px',
        padding: '4px',
        borderRadius: '8px',
        bg: 'gray.200',
        _checked: {
            bg: 'blue.medium',
        },
    },
});
exports.switchTheme = defineMultiStyleConfig({ baseStyle: baseStyle });
