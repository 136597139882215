import { RelationshipType } from '@common/entities';
import { get, patch } from 'config/api/config';

const RESPONSE_ENDPOINT = '/viewer/responses';
const UPDATE_DIGITAL_SIGNATURE = 'digital-signature';

interface PatchResponse {
  currentPageId: string;
  targetPageId?: string;
  responses: { [key: string]: any }[];
  errorCount?: number;
}

interface PatchResponseForId {
  responseData: PatchResponse;
  responseId: string;
}

export const patchResponseForId = async (data: PatchResponseForId) => {
  const response = await patch(
    `${RESPONSE_ENDPOINT}/${data.responseId}`,
    data.responseData,
  );
  return response;
};

export const patchDigitalSignature = async (data: PatchResponseForId) => {
  const response = await patch(
    `${RESPONSE_ENDPOINT}/${data.responseId}/${UPDATE_DIGITAL_SIGNATURE}`,
    data.responseData,
  );
  return response;
};

interface GetResponseResult {
  applicationId: string;
  clientId: number;
  details: string;
  entrataApplicationId: number;
  entrataCustomerRelationshipId: number;
  formId: string;
  id: string;
  leaseId: number;
  paymentAccountId: number;
  paymentProcessed: boolean;
  primaryApplicationResponseId: string;
  relationshipType: RelationshipType;
  startDate: string;
  updatedDate: string;
  userId: number;
}
export const getResponse = async (responseId: string) => {
  if (!responseId) throw new Error('Response Id is missing.');

  const response = await get<GetResponseResult>(
    `${RESPONSE_ENDPOINT}/${responseId}`,
  );
  return response;
};
