"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApplicationScreening = void 0;
var ApplicationScreening = /** @class */ (function () {
    function ApplicationScreening() {
        this.fileAssociations = [];
        this.insuranceCarrierId = '';
        this.conditionSets = [];
        this.standardDepositCharge = 0;
    }
    return ApplicationScreening;
}());
exports.ApplicationScreening = ApplicationScreening;
