/* istanbul ignore file */
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Center, Flex } from '@chakra-ui/react';
import { NotificationStatus } from 'enums/notification-status';
import { RootState } from 'state/store';
import { useSelector } from 'state/storeHooks';

import { Loader } from 'components/Loader';
import notification from 'components/notification';
import { SelectApplication } from 'components/select-application/SelectApplication';
import { useCreateNewApplicationForUser } from 'hooks';

function SelectNewApplicationPage() {
  const [newApplicationId, setNewApplicationId] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const { email } = useSelector(
    (state: RootState) => state.application.applicant,
  );

  const createNewApplication = useCreateNewApplicationForUser();

  const { orgId: orgIdString = '', propertyId: propertyIdString = '' } =
    useParams();
  const propertyId = parseInt(propertyIdString, 10);
  const orgId = parseInt(orgIdString, 10);

  const applicationSelected = async () => {
    setIsLoading(true);

    try {
      await createNewApplication(orgId, propertyId, newApplicationId, email);
    } catch {
      notification(
        'Error while initializing application.',
        NotificationStatus.ERROR,
      );
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader label="Initializing new application..." />;

  return (
    <Flex bg="gray.highlighthalf" h="100%" flexDirection="column">
      <Center flex="1" bg="gray.highlighthalf">
        <Card
          p={6}
          maxW="700px"
          w="100%"
          h={{
            base: '100%',
            md: 'auto',
          }}
          my={8}
          key={newApplicationId}
        >
          <SelectApplication
            applicationSelected={applicationSelected}
            setSelectedApplicationId={setNewApplicationId}
            currentApplicationId={newApplicationId}
          />
        </Card>
      </Center>
    </Flex>
  );
}

export default SelectNewApplicationPage;
