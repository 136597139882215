/* istanbul ignore file */
import React from 'react';
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
} from '@chakra-ui/react';
import styled from '@emotion/styled';

import CartItem from './CartItem';

export interface SubCharge {
  label: string;
  priceNumber: number;
  priceFormatted?: string;
  recurringFrequency?: string;
  currencyCode?: string;
}

interface Props {
  key: string;
  charges: SubCharge[];
  title: string;
  isRecurring?: boolean;
}

export function ChargesAccordionSection({
  charges,
  title,
  isRecurring,
}: Props) {
  function getUniqueRecurringFrequencies() {
    if (!isRecurring) return [];
    const uniqueRecurringFrequencies = new Set(
      charges.map((charge) => charge.recurringFrequency),
    );
    return Array.from(uniqueRecurringFrequencies);
  }

  function getChargesByRecurringFrequency(frequency?: string) {
    if (!frequency) return [];
    return charges.filter((charge) => charge.recurringFrequency === frequency);
  }

  return (
    <AccItems>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            {title}
          </Box>
          <AccIcon />
        </AccordionButton>
      </h2>
      {isRecurring &&
        getUniqueRecurringFrequencies().map((frequency, index) => (
          <AccordionPanel pb={4} key={index}>
            <Text fontStyle="italic" color="gray.base">
              {frequency}
            </Text>
            {getChargesByRecurringFrequency(frequency) &&
              getChargesByRecurringFrequency(frequency).map(
                (charge: SubCharge, i: number) => (
                  <CartItem key={i} {...charge} />
                ),
              )}
            <CartItem
              key={getChargesByRecurringFrequency(frequency)?.length ?? 0}
              label="Total"
              total
              priceNumber={getChargesByRecurringFrequency(frequency)?.reduce(
                (a, b) => a + (b.priceNumber || 0),
                0,
              )}
              currencyCode={
                charges && charges.length > 0 ? charges[0].currencyCode : ''
              }
            />
          </AccordionPanel>
        ))}
      {!isRecurring && (
        <AccordionPanel pb={4}>
          {charges &&
            charges.map((charge: SubCharge, i: number) => (
              <CartItem key={i} {...charge} />
            ))}
          <CartItem
            key={charges?.length ?? 0}
            label="Total"
            total
            priceNumber={charges?.reduce((a, b) => a + (b.priceNumber || 0), 0)}
            currencyCode={
              charges && charges.length > 0 ? charges[0].currencyCode : ''
            }
          />
        </AccordionPanel>
      )}
    </AccItems>
  );
}

const AccItems = styled(AccordionItem)`
  border-top: 1px solid ${({ theme }: any) => theme.colors.gray.light};
`;
const AccIcon = styled(AccordionIcon)`
  background: ${({ theme }: any) => theme.colors.gray.light};
  border-radius: 4px;
  width: 25px;
  height: 25px;
`;
