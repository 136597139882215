import { createSlice } from '@reduxjs/toolkit';
import { resetStoreAction } from 'state/commonActions';

export interface ResponseState {
  value: any;
}

const initialState: ResponseState = {
  value: [],
};

const responseSlice = createSlice({
  name: 'responseState',
  initialState,
  reducers: {
    updateResponseState: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.value = { ...state.value, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStoreAction, () => initialState);
  },
});

export const { updateResponseState } = responseSlice.actions;
export default responseSlice.reducer;
