// eslint-disable-next-line camelcase
import { Applicant } from '@common/entities';
// eslint-disable-next-line camelcase
import { jwtDecode } from 'jwt-decode';

export interface SessionData {
  userId: number;
  email: string;
  name: string;
  clientId: number;
}
/* istanbul ignore next */
export class EntrataSession {
  private static readonly SESSION_NAME = '_entrata-session_';

  private static readonly APPLICANT = 'viewer.applicant';

  private static readonly APP_PATH = 'app-path';

  private static readonly SESSION_EXPIRED = 'session-expired';

  private static decode(token: string) {
    const tokenData: any = jwtDecode(token);
    const data: SessionData = {
      userId: tokenData.sub,
      email: tokenData.email,
      name: tokenData.name,
      clientId: tokenData.clientId,
    };
    return data;
  }

  static get applicant(): Applicant | null {
    const applicant = sessionStorage.getItem(this.APPLICANT);
    return applicant ? JSON.parse(applicant) : null;
  }

  static setApplicant(applicant: Applicant) {
    sessionStorage.setItem(this.APPLICANT, JSON.stringify(applicant));
  }

  static get data(): SessionData | null {
    const { token } = this;
    return token !== null ? this.decode(token) : null;
  }

  static get token(): string | null {
    return sessionStorage.getItem(EntrataSession.SESSION_NAME);
  }

  static setSession(token: string) {
    sessionStorage.setItem(this.SESSION_NAME, token);
  }

  static setAppPath(appPath: string) {
    sessionStorage.setItem(this.APP_PATH, appPath);
  }

  static get appPath(): string | null {
    return sessionStorage.getItem(this.APP_PATH);
  }

  static setSessionExpired(expired: boolean) {
    sessionStorage.setItem(this.SESSION_EXPIRED, expired.toString());
  }

  static get sessionExpired(): boolean {
    return sessionStorage.getItem(this.SESSION_EXPIRED) === 'true';
  }
}
