"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BILLING_ADDRESS_FIELDS = void 0;
var entities_1 = require("@common/entities");
exports.BILLING_ADDRESS_FIELDS = [
    {
        type: entities_1.FieldType.Text,
        label: 'First Name',
        sequence: 1,
        name: 'first_name',
        customType: entities_1.FieldType.Text,
        enabled: true,
        required: true,
    },
    {
        type: entities_1.FieldType.Text,
        label: 'Last Name',
        sequence: 2,
        name: 'last_name',
        customType: entities_1.FieldType.Text,
        enabled: true,
        required: true,
    },
    {
        type: entities_1.FieldType.Text,
        label: 'Company Name',
        sequence: 3,
        name: 'company_name',
        customType: entities_1.FieldType.Text,
        enabled: true,
        required: false,
    },
    {
        type: entities_1.FieldType.Text,
        label: 'Street Line 1',
        sequence: 4,
        name: 'street_line1',
        customType: entities_1.FieldType.Text,
        enabled: true,
        required: true,
        entrataRequired: true,
    },
    {
        type: entities_1.FieldType.Text,
        label: 'Street Line 2',
        sequence: 5,
        name: 'street_line2',
        customType: entities_1.FieldType.Text,
        enabled: true,
        required: false,
    },
    {
        type: entities_1.FieldType.Text,
        label: 'Street Line 3',
        sequence: 6,
        name: 'street_line3',
        customType: entities_1.FieldType.Text,
        enabled: false,
        required: false,
    },
    {
        type: entities_1.FieldType.Text,
        label: 'City',
        sequence: 7,
        name: 'city',
        customType: entities_1.FieldType.Text,
        enabled: true,
        required: true,
        entrataRequired: true,
    },
    {
        type: entities_1.FieldType.CountryDropdown,
        label: 'Country',
        options: [],
        sequence: 8,
        name: 'country_code',
        customType: entities_1.FieldType.CountryDropdown,
        enabled: true,
        required: true,
        entrataRequired: true,
    },
    {
        type: entities_1.FieldType.StateDropdown,
        label: 'State/Province/Region',
        options: [],
        sequence: 9,
        name: 'state_code',
        customType: entities_1.FieldType.StateDropdown,
        enabled: true,
        required: true,
        entrataRequired: true,
    },
    {
        type: entities_1.FieldType.ZipCode,
        label: 'ZIP Code',
        sequence: 10,
        name: 'zip_code',
        customType: entities_1.FieldType.ZipCode,
        enabled: true,
        required: true,
        entrataRequired: true,
    },
    {
        type: entities_1.FieldType.PhoneNumber,
        label: 'Phone Number',
        sequence: 12,
        name: 'phone_number',
        customType: entities_1.FieldType.PhoneNumber,
        enabled: true,
        required: true,
    },
    {
        type: entities_1.FieldType.Email,
        label: 'Email Address',
        sequence: 13,
        name: 'email_address',
        customType: entities_1.FieldType.Email,
        enabled: true,
        required: true,
    },
];
