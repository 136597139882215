"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Tabs = {
    variants: {
        enclosed: {
            tab: {
                _selected: {
                    bgColor: 'white.base',
                    color: 'black.base',
                },
                border: 'none',
                bgColor: 'gray.highlight',
                borderTopLeftRadius: 'xl',
                borderTopRightRadius: 'xl',
            },
        },
    },
};
exports.default = Tabs;
