import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Heading } from '@chakra-ui/react';
import { RootState } from 'state/store';

import { NoUnitAvailable } from './NoUnitAvailable';
import { SelectedUnitDetails } from './SelectedUnitDetails';

interface Props {
  handleDrawerClose?: () => void;
}

export function NewHomeCard({ handleDrawerClose }: Props) {
  const { unit, leaseTerm } = useSelector(
    (state: RootState) => state.unitSelection,
  );

  return (
    <Box mb={5}>
      <Heading as="h4" size="md" letterSpacing="0.5px">
        Your New Home
      </Heading>
      {unit && leaseTerm ? (
        <SelectedUnitDetails />
      ) : (
        <NoUnitAvailable handleDrawerClose={handleDrawerClose} />
      )}
    </Box>
  );
}
