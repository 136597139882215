let formBuilderUrl: any

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
  formBuilderUrl = process.env.FORMBUILDER_API_URL
} else {
  formBuilderUrl = (window as any).env?.FORMBUILDER_API_URL
}

const BASE_URL = formBuilderUrl

export { BASE_URL }
