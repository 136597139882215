"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeadProfileApplicant = void 0;
var enums_1 = require("./enums");
var LeadProfileApplicant = /** @class */ (function () {
    function LeadProfileApplicant() {
        this.relationshipType = enums_1.RelationshipType.Primary;
    }
    return LeadProfileApplicant;
}());
exports.LeadProfileApplicant = LeadProfileApplicant;
