/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetStoreAction } from 'state/commonActions';

type ActionsState = {
  isLoading: boolean;
  message?: string;
  saveTriggerCounter: number;
  saveCompleted: boolean;
  pendingTabIndex: number | null;
};

const initialState: ActionsState = {
  isLoading: false,
  message: '',
  saveTriggerCounter: 0,
  saveCompleted: true,
  pendingTabIndex: null,
};

export const actionsSlice = createSlice({
  name: 'actions',
  initialState,
  reducers: {
    setLoadingState: (state, action: PayloadAction<Partial<ActionsState>>) => {
      const { isLoading, message } = action.payload;
      if (isLoading !== undefined) state.isLoading = isLoading;
      if (message !== undefined) state.message = message;
    },
    triggerSave: (state) => {
      state.saveTriggerCounter += 1;
      state.saveCompleted = false;
    },
    setSaveCompleted: (state, action: PayloadAction<boolean>) => {
      state.saveCompleted = action.payload;
    },
    resetSaveTrigger: (state) => {
      state.saveTriggerCounter = 0;
    },
    resetSaveState: (state) => {
      state.saveTriggerCounter = 0;
      state.saveCompleted = true;
      state.pendingTabIndex = null;
    },
    setPendingTabIndex: (state, action: PayloadAction<number | null>) => {
      state.pendingTabIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStoreAction, () => initialState);
  },
});

export const {
  setLoadingState,
  triggerSave,
  setSaveCompleted,
  resetSaveState,
  resetSaveTrigger,
  setPendingTabIndex,
} = actionsSlice.actions;

export default actionsSlice.reducer;
