/* istanbul ignore file */
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Center, Flex } from '@chakra-ui/react';
import { RootState } from 'state/store';

import { Caution, InvertCheckMark } from 'components/custom-icons';
import NotificationBanner from 'components/notifications/NotificationBanner';

import PageNotFound from './PageNotFound';

const statusData = {
  'lp-save-success': {
    title: 'Success',
    message:
      'All changes to the application have been saved. Navigate back to the main Entrata tab to continue.',
    color: 'green.700',
    backgroundColor: '#E6EFE7',
    Icon: <InvertCheckMark />,
  },
  error: {
    title: 'Error',
    message: 'An error occurred during your operation.',
    color: 'red.700',
    backgroundColor: '#FEE2E2',
    Icon: <Caution />,
  },
  caution: {
    title: 'Warning',
    message: "There's something you should caution.",
    color: 'yellow.700',
    backgroundColor: '#FEF3C7',
    Icon: <Caution />,
  },
};

const StatusPage = () => {
  const { status } = useParams<{ status: string }>();
  const allowStatusPage = useSelector(
    (state: RootState) => state.auth.allowStatusPage,
  );

  const statusKey = status as keyof typeof statusData;

  const statusInfo = statusData[statusKey];

  if (!statusInfo || !allowStatusPage) {
    return <PageNotFound />;
  }
  const { message, color, backgroundColor, Icon } = statusInfo;

  return (
    <Flex direction="column" minHeight="100vh">
      <Flex direction="column" flex="1" p={4} bg="gray.highlighthalf">
        <Center mt={10}>
          <NotificationBanner
            message={message}
            color={color}
            backgroundColor={backgroundColor}
            icon={React.cloneElement(Icon, { color })}
          />
        </Center>
      </Flex>
    </Flex>
  );
};

export default StatusPage;
