import { useDispatch } from 'react-redux';
import { PageType } from '@common/entities';
import { queryClient } from 'AppWrapper';
import { updateLeasingJourneyErrors } from 'state/slices/navigationSlice';
import { setQuote } from 'state/slices/unitSelectionSlice';

import { getQuotes } from 'services';
import { AnyPage, Quote } from 'types/page';
import { getPageIndex } from 'utils/page-utils';

const useInitializeQuotes = () => {
  const dispatch = useDispatch();

  const getSelectedQuotes = async (quoteId: number) => {
    try {
      const data = await queryClient.fetchQuery({
        queryKey: ['quotes'],
        queryFn: getQuotes,
      });

      const selectedQuote = data.find((i: Quote) => i.id === quoteId);
      if (!selectedQuote) throw new Error('Quote is not available.');
      return selectedQuote;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const initialize = async (
    pages: AnyPage[],
    quoteId: number,
    leaseTermId: number,
  ) => {
    const quotePageIndex = getPageIndex(pages, PageType.Quotes);
    try {
      const quote = await getSelectedQuotes(quoteId);
      dispatch(
        setQuote({
          quote,
          quoteLeaseTerm: leaseTermId,
        }),
      );
      dispatch(
        updateLeasingJourneyErrors({
          pageIndex: quotePageIndex as number,
          count: 0,
        }),
      );
    } catch {
      dispatch(
        updateLeasingJourneyErrors({
          pageIndex: quotePageIndex as number,
          count: null,
        }),
      );
    }
  };

  return initialize;
};

export default useInitializeQuotes;
