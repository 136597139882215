"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentTypeId = exports.CardPaymentType = exports.Payment = void 0;
var Payment = /** @class */ (function () {
    function Payment() {
        this.id = 0;
        this.result = '';
        this.amount = 0;
        this.fee = 0;
        this.total = 0;
        this.status = '';
        this.type = '';
        this.createdCustomerName = '';
    }
    return Payment;
}());
exports.Payment = Payment;
var CardPaymentType;
(function (CardPaymentType) {
    CardPaymentType["VISA"] = "visa";
    CardPaymentType["MASTERCARD"] = "mastercard";
    CardPaymentType["DISCOVER"] = "discover";
    CardPaymentType["AMEX"] = "amex";
    CardPaymentType["JCB"] = "jcb";
    CardPaymentType["DINERSCLUB"] = "dinersclub";
    CardPaymentType["MAESTRO"] = "maestro";
    CardPaymentType["UNIONPAY"] = "unionpay";
})(CardPaymentType || (exports.CardPaymentType = CardPaymentType = {}));
var PaymentTypeId;
(function (PaymentTypeId) {
    PaymentTypeId[PaymentTypeId["E_CHECK_ID"] = 4] = "E_CHECK_ID";
    PaymentTypeId[PaymentTypeId["VISA_ID"] = 5] = "VISA_ID";
    PaymentTypeId[PaymentTypeId["MASTERCARD_ID"] = 6] = "MASTERCARD_ID";
    PaymentTypeId[PaymentTypeId["DISCOVER_ID"] = 7] = "DISCOVER_ID";
    PaymentTypeId[PaymentTypeId["AMERICAN_EXPRESS_ID"] = 8] = "AMERICAN_EXPRESS_ID";
    PaymentTypeId[PaymentTypeId["MONEY_GRAM_ID"] = 10] = "MONEY_GRAM_ID";
})(PaymentTypeId || (exports.PaymentTypeId = PaymentTypeId = {}));
