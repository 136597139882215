"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScreeningConditionSet = void 0;
var enums_1 = require("./enums");
var ScreeningConditionSet = /** @class */ (function () {
    function ScreeningConditionSet() {
        this.screeningApplicationConditionSetId = 0;
        this.conditionSetId = 0;
        this.operandType = enums_1.ScreeningConditionOperandType.All;
        this.name = '';
        this.subsets = [];
    }
    return ScreeningConditionSet;
}());
exports.ScreeningConditionSet = ScreeningConditionSet;
