import { createSlice } from '@reduxjs/toolkit';
import { resetStoreAction } from 'state/commonActions';

interface FieldElement {
  elementType: 'field';
  id: string;
  name: string;
  label: string;
  sequence: number;
  type: string;
  customType: string;
  required: boolean;
  minValue: null;
  maxValue: null;
  options: any[];
}

interface BlockElement {
  elementType: 'block';
  id: string;
  name: string;
  sequence: number;
  customType: string;
  configuration: {
    label: string;
    fields: Array<{
      name: string;
      enabled: boolean;
      required: boolean;
    }>;
    toolTip: string;
    required: boolean;
    addressNote: string;
    addressType: string;
    requireUSFormat: boolean;
  };
}

type FormElement = FieldElement | BlockElement;

interface Section {
  elements: FormElement[];
  id: string;
  sequence: number;
  title: string;
}

interface Page {
  sections: Section[];
  id: string;
  sequence: number;
  name: string;
  description: string;
  type: string;
  configuration?: {
    hideFromGuarantor: boolean;
    hideFromCoApplicant: boolean;
  };
}

export interface FormData {
  id: string;
  name: string;
  description: string;
  type: string;
  clientId: number;
  pages: Page[];
}

const initialState: FormData = {
  id: '',
  name: '',
  description: '',
  type: '',
  clientId: 0,
  pages: [],
};

const formDataSlice = createSlice({
  name: 'formData',
  initialState,
  reducers: {
    updateFormData: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStoreAction, () => initialState);
  },
});

export const { updateFormData } = formDataSlice.actions;
export default formDataSlice.reducer;
