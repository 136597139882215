import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetStoreAction } from 'state/commonActions';

export type BlockFieldMap = {
  blockFieldName: string;
  blockFieldFormFieldName: string;
}[];

export type BlockFieldMapReducer = {
  blockFieldMap: BlockFieldMap;
};

const initialState: BlockFieldMapReducer = {
  blockFieldMap: [],
};

export const activeBlockFieldSlice = createSlice({
  name: 'activeBlockFields',
  initialState,
  reducers: {
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    setActiveBlockFields: (state, action: PayloadAction<BlockFieldMap>) => {      
      const dedupedMap = removeDuplicates(action.payload);
      // eslint-disable-next-line no-param-reassign
      state.blockFieldMap = dedupedMap;
    },
    resetActiveBlockFields: (state, action: PayloadAction<BlockFieldMap>) => {
      return { blockFieldMap: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStoreAction, () => initialState);
  },
});

function removeDuplicates(blockFieldPairs: BlockFieldMap) {
  const mapSet = new Set();
  return blockFieldPairs.filter((pair) => {
    const uniquePair = JSON.stringify(pair);
    if (mapSet.has(uniquePair)) {
      return false;
    }
    mapSet.add(uniquePair);
    return true;
  })
}

export const { setActiveBlockFields, resetActiveBlockFields } =
  activeBlockFieldSlice.actions;
export default activeBlockFieldSlice.reducer;
