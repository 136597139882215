"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.US_MILITARY_VETERAN = void 0;
var entities_1 = require("@common/entities");
var constants_1 = require("../../constants");
exports.US_MILITARY_VETERAN = [
    {
        label: 'Are you a US military veteran?',
        type: entities_1.FieldType.YesNo,
        customType: entities_1.FieldType.YesNo,
        sequence: 1,
        name: 'military_veteran',
        required: false,
        enabled: true,
        options: constants_1.YES_NO_OPTIONS,
    },
];
