import { Applicant } from '@common/entities';
import { ApplicantApplicationResponse } from '@common/entities/src/applicant-application-response.entity';

export function getActiveResponse(
  applicant: Applicant,
  responseId: string,
): ApplicantApplicationResponse {
  const responses = applicant.responses ?? [];
  return (
    responses.find((response) => response.responseId === responseId) ||
    responses[0]
  );
}
