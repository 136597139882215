/* istanbul ignore file */
import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import {
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import _ from 'lodash';

interface IProps {
  enablePaging: boolean;
  documentUrl: string | File;
  documentName: string;
  showFooter: boolean;
}

function PdfRenderer({
  enablePaging,
  documentUrl,
  documentName,
  showFooter,
}: IProps) {
  const [pageCount, setPageCount] = useState<number>();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    if (pageCount !== numPages) {
      setPageCount(numPages);
    }
  }

  const pageWidth = useBreakpointValue({ base: 330, sm: 480, md: 750 });

  return (
    <>
      <Center overflow="auto" marginTop={10}>
        <Flex flexDir="column" height="100%">
          <Document file={documentUrl} onLoadSuccess={onDocumentLoadSuccess}>
            {!enablePaging &&
              _.times(pageCount ?? 0, (p) => (
                <Page
                  key={`page_${p + 1}`}
                  width={pageWidth}
                  renderMode="canvas"
                  pageNumber={p + 1}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                />
              ))}
            {enablePaging && (
              <Page
                key={`page_${pageNumber}`}
                width={pageWidth}
                renderMode="canvas"
                pageNumber={pageNumber}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            )}
          </Document>
        </Flex>
      </Center>
      {showFooter && (
        <>
          <Divider orientation="horizontal" />
          <HStack>
            <Flex width="20%" marginBottom={5}>
              <VStack marginLeft={12}>
                <Text fontSize="md" fontWeight="semibold">
                  {documentName}
                </Text>
                {enablePaging && (
                  <Text fontSize="xs">
                    Page {pageNumber} of {pageCount}
                  </Text>
                )}
              </VStack>
            </Flex>
            {enablePaging && (
              <Center width="55%" marginTop={2}>
                <HStack marginBottom={5} spacing="100px">
                  <Button
                    isDisabled={pageNumber === 1}
                    size="sm"
                    onClick={() => setPageNumber(pageNumber - 1)}
                  >
                    Previous
                  </Button>
                  <Button
                    isDisabled={pageNumber === pageCount}
                    size="sm"
                    onClick={() => setPageNumber(pageNumber + 1)}
                  >
                    Next
                  </Button>
                </HStack>
              </Center>
            )}
          </HStack>
        </>
      )}
    </>
  );
}

export default PdfRenderer;
