import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { EntrataSession } from 'auth/EntrataSession';

import { postUserLogin } from 'services';

import useAuthRedirection from './useAuthRedirection';

interface LoginRequest {
  orgId: number;
  propertyId: number;
  username: string;
  password: string;
}
const useLoginUser = () => {
  const [isLoading, setIsLoading] = useState(false);

  const authRedirection = useAuthRedirection();

  const { mutateAsync } = useMutation({ mutationFn: postUserLogin });

  const validateRequestData = (data: LoginRequest) => {
    const { orgId, propertyId, username, password } = data;
    if (!orgId) throw new Error('Org. id is missing.');
    if (!propertyId) throw new Error('Property Id is missing.');
    if (!username) throw new Error('Username is missing.');
    if (!password) throw new Error('Password is missing.');
  };

  const loginRequest = async (data: LoginRequest) => {
    try {
      const response = await mutateAsync(data);
      return response;
    } catch (err: any) {
      throw new Error(err.message);
    }
  };

  const loginUser = async (data: LoginRequest) => {
    setIsLoading(true);
    try {
      validateRequestData(data);
      EntrataSession.setSessionExpired(false);
      const response = await loginRequest(data);
      if (response.status >= 200 && response.status < 300) {
        const { applicant, form } = response.data;
        EntrataSession.setApplicant(applicant);

        const { authorizedResponseIds } = applicant;

        await authRedirection(
          form,
          applicant,
          authorizedResponseIds as string[],
          data.orgId,
        );
      } else {
        throw new Error('Error while login');
      }
    } catch (err: any) {
      throw new Error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, loginUser };
};

export default useLoginUser;
