"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Merchant = void 0;
var Merchant = /** @class */ (function () {
    function Merchant() {
        this.id = 0;
        this.accountId = 0;
        this.accountName = '';
        this.arCodeId = 0;
    }
    return Merchant;
}());
exports.Merchant = Merchant;
