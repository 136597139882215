import { useDispatch } from 'react-redux';
import { LeaseChargeType, LeaseChargeTypeIsRecurring, LeaseChargeTypeToDisplayText } from '@common/entities';
import { CartCharge, CartChargeType, resetCartCharges } from 'state/slices/shoppingCartSlice';

import { getScheduledCharges } from 'services';

interface Props {
  propertyId: number;
  leaseId: number;
  leaseIntervalId?: number;
}

function useRefreshScheduledCharges ({propertyId, leaseId, leaseIntervalId} : Props) {
  const dispatch = useDispatch();

  async function refreshScheduledCharges() {
    if (propertyId === 0 || leaseId === 0)
      return;
    // eslint-disable-next-line no-console
    console.log(`Retrieving charges for propertyId: ${propertyId}, leaseId: ${leaseId}`)
    const {data} = await getScheduledCharges(propertyId, leaseId, leaseIntervalId);
    // Update redux shopping cart state with latest charges.
    if (!data)
      return;
    const charges : CartCharge[]  = data.map((charge: any) => {
      return {
        id: charge.summarizationGroupId,
        type: CartChargeType.Rent,
        label: charge.arCodeName,
        priceNumber: charge.chargeAmount.amountNumber,
        priceFormatted: charge.chargeAmount.amountFormatted,
        currencyCode: charge.chargeAmount.currencyCode,
        dueNow: charge.arTriggerId === LeaseChargeType.ApplicationCompleted,
        dueAtMoveIn: charge.arTriggerId === LeaseChargeType.MoveIn,
        isRecurring: LeaseChargeTypeIsRecurring(charge.arTriggerId),
        recurringFrequency: LeaseChargeTypeToDisplayText(charge.arTriggerId),
      };
    });
    dispatch(resetCartCharges(charges));
  }

  return refreshScheduledCharges;
}

export default useRefreshScheduledCharges;
