import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PageType, RelationshipType } from '@common/entities';
import {
  QueryFunctionContext,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { EntrataSession } from 'auth/EntrataSession';
import { setApplicationData } from 'state/slices/applicationSlice';
import { setIsLeadProfile } from 'state/slices/authSlice';
import { updateFormData } from 'state/slices/formDataSlice';
import {
  setApplicantsData,
  updateApplicantResponses,
} from 'state/slices/leadProfileSlice';
import { setActiveStep, updateStepStatus } from 'state/slices/navigationSlice';
import {
  updateAddon,
  updateUnitSelection,
} from 'state/slices/unitSelectionSlice';

import { useSetFloorPlanPageDataInStore } from 'hooks/floor-plans';
import { postLeadProfileVerifySession } from 'services';
import { AddonOption } from 'types/addon';
import { getTodaysDate } from 'utils/date';
import { filterApplicationPages } from 'utils/filterApplicationPages';
import { prepareApplicantResponseData } from 'utils/form';

import { useGetPropertySettings } from './useGetPropertySettings';

type SessionQueryKey = [
  string,
  {
    orgId: number;
    propertyId: number;
    token: string;
    userId: number;
  },
];

interface PreparedResponseData {
  addOns?: AddonOption[];
  [key: string]: any;
}

const fetchSessionData = async ({
  queryKey,
}: QueryFunctionContext<SessionQueryKey>) => {
  const [, { orgId, propertyId, token, userId }] = queryKey;

  const response = await postLeadProfileVerifySession({
    orgId,
    propertyId,
    token,
    userId,
  });

  return response;
};

type Props = {
  orgId: number;
  propertyId: number;
  token: string;
  userId: number;
  isLeadProfile: boolean;
};

type LeadProfileData = any;

export const useLeadProfileData = ({
  orgId,
  propertyId,
  token,
  userId,
  isLeadProfile,
}: Props): UseQueryResult<LeadProfileData> & { isProcessing: boolean } => {
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = useState(false);

  const initializeFloorPlanPageData = useSetFloorPlanPageDataInStore();
  const initializePropertySettings = useGetPropertySettings();

  const queryResult = useQuery({
    queryKey: ['verify-session-data', { orgId, propertyId, token, userId }],
    queryFn: fetchSessionData,
    enabled: !!orgId && !!propertyId && !!token && !!userId,
  });

  const handleDataSuccess = async (data: any) => {
    setIsProcessing(true);

    dispatch(setApplicantsData(data));
    dispatch(updateFormData(data.applicationForm));
    dispatch(
      setApplicationData({
        applicant: data.primaryApplicant,
        form: data.applicationForm,
        activeResponseId: data.primaryResponseId,
      }),
    );
    dispatch(setIsLeadProfile(true));
    EntrataSession.setApplicant(data.primaryApplicant);

    await Promise.all(
      data.applicants.map(async (applicant: any, tabIndex: number) => {
        const filteredPages = filterApplicationPages(
          data.applicationForm,
          applicant.relationshipType,
          isLeadProfile,
        );

        await Promise.all(
          applicant.responseDetails?.pages?.map(async (page: any) => {
            if (applicant.relationshipType === RelationshipType.Primary) {
              const floorPlanResponse = page.responses.find(
                (response: any) => response.floorPlanData,
              );

              if (!floorPlanResponse) {
                // TODO: Temp code
                const todaysDate = getTodaysDate();
                dispatch(updateUnitSelection({ moveInDate: todaysDate }));
                return;
              }

              if (floorPlanResponse) {
                const { pages } = data.applicationForm;
                const floorPlanIndex = pages.findIndex(
                  (i: any) => i.type === PageType.FloorPlans,
                );

                const { floorplanId, unitSpaceId, termMonth, moveInDate } =
                  floorPlanResponse.floorPlanData;

                const errorCount = await initializeFloorPlanPageData(
                  moveInDate,
                  floorplanId,
                  unitSpaceId,
                  termMonth,
                  data.primaryApplicant.propertyId,
                  applicant.leaseId,
                );

                dispatch(
                  updateStepStatus({
                    tabIndex,
                    step: pages[floorPlanIndex].id,
                    status: { errorCount },
                  }),
                );
              }
            }
          }) || [],
        );

        const responseDetail = applicant.responseDetails;
        const prepData = prepareApplicantResponseData(responseDetail);

        dispatch(updateApplicantResponses({ tabIndex, data: prepData }));

        filteredPages.forEach((page: any) => {
          const foundPage = applicant.responseDetails?.pages?.find(
            (p: any) => p.pageId === page.id,
          );
          const errorCount = foundPage ? foundPage.errorCount : null;

          dispatch(
            updateStepStatus({
              tabIndex,
              step: page.id,
              status: { errorCount },
            }),
          );
        });

        const activePage = filteredPages.find(
          (page) =>
            page.id ===
            applicant.responseDetails?.pages?.find((p: any) => p.isCurrentPage)
              ?.pageId,
        );

        if (activePage) {
          dispatch(setActiveStep({ tabIndex, step: activePage.id as string }));
        } else if (filteredPages.length > 0) {
          dispatch(
            setActiveStep({ tabIndex, step: filteredPages[0].id as string }),
          );
        }
      }),
    );

    const primaryApplicant = data.applicants.find(
      (applicant: any) =>
        applicant.relationshipType === RelationshipType.Primary,
    );

    if (primaryApplicant) {
      const preparedData: PreparedResponseData = prepareApplicantResponseData(
        primaryApplicant.responseDetails,
      );

      if (preparedData?.addOns) {
        dispatch(updateAddon(preparedData.addOns));
      }
    }

    initializePropertySettings(propertyId);

    setIsProcessing(false);
  };

  const { data, isSuccess } = queryResult;

  useEffect(() => {
    if (data && isSuccess) {
      handleDataSuccess(data.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, data, orgId, propertyId, token, userId]);

  return {
    ...queryResult,
    isProcessing,
  };
};
