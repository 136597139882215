"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColorOptions = void 0;
var ColorOptions;
(function (ColorOptions) {
    ColorOptions["Dusty"] = "dusty";
    ColorOptions["Bright"] = "bright";
    ColorOptions["Darkest"] = "darkest";
    ColorOptions["Dark"] = "dark";
    ColorOptions["Base"] = "base";
    ColorOptions["Medium"] = "medium";
    ColorOptions["Light"] = "light";
    ColorOptions["Highlight"] = "highlight";
    ColorOptions["Highlighthalf"] = "highlighthalf";
    ColorOptions["Essence"] = "essence";
})(ColorOptions || (exports.ColorOptions = ColorOptions = {}));
var defaultColors = {
    brand: {
        base: '#383838',
        text: '#999999',
        accent1: '#222',
        accent2: '#444',
        accent3: '#f2f2f2',
        accent4: '#333333',
        background: '#FFFFFF',
        header: '#E6E6E6',
        link: '#E6E6E6',
        primaryButton: '#E6E6E6',
        secondaryButton: '#E6E6E6',
        tertiaryButton: '#E6E6E6',
    },
    gray: {
        '100': 'rgba(245, 245, 245, 1)',
        '200': 'rgba(235, 235, 235, 1)',
        '300': 'rgba(224, 224, 224, 1)',
        '400': 'rgba(204, 204, 204, 1)',
        '500': 'rgba(184, 184, 184, 1)',
        '600': 'rgba(153, 153, 153, 1)',
        '700': 'rgba(122, 122, 122, 1)',
        '800': 'rgba(82, 82, 82, 1)',
        '900': 'rgba(56, 56, 56, 1)',
        darkest: '#4E4D4D',
        dark: '#777676',
        dusty: '#999',
        base: '#ABAAAA',
        medium: '#B8B8B8',
        light: '#E0E0E0',
        highlight: '#EBEBEB',
        highlighthalf: '#F5F5F5',
        essence: '#F9F9F9',
    },
    blackAlpha: {
        '900': 'rgba(41, 41, 41, 1)',
    },
    blue: {
        '100': 'rgba(223, 238, 250, 1)',
        '200': 'rgba(202, 227, 247, 1)',
        '300': 'rgba(175, 212, 241, 1)',
        '400': 'rgba(137, 191, 233, 1)',
        '500': 'rgba(97, 170, 229, 1)',
        '600': 'rgba(66, 150, 218, 1)',
        '700': 'rgba(21, 120, 201, 1)',
        '800': 'rgba(19, 87, 145, 1)',
        '900': 'rgba(15, 58, 92, 1)',
        bright: '#0075FE',
        dusty: '#1578C9',
        darkest: '#264F6A',
        dark: '#306688',
        base: '#3878A0',
        medium: '#599BC5',
        light: '#C6DCE8',
        highlight: '#E2EEF4',
        highlighthalf: '#f3f8fa',
    },
    purple: {
        '100': 'rgba(235, 228, 235, 1)',
        '200': 'rgba(217, 205, 217, 1)',
        '300': 'rgba(198, 171, 198, 1)',
        '400': 'rgba(178, 140, 177, 1)',
        '500': 'rgba(157, 110, 156, 1)',
        '600': 'rgba(129, 87, 128, 1)',
        '700': 'rgba(99, 66, 98, 1)',
        '800': 'rgba(68, 46, 68, 1)',
        '900': 'rgba(38, 25, 37, 1)',
    },
    red: {
        '100': 'rgba(247, 221, 222, 1)',
        '200': 'rgba(244, 204, 207, 1)',
        '300': 'rgba(235, 164, 168, 1)',
        '400': 'rgba(226, 124, 130, 1)',
        '500': 'rgba(218, 83, 91, 1)',
        '600': 'rgba(211, 53, 62, 1)',
        '700': 'rgba(175, 38, 46, 1)',
        '800': 'rgba(133, 29, 35, 1)',
        '900': 'rgba(91, 20, 24, 1)',
        bright: '#EC0006',
        darkest: '#981F23',
        dark: '#D7393F',
        base: '#E52938',
        medium: '#FEA1A5',
        light: '#F9CACD',
        highlight: '#F8DDDE',
    },
    orange: {
        '100': 'rgba(251, 238, 232, 1)',
        '200': 'rgba(250, 229, 219, 1)',
        '300': 'rgba(246, 210, 193, 1)',
        '400': 'rgba(244, 189, 164, 1)',
        '500': 'rgba(239, 160, 123, 1)',
        '600': 'rgba(234, 131, 82, 1)',
        '700': 'rgba(228, 98, 37, 1)',
        '800': 'rgba(200, 80, 25, 1)',
        '900': 'rgba(145, 58, 18, 1)',
        darkest: '#CE5200',
        dark: '#E97122',
        base: '#F89859',
        medium: '#FABC94',
        light: '#FBDDC9',
        highlight: '#FCEDE3',
    },
    yellow: {
        '100': 'rgba(255, 246, 224, 1)',
        '200': 'rgba(255, 239, 198, 1)',
        '300': 'rgba(255, 232,173, 1)',
        '400': 'rgba(255, 221, 135, 1)',
        '500': 'rgba(255, 211, 100, 1)',
        '600': 'rgba(255, 203, 71, 1)',
        '700': 'rgba(255, 189, 20, 1)',
        '800': 'rgba(224, 161, 0, 1)',
        '900': 'rgba(173, 124, 0, 1)',
        darkest: '#DC8800',
        dark: '#EFA122',
        base: '#FFA800',
        medium: '#FED99B',
        light: '#FEEBCC',
        highlight: '#FDF4E5',
    },
    green: {
        '100': 'rgba(230, 239, 231, 1)',
        '200': 'rgba(205, 223, 208, 1)',
        '300': 'rgba(180, 207, 184, 1)',
        '400': 'rgba(143, 183, 148, 1)',
        '500': 'rgba(106, 160, 113, 1)',
        '600': 'rgba(88, 137, 94, 1)',
        '700': 'rgba(68, 106, 73, 1)',
        '800': 'rgba(50, 77, 53, 1)',
        '900': 'rgba(32, 50, 34, 1)',
        darkest: '#048659',
        dark: '#37A17C',
        base: '#507C56',
        medium: '#75A97D',
        light: '#D2E9E1',
        highlight: '#E8F3EF',
    },
    blueGreen: {
        '100': 'rgba(224, 235, 239, 1)',
        '200': 'rgba(208, 226, 231, 1)',
        '300': 'rgba(177, 208, 216, 1)',
        '400': 'rgba(163, 198, 208, 1)',
        '500': 'rgba(120, 171, 184, 1)',
        '600': 'rgba(98, 159, 176, 1)',
        '700': 'rgba(69, 134, 152, 1)',
        '800': 'rgba(57, 110, 124, 1)',
        '900': 'rgba(46, 87, 97, 1)',
    },
    bluePurple: {
        '100': 'rgba(231, 235, 241, 1)',
        '200': 'rgba(209, 217, 230, 1)',
        '300': 'rgba(180, 192, 213, 1)',
        '400': 'rgba(151, 168, 197, 1)',
        '500': 'rgba(124, 144, 181, 1)',
        '600': 'rgba(98, 123, 171, 1)',
        '700': 'rgba(69, 91, 133, 1)',
        '800': 'rgba(55, 74, 106, 1)',
        '900': 'rgba(43, 57, 81, 1)',
    },
    redPurple: {
        '100': 'rgba(243, 233, 236, 1)',
        '200': 'rgba(235, 216, 223, 1)',
        '300': 'rgba(218, 182, 194, 1)',
        '400': 'rgba(200, 149, 166, 1)',
        '500': 'rgba(183, 115, 138, 1)',
        '600': 'rgba(163, 84, 111, 1)',
        '700': 'rgba(129, 67, 88, 1)',
        '800': 'rgba(96, 49, 65, 1)',
        '900': 'rgba(62, 32, 42, 1)',
    },
    redOrange: {
        '100': 'rgba(252, 238, 235, 1)',
        '200': 'rgba(246, 213, 208, 1)',
        '300': 'rgba(241, 190, 182, 1)',
        '400': 'rgba(238, 172, 163, 1)',
        '500': 'rgba(231, 134, 119, 1)',
        '600': 'rgba(223, 95, 76, 1)',
        '700': 'rgba(210, 60, 38, 1)',
        '800': 'rgba(167, 48, 30, 1)',
        '900': 'rgba(124, 35, 22, 1)',
    },
    yellowOrange: {
        '100': 'rgba(253, 243, 230, 1)',
        '200': 'rgba(252, 236, 215, 1)',
        '300': 'rgba(252, 224, 187, 1)',
        '400': 'rgba(251, 207, 150, 1)',
        '500': 'rgba(251, 192, 115, 1)',
        '600': 'rgba(250, 170, 65, 1)',
        '700': 'rgba(249, 148, 15, 1)',
        '800': 'rgba(208, 120, 5, 1)',
        '900': 'rgba(158, 91, 4, 1)',
    },
    yellowGreen: {
        '100': 'rgba(241, 241, 228, 1)',
        '200': 'rgba(234, 234, 215, 1)',
        '300': 'rgba(220, 219, 188, 1)',
        '400': 'rgba(199, 198, 148, 1)',
        '500': 'rgba(178, 176, 108, 1)',
        '600': 'rgba(160, 159, 84, 1)',
        '700': 'rgba(134, 133, 70, 1)',
        '800': 'rgba(107, 106, 56, 1)',
        '900': 'rgba(80, 80, 42, 1)',
    },
    silver: {
        darkest: '#767676',
        dark: '#707070',
        dusty: '#b3b3b3',
        base: '#CCC',
        medium: '#D9D9D9',
        light: '#E5E5E5',
        highlighthalf: '#EEE',
        highlight: '#f2f2f2',
        essence: '#f7f7f7',
    },
    black: {
        darkest: '#222',
        base: '#302F2F',
    },
    white: {
        dark: '#FFF',
        base: '#FDFDFD',
    },
};
exports.default = defaultColors;
