import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowBackIcon,
  ChevronDownIcon,
  HamburgerIcon,
} from '@chakra-ui/icons';
import { Box, Flex, IconButton, Stack, useDisclosure } from '@chakra-ui/react';
import { RootState } from 'state/store';

import { useGetActiveStepIndex } from 'hooks';

import MobileMenuDrawer from './MobileMenuDrawer';
import MobileNavigation from './MobileNavigation';

export default function MobileMenu() {
  const [filteredPages, setFilteredPages]: any = useState([]);
  const getActiveStepIndex = useGetActiveStepIndex();
  const activeStepIndex = getActiveStepIndex();

  const { unit, leaseTerm } = useSelector(
    (state: RootState) => state.unitSelection,
  );

  const handleFilteredPages = useCallback((pages: any) => {
    setFilteredPages(pages);
  }, []);

  const {
    isOpen: isNavigationOpen,
    onOpen: onCartOpen,
    onClose: onNavigationClose,
  } = useDisclosure();
  const {
    isOpen: isMenuDrawerOpen,
    onOpen: onMenuDrawerOpen,
    onClose: onMenuDrawerClose,
  } = useDisclosure();
  const navigate = useNavigate();
  const { orgId, propertyId } = useParams();

  const handleCartClick = () => {
    if (isNavigationOpen) {
      onNavigationClose();
    } else {
      onCartOpen();
    }
  };

  const handleMenuClick = () => {
    if (isMenuDrawerOpen) {
      onMenuDrawerClose();
    } else {
      onMenuDrawerOpen();
    }
  };

  return (
    <Stack flexBasis="100%" p={4} spacing={4}>
      <Flex justifyContent="space-between">
        <IconButton
          aria-label="Menu"
          bg="none"
          icon={<ArrowBackIcon boxSize={6} />}
          onClick={() => navigate(`/${orgId}/${propertyId}`)}
        />
        <Box position="relative">
          <IconButton
            onClick={handleMenuClick}
            aria-label="ShoppingCart"
            bg="none"
            icon={<HamburgerIcon boxSize={6} />}
          />
          {unit && leaseTerm && (
            <Box
              position="absolute"
              top="7px"
              right="5px"
              width="12px"
              height="12px"
              bg="red.500"
              borderRadius="full"
              border="2px solid white"
            />
          )}
        </Box>
      </Flex>
      <Flex justifyContent="space-between" onClick={handleCartClick} px={2}>
        <Box>{filteredPages[activeStepIndex]?.name}</Box>
        <Flex alignItems="center" fontSize="11px">
          {`Step ${activeStepIndex + 1} of ${filteredPages.length} `}
          <ChevronDownIcon ml={1} />
        </Flex>
      </Flex>
      <MobileNavigation
        isOpen={isNavigationOpen}
        onClose={onNavigationClose}
        onFilteredPages={handleFilteredPages}
      />
      <MobileMenuDrawer isOpen={isMenuDrawerOpen} onClose={onMenuDrawerClose} />
    </Stack>
  );
}
