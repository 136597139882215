"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Section = void 0;
var Section = /** @class */ (function () {
    function Section() {
        this.title = '';
        this.sequence = 0;
        this.elements = [];
    }
    return Section;
}());
exports.Section = Section;
