/* istanbul ignore file */
import { queryClient } from 'AppWrapper';

import { getReservedUnitForApplicant } from 'services';
import handleError from 'utils/handleError';

export function useCheckIsUnitReserved() {
  async function checkIsUnitReserved(
    unitSpaceId: number,
    propertyId: number,
  ): Promise<boolean> {
    if (!propertyId) {
      handleError(`Form Viewer - Property ID missing`, `Property ID missing`);
      return false;
    }

    try {
      const { data } = await queryClient.fetchQuery({
        queryKey: ['reserve-unit', propertyId],
        queryFn: () => getReservedUnitForApplicant(propertyId),
      });

      if (!data?.unitSpaceId) return false;

      return data.unitSpaceId === unitSpaceId;
    } catch {
      return false;
    }
  }

  return checkIsUnitReserved;
}
