"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApplicationTemplate = void 0;
var ApplicationTemplate = /** @class */ (function () {
    function ApplicationTemplate() {
        this.name = '';
        this.iconUrl = null;
        this.description = null;
        this.occupancyType = '';
        this.formId = '';
    }
    return ApplicationTemplate;
}());
exports.ApplicationTemplate = ApplicationTemplate;
