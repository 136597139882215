"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaseTerm = void 0;
var LeaseTerm = /** @class */ (function () {
    function LeaseTerm() {
        this.id = 0;
        this.name = '';
        this.amount = 0;
        this.rateFrequency = '';
        this.termMonth = '';
        this.deposit = 0;
    }
    return LeaseTerm;
}());
exports.LeaseTerm = LeaseTerm;
