import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Text } from '@chakra-ui/react';
import { PageType } from '@common/entities';
import { NotificationStatus } from 'enums/notification-status';
import { updatePreviewModeActiveStep } from 'state/slices/navigationSlice';
import { RootState } from 'state/store';

import { AddIcon } from 'components/custom-icons';
import notification from 'components/notification';

interface Props {
  handleDrawerClose?: () => void;
}

export function NoUnitAvailable({ handleDrawerClose }: Props) {
  const navigate = useNavigate();

  const { orgId, propertyId } = useParams();

  const dispatch = useDispatch();
  const { isPreviewMode } = useSelector(
    (state: RootState) => state.auth ?? { isPreviewMode: false },
  );
  const { form } = useSelector((state: RootState) => state.application);
  const { pages } = form;

  function getFloorPlanPageIndex() {
    return pages.findIndex((page) => page.type === PageType.FloorPlans);
  }

  function redirectToFloorPlanPage() {
    const pageIndex = getFloorPlanPageIndex();
    if (pageIndex < 0) {
      notification('Page is not available.', NotificationStatus.ERROR);
      return;
    }
    if (isPreviewMode) {
      dispatch(updatePreviewModeActiveStep(pageIndex));
    } else {
      navigate(`/${orgId}/${propertyId}/${pages[pageIndex].id}`);
      if (handleDrawerClose) {
        handleDrawerClose();
      }
    }
  }

  return (
    <Box py={4}>
      <Text fontSize="sm">
        Please select a unit to make sure all of the details below are reflected
        accurately.
      </Text>
      <Button
        mt={4}
        size="sm"
        leftIcon={<AddIcon color="black.base" />}
        bg="white.base"
        color="blue.bright"
        border="1px solid"
        borderColor="silver.base"
        _hover={{
          bg: 'white.base',
          color: 'blue.bright',
        }}
        onClick={redirectToFloorPlanPage}
      >
        Select a Unit
      </Button>
    </Box>
  );
}
