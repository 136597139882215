"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STUDENT_IDENTIFICATION_NUMBER = void 0;
var entities_1 = require("@common/entities");
exports.STUDENT_IDENTIFICATION_NUMBER = {
    name: 'student_identification_number',
    label: 'Student Identification Number',
    customType: entities_1.IDTypes.StudentIdentificationNumber,
    elementType: entities_1.SectionElementType.Block,
    fields: [
        {
            customType: entities_1.FieldType.Text,
            enabled: true,
            label: 'Student Identification Number',
            name: 'value',
            required: true,
            sequence: 1,
            type: entities_1.FieldType.Text,
        },
    ],
};
