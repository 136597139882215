import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetStoreAction } from 'state/commonActions';

import { MoneyGramAccountInfo, PaymentType } from 'types/payments';

export type PaymentAccount = {
  expMonth: number;
  expYear: number;
  id: number;
  mask: string;
  name: string;
  paymentTypeId: number;
  paymentTypeName: string;
  type: string;
};

export type PaymentStore = {
  paymentTypes: PaymentType[];
  selectedPaymentType: string | null;
  paymentInfo: any;
  savePaymentInfo: boolean;
  termsAndConditionAck: boolean;
  error: string[];
  paymentAccount: PaymentAccount | null;
  paymentSettings: { [key: string]: any };
  moneyGramAccountInfo: MoneyGramAccountInfo | null;
};

const initialState: PaymentStore = {
  paymentTypes: [],
  selectedPaymentType: null,
  paymentInfo: null,
  savePaymentInfo: false,
  termsAndConditionAck: false,
  error: [],
  paymentAccount: null,
  paymentSettings: {},
  moneyGramAccountInfo: null,
};

const paymentSlice = createSlice({
  name: 'paymentState',
  initialState,
  reducers: {
    setPaymentType: (state, action: PayloadAction<PaymentType[]>) => {
      return { ...state, paymentTypes: action.payload };
    },
    changePaymentType: (state, action: PayloadAction<string>) => {
      const {
        selectedPaymentType: oldPaymentType,
        paymentInfo,
        error,
        moneyGramAccountInfo,
      } = state;

      const isChanged = oldPaymentType !== action.payload;
      const { payload } = action;

      return {
        ...state,
        selectedPaymentType: isChanged ? payload : oldPaymentType,
        paymentInfo: isChanged ? null : paymentInfo,
        error: isChanged ? [] : error,
        moneyGramAccountInfo: isChanged ? null : moneyGramAccountInfo,
      };
    },
    setPaymentError: (state, action: PayloadAction<string[]>) => {
      return {
        ...state,
        error: action.payload,
      };
    },
    updatePaymentInfo: (
      state,
      action: PayloadAction<{ [key: string]: any }>,
    ) => {
      return {
        ...state,
        paymentInfo: {
          ...state.paymentInfo,
          ...action.payload,
        },
      };
    },
    resetPaymentInfo: (state) => {
      return {
        ...state,
        paymentInfo: null,
      };
    },
    updateTermsAndConditionAck(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        termsAndConditionAck: action.payload,
      };
    },
    savePaymentInformation: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        savePaymentInfo: action.payload,
      };
    },
    setPaymentAccount: (state, action: PayloadAction<PaymentAccount>) => {
      return {
        ...state,
        paymentAccount: action.payload,
      };
    },
    setPaymentSettings: (
      state,
      action: PayloadAction<{ [key: string]: any }>,
    ) => {
      return {
        ...state,
        paymentSettings: action.payload,
      };
    },
    updateMoneyGramAccountInfo: (
      state,
      action: PayloadAction<MoneyGramAccountInfo>,
    ) => {
      return { ...state, moneyGramAccountInfo: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStoreAction, () => initialState);
  },
});

export const {
  changePaymentType,
  resetPaymentInfo,
  savePaymentInformation,
  setPaymentAccount,
  setPaymentError,
  setPaymentType,
  updatePaymentInfo,
  updateTermsAndConditionAck,
  setPaymentSettings,
  updateMoneyGramAccountInfo,
} = paymentSlice.actions;
export default paymentSlice.reducer;
