import { useDispatch } from 'react-redux';
import { Applicant, PageType, RelationshipType } from '@common/entities';
import { EntrataSession } from 'auth/EntrataSession';
import { setApplicationData } from 'state/slices/applicationSlice';
import { setIsPreviewMode } from 'state/slices/authSlice';
import {
  setActivePageId,
  setLeasingJourneyError,
  updateLeasingJourneyErrors,
} from 'state/slices/navigationSlice';
import { updateResponseState } from 'state/slices/responseSlice';
import {
  updateAddon,
  updateSelectedSpecials,
  updateUnitSelection,
} from 'state/slices/unitSelectionSlice';

import { useCheckToShowPaymentsPage } from 'hooks/payments';
import { getResponse } from 'services';
import type { Form } from 'types/form';
import { getTodaysDate } from 'utils/date';
import { prepareFormInitializingData } from 'utils/form';
import { getActiveResponse } from 'utils/getActiveResponse';
import { getFilteredErrors } from 'utils/getFilteredErrors';
import handleError from 'utils/handleError';
import { getPageIndex } from 'utils/page-utils';

import { getSecondaryApplicantStoreJson } from '../utils/getSecondaryApplicantStoreJson';

import useInitializeQuotes from './quotes/useInitializeQuotes';
import { useSetFloorPlanPageDataInStore } from './floor-plans';
import { useGetPropertySettings } from './useGetPropertySettings';
import { useInitializeSpecials } from './useInitializeSpecials';

type Props = { applicant: Applicant; form: Form; responseId?: string };
export const useInitializeLJApplication = () => {
  const dispatch = useDispatch();

  const initializeFloorPlanPageData = useSetFloorPlanPageDataInStore();
  const initializePropertySettings = useGetPropertySettings();
  const checkToShowPaymentsPage = useCheckToShowPaymentsPage();
  const initializeQuotes = useInitializeQuotes();
  const initializeSpecials = useInitializeSpecials();

  function updateAuthStore() {
    dispatch(setIsPreviewMode(false));
  }

  async function initializeFloorPlanStore(
    propertyId: number,
    { pages }: Form,
    response: any,
    leaseId: number,
  ) {
    const pageIndex = getPageIndex(pages, PageType.FloorPlans);
    if (pageIndex < 0) return;

    const { floorPlanData } = response;
    if (!floorPlanData) {
      const todaysDate = getTodaysDate();
      dispatch(updateUnitSelection({ moveInDate: todaysDate }));
      return;
    }

    const { floorplanId, unitSpaceId, termMonth, moveInDate } = floorPlanData;
    const errorCount = await initializeFloorPlanPageData(
      moveInDate,
      floorplanId,
      unitSpaceId,
      termMonth,
      propertyId,
      leaseId,
    );
    dispatch(
      updateLeasingJourneyErrors({
        pageIndex,
        count: errorCount,
      }),
    );
  }

  function initializeResponseStore(response: any) {
    const formValue = prepareFormInitializingData(response) as any;
    dispatch(updateResponseState(formValue));
    return formValue;
  }

  function initializeNavigationStore({ pages }: Form, response: any) {
    const parsedResponses = JSON.parse(response.details);
    const { filteredPages, stepsStatus } = getFilteredErrors(
      pages,
      parsedResponses,
    );

    const activePageId = parsedResponses?.pages?.find(
      (page: any) => page.isCurrentPage === true,
    );

    if (activePageId) dispatch(setActivePageId(activePageId.pageId));

    dispatch(
      setLeasingJourneyError({ pages: filteredPages, errors: stepsStatus }),
    );
  }

  async function initializeApplicationStore(
    applicant: Applicant,
    formData: Form,
    responseId: string,
  ) {
    let updatedForm = await checkToShowPaymentsPage(formData, responseId);
    updatedForm = checkToHideSecondaryApplicantPages(
      applicant,
      updatedForm,
      responseId,
    );
    dispatch(
      setApplicationData({
        applicant,
        form: updatedForm,
        activeResponseId: responseId,
      }),
    );

    return updatedForm;
  }

  function checkToHideSecondaryApplicantPages(
    applicant: Applicant,
    formData: Form,
    responseId: string,
  ): Form {
    const activeResponse = getActiveResponse(applicant, responseId);
    if (
      activeResponse?.relationshipType === undefined ||
      activeResponse.relationshipType === RelationshipType.Primary
    ) {
      return formData;
    }

    const store = getSecondaryApplicantStoreJson(
      formData,
      applicant,
      responseId,
      activeResponse.relationshipType,
    );
    return store.application.form;
  }

  const initializeQuotesPage = async ({ pages }: Form, responseData: any) => {
    const isQuotesPageAvailable = getPageIndex(pages, PageType.Quotes);
    if (isQuotesPageAvailable < 0) return;
    const { quote } = responseData;
    if (!quote) return;

    const { quoteId, leaseTermId } = quote;
    await initializeQuotes(pages, quoteId, leaseTermId);
  };

  const initializeUnitSelection = async (
    propertyId: number,
    form: Form,
    response: any,
    leaseId: number,
  ) => {
    const { quotes, floorPlanData } = response;
    if (!quotes) {
      await initializeFloorPlanStore(propertyId, form, response, leaseId);
    }
    if (!floorPlanData) await initializeQuotesPage(form, response);
  };

  const initializeAddons = (response: any) => {
    const { addOns, selectedSpecials } = response;
    if (addOns) dispatch(updateAddon(addOns));
    if (selectedSpecials) dispatch(updateSelectedSpecials(selectedSpecials));
  };

  async function initializeStore(
    applicant: Applicant,
    formData: Form,
    userResponse: any,
    responseId: string,
  ) {
    const updatedForm = await initializeApplicationStore(
      applicant,
      formData,
      responseId,
    );
    initializeNavigationStore(updatedForm, userResponse);
    const responseObject = initializeResponseStore(userResponse);

    const { propertyId } = applicant;
    const activeResponse = getActiveResponse(applicant, responseId);
    await initializeUnitSelection(
      propertyId,
      updatedForm,
      responseObject,
      activeResponse.leaseId,
    );
    await initializePropertySettings(propertyId);
    await initializeSpecials();
    initializeAddons(responseObject);
    updateAuthStore();

    return updatedForm;
  }

  async function fetchExistingResponse(responseId: string) {
    try {
      const response = await getResponse(responseId);
      return response?.data;
    } catch (e: any) {
      handleError(
        `Form Viewer - Error fetching responses: ${e}`,
        `Error fetching responses`,
      );
      return {};
    }
  }

  function setSession(applicant: Applicant) {
    EntrataSession.setApplicant(applicant);
  }

  async function initializeApplication(data: Props) {
    const { applicant, form: formData, responseId } = data;
    try {
      if (!responseId) {
        throw new Error('Response Id is missing.');
      }

      setSession(applicant);

      const response = await fetchExistingResponse(responseId);
      const updatedForm = await initializeStore(
        applicant,
        formData,
        response,
        responseId,
      );

      return { form: updatedForm, applicant };
    } catch (e: any) {
      throw new Error(e?.message ?? 'Error while initializing application');
    }
  }

  return initializeApplication;
};
