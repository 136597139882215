/* istanbul ignore file */
import { useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';
import { useLocation } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';

import { LeasingJourneyHeader } from 'components/header';
import { Loader } from 'components/Loader';
import { getProspectDocument } from 'services';
import handleError from 'utils/handleError';

import PdfRenderer from './PdfRenderer';

function PdfViewer() {
  const location = useLocation();

  const [documentUrl, setDocumentUrl] = useState('');

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

  const { isPending, data, error } = useQuery({
    queryKey: ['view-prospect-document', location.state.documentId],
    queryFn: () => getProspectDocument(location.state.documentId),
  });

  useEffect(() => {
    if (data) {
      const fileData = new Blob([data.data], { type: 'application/pdf' });
      setDocumentUrl(URL.createObjectURL(fileData));
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      handleError(
        `Form Viewer - Error viewing PDF: ${error}`,
        'Error viewing PDF.',
      );
    }
  }, [error]);

  if (isPending) return <Loader label="Loading document..." />;

  return (
    <Flex flexDir="column" h="100%">
      <LeasingJourneyHeader />
      {(isPending || !documentUrl) && <Loader label="Loading document..." />}
      {documentUrl && (
        <PdfRenderer
          enablePaging={false}
          documentUrl={documentUrl}
          documentName={location.state.documentName}
          showFooter
        />
      )}
    </Flex>
  );
}

export default PdfViewer;
