"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CERTIFICATE_OF_DEPOSIT_FIELDS = void 0;
var entities_1 = require("@common/entities");
var ASSET_FIELDS_1 = require("./ASSET_FIELDS");
exports.CERTIFICATE_OF_DEPOSIT_FIELDS = {
    name: 'certificate-of-deposit',
    label: 'Certificate of Deposit',
    customType: entities_1.AssetTypes.CertificateOfDeposit,
    elementType: entities_1.SectionElementType.Block,
    documentUpload: {
        maxFilesAllowed: '2',
        verifyDocumentUpload: true,
    },
    fields: (0, ASSET_FIELDS_1.getAssetFields)('certificates_of_deposit'),
};
