"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlockField = exports.Block = void 0;
var enums_1 = require("./enums");
var Block = /** @class */ (function () {
    function Block() {
        this.sequence = 0;
        this.elementType = enums_1.SectionElementType.Block;
        this.name = '';
    }
    return Block;
}());
exports.Block = Block;
var BlockField = /** @class */ (function () {
    function BlockField() {
        this.id = '';
        this.name = '';
        this.required = false;
        this.enabled = true;
    }
    return BlockField;
}());
exports.BlockField = BlockField;
