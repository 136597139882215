"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FieldOption = void 0;
var FieldOption = /** @class */ (function () {
    function FieldOption() {
        this.sequence = 0;
        this.label = '';
    }
    return FieldOption;
}());
exports.FieldOption = FieldOption;
