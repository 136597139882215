"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Version = void 0;
var Version = /** @class */ (function () {
    function Version() {
        this.versionNumber = null;
        this.status = '';
        this.updatedBy = null;
        this.parentVersionId = '';
    }
    return Version;
}());
exports.Version = Version;
