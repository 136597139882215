import { useSelector } from 'react-redux';
import { RootState } from 'state/store';

export const useDisabledFields = (fieldName: string): boolean => {
  const fieldSettings = useSelector((state: RootState) => state.fieldSettings);

  const isApplicationLocked = fieldSettings?.isApplicationLocked ?? false;
  const disabledFields = fieldSettings?.disabledFields ?? [];

  return isApplicationLocked || disabledFields.includes(fieldName);
};
