"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.MONEY_GRAM_ID = exports.E_CHECK_ID = exports.MONEY_GRAM = exports.E_CHECK = exports.CREDIT_DEBIT_CARD = exports.CARD_ID_NAME_MAPPING = exports.CARD_PAYMENT_ID = void 0;
var entities_1 = require("@common/entities");
exports.CARD_PAYMENT_ID = [
    entities_1.PaymentTypeId.VISA_ID,
    entities_1.PaymentTypeId.MASTERCARD_ID,
    entities_1.PaymentTypeId.DISCOVER_ID,
    entities_1.PaymentTypeId.AMERICAN_EXPRESS_ID,
];
exports.CARD_ID_NAME_MAPPING = (_a = {},
    _a[entities_1.PaymentTypeId.VISA_ID] = entities_1.CardPaymentType.VISA,
    _a[entities_1.PaymentTypeId.MASTERCARD_ID] = entities_1.CardPaymentType.MASTERCARD,
    _a[entities_1.PaymentTypeId.DISCOVER_ID] = entities_1.CardPaymentType.DISCOVER,
    _a[entities_1.PaymentTypeId.AMERICAN_EXPRESS_ID] = entities_1.CardPaymentType.AMEX,
    _a);
exports.CREDIT_DEBIT_CARD = 'Credit/Debit';
exports.E_CHECK = 'ACH';
exports.MONEY_GRAM = 'Moneygram';
exports.E_CHECK_ID = entities_1.PaymentTypeId.E_CHECK_ID, exports.MONEY_GRAM_ID = entities_1.PaymentTypeId.MONEY_GRAM_ID;
