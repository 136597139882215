import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/store';

import { getMoneyGramAccount } from 'services';

export function useProcessMoneyGramPayment() {
  const [isLoading, setIsLoading] = useState(false);
  const { responseId } = useSelector(
    (state: RootState) => state.application.activeResponse,
  );

  async function processMoneyGramPayment() {
    setIsLoading(true);

    try {
      const response = await getMoneyGramAccount(responseId as string);
      if (!response) throw new Error('Please create moneygram account.');
    } catch (err: any) {
      throw new Error(err?.message ?? 'Something went wrong.');
    } finally {
      setIsLoading(false);
    }
  }

  return {
    isPaymentProcessing: isLoading,
    processMoneyGramPayment,
  };
}
