"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadedFile = void 0;
var enums_1 = require("./enums");
var UploadedFile = /** @class */ (function () {
    function UploadedFile() {
        this.userId = 0;
        this.applicationId = '';
        this.responseId = '';
        this.vendor = '';
        this.container = '';
        this.fileKey = '';
        this.etag = '';
        this.fileType = enums_1.UploadedFileType.Unknown;
        this.vendorResponse = '';
        this.groupKey = '';
        this.fileName = '';
        this.fileSize = 0;
    }
    return UploadedFile;
}());
exports.UploadedFile = UploadedFile;
