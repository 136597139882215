"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormChangeLog = void 0;
var FormChangeLog = /** @class */ (function () {
    function FormChangeLog() {
        this.userId = 0;
        this.formId = '';
        this.userName = '';
        this.description = '';
        this.changeDate = new Date();
        this.changeType = '';
        this.changeEntity = '';
    }
    return FormChangeLog;
}());
exports.FormChangeLog = FormChangeLog;
