"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChargeAmount = exports.ScheduledCharge = exports.ScheduledChargeGroup = void 0;
var ScheduledChargeGroup = /** @class */ (function () {
    function ScheduledChargeGroup() {
        this.orgId = 0;
        this.leaseId = 0;
        this.propertyId = 0;
        this.leaseIntervalId = 0;
        this.arTriggerId = 0;
        this.arTriggerName = '';
        this.installmentId = 0;
        this.endsWithMoveOut = true;
        this.chargeAmount = new ChargeAmount();
        this.totalValue = 0;
        this.ledgerFilterId = 0;
        this.arCodeGroupTypeId = 0;
        this.arCodeGroupName = '';
        this.arCodeName = '';
        this.isHistorical = false;
        this.customerName = '';
        this.taxAmount = 0;
        this.summarizationGroupId = 0;
    }
    return ScheduledChargeGroup;
}());
exports.ScheduledChargeGroup = ScheduledChargeGroup;
var ScheduledCharge = /** @class */ (function () {
    function ScheduledCharge() {
        this.scheduledChargeId = 0;
        this.arCodeId = 0;
        this.arCodeName = '';
        this.chargeAmount = 0;
    }
    return ScheduledCharge;
}());
exports.ScheduledCharge = ScheduledCharge;
var ChargeAmount = /** @class */ (function () {
    function ChargeAmount() {
        this.amountNumber = 0;
        this.amountFormatted = '';
        this.currencyCode = '';
    }
    return ChargeAmount;
}());
exports.ChargeAmount = ChargeAmount;
