"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubsidyType = exports.SubsidyProgram = exports.MOCK_SUBSIDY_PROGRAMS = void 0;
exports.MOCK_SUBSIDY_PROGRAMS = [
    {
        id: 1,
        name: 'Section 8 housing',
        type: {
            id: 1,
            name: 'HUD',
        },
    },
    {
        id: 2,
        name: 'Rent Supplement',
        type: {
            id: 1,
            name: 'HUD',
        },
    },
    {
        id: 3,
        name: 'Housing Tax Credit Set-aside',
        type: {
            id: 1,
            name: 'HUD',
        },
    },
    {
        id: 4,
        name: 'Section 811 with Project Rental',
        type: {
            id: 1,
            name: 'HUD',
        },
    },
    {
        id: 5,
        name: 'Assistance Contracts',
        type: {
            id: 1,
            name: 'HUD',
        },
    },
    {
        id: 6,
        name: '15/40 Set-aside',
        type: {
            id: 1,
            name: 'HUD',
        },
    },
    {
        id: 7,
        name: 'ADHP Set-aside',
        type: {
            id: 1,
            name: 'HUD',
        },
    },
    {
        id: 8,
        name: 'High HOME Rent Limit',
        type: {
            id: 1,
            name: 'HUD',
        },
    },
    {
        id: 9,
        name: 'State housing trust fund (SHTF)',
        type: {
            id: 1,
            name: 'HUD',
        },
    },
    {
        id: 10,
        name: 'Set-aside',
        type: {
            id: 1,
            name: 'HUD',
        },
    },
    {
        id: 11,
        name: 'Exchange Set-aside',
        type: {
            id: 1,
            name: 'HUD',
        },
    },
    {
        id: 12,
        name: '40/60 Set-aside',
        type: {
            id: 1,
            name: 'HUD',
        },
    },
    {
        id: 13,
        name: '20/50 Set-aside',
        type: {
            id: 1,
            name: 'HUD',
        },
    },
];
var SubsidyProgram = /** @class */ (function () {
    function SubsidyProgram() {
        this.id = 0;
        this.name = '';
    }
    return SubsidyProgram;
}());
exports.SubsidyProgram = SubsidyProgram;
var SubsidyType = /** @class */ (function () {
    function SubsidyType() {
        this.id = 0;
        this.name = '';
    }
    return SubsidyType;
}());
exports.SubsidyType = SubsidyType;
