import { get, post } from 'config/api/config';

const GET_SPECIAL_DETAILS_ENDPOINT = '/viewer/specials/special-details';
const ACCEPT_OFFERS_ENDPOINT = '/viewer/specials/accept-offers';

/**
 * GET specials
 * @returns promise
 */
export const getSpecials = async () => {
  const response = await get(`${GET_SPECIAL_DETAILS_ENDPOINT}`);
  return response.data;
};

/**
 * Accept offers
 * @returns promise
 */
export const postAcceptOffers = async (leaseTermId: number, userId: number, quoteId?: number): Promise<any> => {
  const url = `${ACCEPT_OFFERS_ENDPOINT}?leaseTermId=${leaseTermId}&userId=${userId}&quoteId=${quoteId}`;
  const response = await post(url);
  return response;
};
