/* istanbul ignore file */
import React from 'react';
import { Box, Flex, Spinner } from '@chakra-ui/react';
import styled from '@emotion/styled';

interface IProps {
  label?: string;
}

function Loader({ label }: IProps) {
  return (
    <StyledSpinner>
      <Flex flexDirection="column" alignItems="center">
        <Box>
          <Spinner size="xl" />
        </Box>
        {label && <Box>{label}</Box>}
      </Flex>
    </StyledSpinner>
  );
}

export { Loader };

const StyledSpinner = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(5px);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
`;
