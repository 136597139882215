/* eslint-disable no-param-reassign */
import { Applicant, defaultApplicant } from '@common/entities';
import { createSlice } from '@reduxjs/toolkit';
import { resetStoreAction } from 'state/commonActions';

export interface ApplicationForm {
  id: string;
  name: string;
  description: string;
  type: string;
  clientId: number;
  pages: any[];
}

export interface LeadProfileApplicant {
  responseId: string;
  entrataApplicationId?: number;
  leaseId?: number;
  firstName: string;
  lastName: string;
  email: string;
  responseDetails: any;
  relationshipType: string;
  startDate?: string;
  updatedDate?: string;
  paymentProcessed?: boolean;
  paymentAccountId?: string;
}

export interface ApplicantsState {
  primaryResponseId: string;
  primaryApplicant: Applicant;
  applicationForm: ApplicationForm;
  applicants: LeadProfileApplicant[];
  applicantResponses: any;
}

const initialState: ApplicantsState = {
  primaryResponseId: '',
  applicationForm: {
    id: '',
    name: '',
    description: '',
    type: '',
    clientId: 0,
    pages: [],
  },
  primaryApplicant: defaultApplicant,
  applicants: [],
  applicantResponses: [],
};

export const leadProfileSlice = createSlice({
  name: 'leadProfile',
  initialState,
  reducers: {
    setApplicantsData: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    updateApplicantResponses: (state, action) => {
      const { tabIndex, data } = action.payload;

      if (!state.applicantResponses[tabIndex]) {
        state.applicantResponses[tabIndex] = {};
      }

      state.applicantResponses[tabIndex] = {
        ...state.applicantResponses[tabIndex],
        ...data,
      };
    },
    addApplicant: (state, action) => {
      state.applicants.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStoreAction, () => initialState);
  },
});

export const { setApplicantsData, updateApplicantResponses, addApplicant } =
  leadProfileSlice.actions;
export default leadProfileSlice.reducer;
