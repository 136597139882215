"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeadProfileApplicants = void 0;
var form_entity_1 = require("./form.entity");
var applicant_1 = require("./applicant");
var LeadProfileApplicants = /** @class */ (function () {
    function LeadProfileApplicants() {
        this.primaryResponseId = '';
        this.applicationForm = new form_entity_1.Form();
        this.applicants = [];
        this.primaryApplicant = applicant_1.defaultApplicant;
    }
    return LeadProfileApplicants;
}());
exports.LeadProfileApplicants = LeadProfileApplicants;
