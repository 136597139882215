"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PREVIOUS_ADDRESS_FIELDS = exports.MAILING_ADDRESS_FIELDS = exports.CURRENT_ADDRESS_FIELDS = exports.BILLING_ADDRESS_FIELDS = void 0;
var BILLING_ADDRESS_FIELDS_1 = require("./BILLING_ADDRESS_FIELDS");
Object.defineProperty(exports, "BILLING_ADDRESS_FIELDS", { enumerable: true, get: function () { return BILLING_ADDRESS_FIELDS_1.BILLING_ADDRESS_FIELDS; } });
var CURRENT_ADDRESS_FIELDS_1 = require("./CURRENT_ADDRESS_FIELDS");
Object.defineProperty(exports, "CURRENT_ADDRESS_FIELDS", { enumerable: true, get: function () { return CURRENT_ADDRESS_FIELDS_1.CURRENT_ADDRESS_FIELDS; } });
var MAILING_ADDRESS_FIELDS_1 = require("./MAILING_ADDRESS_FIELDS");
Object.defineProperty(exports, "MAILING_ADDRESS_FIELDS", { enumerable: true, get: function () { return MAILING_ADDRESS_FIELDS_1.MAILING_ADDRESS_FIELDS; } });
var PREVIOUS_ADDRESS_FIELDS_1 = require("./PREVIOUS_ADDRESS_FIELDS");
Object.defineProperty(exports, "PREVIOUS_ADDRESS_FIELDS", { enumerable: true, get: function () { return PREVIOUS_ADDRESS_FIELDS_1.PREVIOUS_ADDRESS_FIELDS; } });
