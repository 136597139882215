"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EMPLOYER_FIELDS = exports.OTHER_INCOME_FIELDS = exports.SELF_EMPLOYER_FIELDS = exports.PREVIOUS_EMPLOYER_FIELDS = exports.CURRENT_EMPLOYER_FIELDS = void 0;
var CURRENT_EMPLOYER_FIELDS_1 = require("./CURRENT_EMPLOYER_FIELDS");
Object.defineProperty(exports, "CURRENT_EMPLOYER_FIELDS", { enumerable: true, get: function () { return CURRENT_EMPLOYER_FIELDS_1.CURRENT_EMPLOYER_FIELDS; } });
var PREVIOUS_EMPLOYER_FIELDS_1 = require("./PREVIOUS_EMPLOYER_FIELDS");
Object.defineProperty(exports, "PREVIOUS_EMPLOYER_FIELDS", { enumerable: true, get: function () { return PREVIOUS_EMPLOYER_FIELDS_1.PREVIOUS_EMPLOYER_FIELDS; } });
var SELF_EMPLOYER_FIELDS_1 = require("./SELF_EMPLOYER_FIELDS");
Object.defineProperty(exports, "SELF_EMPLOYER_FIELDS", { enumerable: true, get: function () { return SELF_EMPLOYER_FIELDS_1.SELF_EMPLOYER_FIELDS; } });
var OTHER_INCOME_FIELDS_1 = require("./OTHER_INCOME_FIELDS");
Object.defineProperty(exports, "OTHER_INCOME_FIELDS", { enumerable: true, get: function () { return OTHER_INCOME_FIELDS_1.OTHER_INCOME_FIELDS; } });
var EMPLOYER_FIELDS_1 = require("./EMPLOYER_FIELDS");
Object.defineProperty(exports, "EMPLOYER_FIELDS", { enumerable: true, get: function () { return EMPLOYER_FIELDS_1.EMPLOYER_FIELDS; } });
