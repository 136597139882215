/**
 * Deep clone objects or Array
 *
 * @param {Mixed} original Array or Objects
 * @returns Cloned Objects || Array
 */
export const deepCopy = (original: any): any => {
  if (Array.isArray(original)) {
    return original.map((elem) => deepCopy(elem));
  }
  if (typeof original === 'object' && original !== null) {
    return Object.fromEntries(
      Object.entries(original).map(([k, v]) => [k, deepCopy(v)]),
    );
  }
  return original;
};

/**
 * Check if two objects are equal by deep comparison
 *
 * @param {any} obj1 First object
 * @param {any} obj2 Second object
 * @returns {boolean} True if objects are equal, false otherwise
 */
export const areObjectsEqual = (obj1: any, obj2: any): boolean => {
  const clonedObj1 = deepCopy(obj1);
  const clonedObj2 = deepCopy(obj2);
  return JSON.stringify(clonedObj1) === JSON.stringify(clonedObj2);
};
