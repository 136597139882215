"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ID_TYPE_ENUM_MAPPING = exports.PRIMARY_ID_TYPE = exports.ID_TYPES = void 0;
var entities_1 = require("@common/entities");
var DRIVERS_LICENSE_1 = require("./DRIVERS_LICENSE");
var GOVERNMENT_ISSUED_PICTURE_ID_1 = require("./GOVERNMENT_ISSUED_PICTURE_ID");
var MILITARY_ID_1 = require("./MILITARY_ID");
var PASSPORT_1 = require("./PASSPORT");
var SOCIAL_SECURITY_NUMBER_1 = require("./SOCIAL_SECURITY_NUMBER");
var STUDENT_IDENTIFICATION_NUMBER_1 = require("./STUDENT_IDENTIFICATION_NUMBER");
var VISA_1 = require("./VISA");
exports.ID_TYPES = [
    { value: entities_1.IDTypes.DriversLicense, label: 'Drivers License' },
    {
        value: entities_1.IDTypes.GovernmentIssuedPhotoId,
        label: 'Government Issued Picture Id',
    },
    { value: entities_1.IDTypes.MilitaryId, label: 'Military Id' },
    { value: entities_1.IDTypes.Passport, label: 'Passport' },
    {
        value: entities_1.IDTypes.StudentIdentificationNumber,
        label: 'Student Identification Number',
    },
    { value: entities_1.IDTypes.Visa, label: 'Visa' },
];
exports.PRIMARY_ID_TYPE = __spreadArray([
    { value: entities_1.IDTypes.SocialSecurityNumber, label: 'Social Security Number' }
], exports.ID_TYPES, true);
exports.ID_TYPE_ENUM_MAPPING = (_a = {},
    _a[entities_1.IDTypes.SocialSecurityNumber] = SOCIAL_SECURITY_NUMBER_1.SOCIAL_SECURITY_NUMBER,
    _a[entities_1.IDTypes.DriversLicense] = DRIVERS_LICENSE_1.DRIVERS_LICENSE,
    _a[entities_1.IDTypes.GovernmentIssuedPhotoId] = GOVERNMENT_ISSUED_PICTURE_ID_1.GOVERNMENT_ISSUED_PICTURE_ID,
    _a[entities_1.IDTypes.MilitaryId] = MILITARY_ID_1.MILITARY_ID,
    _a[entities_1.IDTypes.Passport] = PASSPORT_1.PASSPORT,
    _a[entities_1.IDTypes.StudentIdentificationNumber] = STUDENT_IDENTIFICATION_NUMBER_1.STUDENT_IDENTIFICATION_NUMBER,
    _a[entities_1.IDTypes.Visa] = VISA_1.VISA,
    _a);
