"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.commonInputs = void 0;
exports.commonInputs = {
    baseStyle: {
        field: {
            _focus: {
                borderColor: 'blue.500',
                boxShadow: '0 0 0 2px rgba(59, 130, 246)',
            },
        },
        _focus: {
            borderColor: 'blue.500',
            boxShadow: '0 0 0 2px rgba(59, 130, 246)',
        },
    },
    sizes: {
        lg: {
            field: {
                fontSize: '0.938rem',
            },
        },
    },
    variants: {
        base: {
            field: {
                _disabled: {
                    opacity: 1,
                },
                _invalid: {
                    borderColor: 'red.light',
                },
                border: '1px solid',
                borderColor: 'gray.400',
                bg: 'white.base',
            },
            _disabled: {
                opacity: 1,
            },
            _invalid: {
                borderColor: 'red.light',
            },
            border: '1px solid',
            borderColor: 'gray.400',
            bg: 'white.base',
        },
    },
    defaultProps: {
        size: 'lg',
        variant: 'base',
    },
};
var FieldOverrides = {
    NumberInput: __assign({}, exports.commonInputs),
    Input: __assign({}, exports.commonInputs),
    Textarea: __assign({}, exports.commonInputs),
    Select: __assign({}, exports.commonInputs),
};
exports.default = FieldOverrides;
