"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Merchants = void 0;
var Merchants = /** @class */ (function () {
    function Merchants() {
        this.merchants = [];
        this.paymentAllowed = false;
    }
    return Merchants;
}());
exports.Merchants = Merchants;
