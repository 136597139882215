import { PageType } from '@common/entities';

import { getPaymentTypes } from 'services';
import type { Form } from 'types/form';

export function useCheckToShowPaymentsPage() {
  async function shouldShowPaymentPage(form: Form, responseId: string) {
    const { pages } = form;
    const pageIndex = pages.findIndex((i) => i.type === PageType.Payment);
    if (pageIndex < 0) return form;
    try {
      const { data } = await getPaymentTypes(10, responseId);
      if (!data.length) throw new Error();
      return form;
    } catch {
      const updatedPages = pages.filter((i) => i.type !== PageType.Payment);
      return { ...form, pages: updatedPages };
    }
  }

  return shouldShowPaymentPage;
}
