import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetStoreAction } from 'state/commonActions';

export type PetReducer = {
  selectedPets: string[];
};
const initialState: PetReducer = {
  selectedPets: [],
};

export const petsSlice = createSlice({
  name: 'pets',
  initialState,
  reducers: {
    setSelectedPets: (state, action: PayloadAction<string[]>) => {
      return {
        ...state,
        selectedPets: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStoreAction, () => initialState);
  },
});

export const { setSelectedPets } = petsSlice.actions;
export default petsSlice.reducer;
