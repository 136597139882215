"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DisplayConditionValue = exports.DisplayCondition = exports.DisplayLogic = void 0;
var enums_1 = require("./enums");
var DisplayLogic = /** @class */ (function () {
    function DisplayLogic() {
        this.displayAction = enums_1.DisplayAction.Show;
        this.conditionalLogicalOperator = enums_1.DisplayLogicConditionalOperator.And;
        this.displayConditions = [];
    }
    return DisplayLogic;
}());
exports.DisplayLogic = DisplayLogic;
var DisplayCondition = /** @class */ (function () {
    function DisplayCondition() {
        this.id = '';
        this.displayConditionValues = [];
    }
    return DisplayCondition;
}());
exports.DisplayCondition = DisplayCondition;
var DisplayConditionValue = /** @class */ (function () {
    function DisplayConditionValue() {
        this.id = '';
        this.value = '';
        this.valueOperator = enums_1.ValueOperator.Equals;
    }
    return DisplayConditionValue;
}());
exports.DisplayConditionValue = DisplayConditionValue;
