import { useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { RootState } from 'state/store';

import { patchResponseForId } from 'services';

interface SaveResponseProps {
  currentPageId: string;
  applicationId: string;
  propertyId: number;
  responses: { [key: string]: any }[];
  errorCount?: number;
  targetPageId?: string;
}

interface SaveResponse {
  responseData: SaveResponseProps;
  responseId: string;
}

export function useSaveResponse() {
  const { mutateAsync: updateResponse } = useMutation({
    mutationFn: patchResponseForId,
  });

  const { responseId } = useSelector(
    (state: RootState) => state.application.activeResponse,
  );

  async function saveResponse(data: SaveResponseProps) {
    const props: SaveResponse = {
      responseData: data,
      responseId,
    };
    const response = await updateResponse(props);
    return response;
  }

  return saveResponse;
}
