// Utilty functions for specials
import { SpecialType } from 'enums/special-type';

export const getStringUnitSpecialsForUnit = (unit: any, unitSpaceSpecials: any, unitTypeSpecials: any) => {
  let concatenatedSpecials = '';
  if (unitSpaceSpecials) {
    unitSpaceSpecials
    .filter((special: any) => special.arCascadeReferenceIds.includes(unit.unitSpaceId))
    .forEach((special: any) => {
      if (concatenatedSpecials.length > 0) {
        concatenatedSpecials += '\n';
      }
      concatenatedSpecials += `${special.groupName} `;
    });
  }
  if (unitTypeSpecials) {
    unitTypeSpecials
    .filter((special: any) => special.arCascadeReferenceIds.includes(unit.unitTypeId))
    .forEach((special: any) => {
      if (concatenatedSpecials.length > 0) {
        concatenatedSpecials += '\n';
      }
      concatenatedSpecials += `${special.groupName} `;
    });
  }
  return concatenatedSpecials;
}

export const getStringAllSpecialsForUnit = (
  floorplan: any, 
  unit: any, 
  propertySpecials: any,
  floorplanSpecials: any,
  unitSpaceSpecials: any, 
  unitTypeSpecials: any) => {
  let concatenatedSpecials = '';
  if (propertySpecials) {
    propertySpecials.forEach((special: any) => {
      if (concatenatedSpecials.length > 0) {
        concatenatedSpecials += '\n';
      }
      concatenatedSpecials += `${special.groupName} `;
    });
  } 
  if (floorplanSpecials) {
    floorplanSpecials
    .filter((special: any) => special.arCascadeReferenceIds.includes(floorplan?.id))
    .forEach((special: any) => {
      if (concatenatedSpecials.length > 0) {
        concatenatedSpecials += '\n';
      }
      concatenatedSpecials += `${special.groupName} `;
    });
  }
  if (concatenatedSpecials.length > 0) {
    concatenatedSpecials += '\n';
  }
  concatenatedSpecials += getStringUnitSpecialsForUnit(unit, unitSpaceSpecials, unitTypeSpecials);
return concatenatedSpecials;
}

export const getUnitSpecialsForUnit = (unit: any, unitSpaceSpecials: any, unitTypeSpecials: any) => {
  const unitSpecials: any[] = [];
  if (unitSpaceSpecials) {
    unitSpaceSpecials
    .filter((special: any) => special.arCascadeReferenceIds.includes(unit?.unitSpaceId))
    .forEach((special: any) => {
      unitSpecials.push(special);
    });
  }
  if (unitTypeSpecials) {
    unitTypeSpecials
    .filter((special: any) => special.arCascadeReferenceIds.includes(unit?.unitTypeId))
    .forEach((special: any) => {
      unitSpecials.push(special);
    });
  }
  return unitSpecials;
}

export const getAllStandardSpecialsForUnit = (
  floorplan: any, 
  unit: any, 
  propertySpecials: any,
  floorplanSpecials: any,
  unitSpaceSpecials: any, 
  unitTypeSpecials: any) => {
  const allSpecials = [];
  if (propertySpecials) {
    propertySpecials.forEach((special: any) => {
      allSpecials.push(special);
    });
  } 
  if (floorplanSpecials) {
    floorplanSpecials
    .filter((special: any) => special.arCascadeReferenceIds.includes(floorplan?.id))
    .forEach((special: any) => {
      allSpecials.push(special);
    });
  }
  allSpecials.push(...getUnitSpecialsForUnit(unit, unitSpaceSpecials, unitTypeSpecials));
  return allSpecials;
}

export const getAllPromoCodeSpecialsForUnit = (
  floorplan: any, 
  unit: any, 
  promoCodeSpecials: any) => {
  const allPromoCodeSpecials: any[] = [];
  if (promoCodeSpecials) {
    promoCodeSpecials.forEach((special: any) => {
      if (special.arCascadeId === SpecialType.Property) {
        allPromoCodeSpecials.push(special);
      } else if (special.arCascadeId === SpecialType.Floorplan && special.arCascadeReferenceIds.includes(floorplan?.id)) {
        allPromoCodeSpecials.push(special);
      } else if (special.arCascadeId === SpecialType.UnitSpace && special.arCascadeReferenceIds.includes(unit?.unitSpaceId)) {
        allPromoCodeSpecials.push(special);
      } else if (special.arCascadeId === SpecialType.UnitType && special.arCascadeReferenceIds.includes(unit?.unitTypeId)) {
        allPromoCodeSpecials.push(special);
      }
    })
  }
  return allPromoCodeSpecials;
}

