import * as React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onClickAction: () => void;
  modalText: string;
  actionBtnText?: string;
  actionBtnVariant: 'success' | 'caution' | 'danger' | 'neutral' | 'brand';
}
export function ConfirmationModal(props: Props) {
  const {
    isOpen,
    onClose,
    onClickAction,
    modalText,
    actionBtnText = 'Submit',
    actionBtnVariant = 'success',
  } = props;

  return (
    <Modal size="xs" isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay
        bg="black.200"
        backdropFilter="blur(12px) hue-rotate(0deg)"
      />
      <ModalContent
        bg="white.base"
        border="1px"
        borderColor="gray.light"
        borderRadius={8}
      >
        <ModalBody px={6} py={8}>
          <Text textAlign="center" wordBreak="break-word">
            {modalText}
          </Text>
        </ModalBody>
        <ModalFooter
          borderBottomRightRadius={8}
          borderBottomLeftRadius={8}
          bg="silver.essence"
        >
          <Button size="sm" onClick={onClose} mr={4}>
            Close
          </Button>
          <Button size="sm" variant={actionBtnVariant} onClick={onClickAction}>
            {actionBtnText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
