import { PropertySettingType } from '@common/entities';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetStoreAction } from 'state/commonActions';

export type PropertySetting = {
  settingType: PropertySettingType;
  value: any;
}

export type PropertySettingsReducer = {
  propertySettings: PropertySetting[];
};
const initialState: PropertySettingsReducer = {
  propertySettings: [],
};

export const propertySettingsSlice = createSlice({
  name: 'propertySettings',
  initialState,
  reducers: {
    setPropertySettings: (state, action: PayloadAction<PropertySetting[]>) => {
      return {
        ...state,
        propertySettings: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStoreAction, () => initialState);
  },
});

export const { setPropertySettings } = propertySettingsSlice.actions;
export default propertySettingsSlice.reducer;
