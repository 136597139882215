"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PetType = void 0;
var PetType = /** @class */ (function () {
    function PetType() {
        this.id = '';
        this.name = '';
        this.description = '';
        this.monthlyRate = 0;
        this.deposit = 0;
    }
    return PetType;
}());
exports.PetType = PetType;
