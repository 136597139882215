import React from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

interface Props {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}
export function ApplicationModal(props: Props) {
  const { title, isOpen, onClose, children } = props;

  return (
    <Modal
      isCentered
      size="5xl"
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      closeOnOverlayClick={false}
    >
      <ModalOverlay
        bg="black.200"
        backdropFilter="blur(12px) hue-rotate(0deg)"
      />
      <ModalContent background="white.base">
        <ModalHeader
          background="blue.base"
          color="white.base"
          p={2}
          fontSize={16}
        >
          {title}
        </ModalHeader>
        <ModalCloseButton color="white.base" marginRight={100} paddingBottom={1} w={20} _hover={{bg: 'blue.base'}}>   
          Discard
        </ModalCloseButton>
        <ModalBody minHeight={500}>{children}</ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
}
