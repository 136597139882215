import { useDispatch, useSelector } from 'react-redux';
import { queryClient } from 'AppWrapper';
import { SpecialType } from 'enums/special-type';
import { updateSpecials } from 'state/slices/unitSelectionSlice';
import { RootState } from 'state/store';

import { getSpecials } from 'services';

export const useInitializeSpecials = () => {
  const dispatch = useDispatch();

  const getSpecialsByType = (responseSpecials: any, specialType: SpecialType) => {
    const specials: any = [];
    responseSpecials.forEach((special: any) => {
      if (special.arCascadeId === specialType) {
        if (
          specials.find((pspecial: any) => pspecial.groupId === special.groupId)
        )
          return;
        specials.push(special);
      }
    });
    return specials;
  };

  async function fetchSpecials() {
    try {
      const data = await queryClient.fetchQuery({
        queryKey: ['specials'],
        queryFn: () => getSpecialsCheckPreview(),
      });
      return data;
    } catch {
      throw new Error('Error while fetching specials.');
    }
  }

  const { isPreviewMode } = useSelector(
    (state: RootState) => state.auth ?? { isPreviewMode: false },
  );

  function getSpecialsCheckPreview() {
    if (isPreviewMode) return [];
    return getSpecials();
  }

  async function initializeSpecials() {
    try {
      const specials = await fetchSpecials();
      dispatch(updateSpecials({ 
        propertySpecials: getSpecialsByType(specials, SpecialType.Property),
        floorplanSpecials: getSpecialsByType(specials, SpecialType.Floorplan),
        unitTypeSpecials: getSpecialsByType(specials, SpecialType.UnitType),
        unitSpaceSpecials: getSpecialsByType(specials, SpecialType.UnitSpace),
       }));
    } catch (e: any) {
      throw new Error(e?.message ?? 'Error while initializing application');
    }
  }

  return initializeSpecials;
};
