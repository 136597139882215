import { createSlice } from '@reduxjs/toolkit';

type BrandingReducer = {
  media?: any;
};

const initialState = {
  media: null,
} as BrandingReducer;

const brandingSlice = createSlice({
  name: 'branding',
  initialState,
  reducers: {
    setBrandingMedia: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.media = action.payload;
    },
  },
});

export const { setBrandingMedia } = brandingSlice.actions;
export default brandingSlice.reducer;
