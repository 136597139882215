/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import {
  ChakraProvider,
  createStandaloneToast,
  extendTheme,
} from '@chakra-ui/react';
import { theme as baseTheme } from '@common/ui';
import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import App from 'App';
import { EntrataSession } from 'auth/EntrataSession';
import { setIsAuthenticated } from 'state/slices/authSlice';
import { setBrandingMedia } from 'state/slices/brandingSlice';
import { useDispatch, useSelector } from 'state/storeHooks';
import { CustomDevTools } from 'test-utils/CustomDevTools';

import { Loader } from 'components/Loader';
import { useInitializeLJApplication } from 'hooks/useInitializeLJApplication';
import useGetURLParamsObj from 'hooks/utils/useGetURLParamsObj';
import { getBrandingColors, getBrandingMedia } from 'services/branding';

import '@entrata/i18n';

// sync translation data from global var
i18nUtils.syncTranslations('__ENTRATA_APPLICATIONS__');

interface QueryParams {
  websiteId?: string;
  rwxDomain?: string;
  track?: string;
}

const { ToastContainer } = createStandaloneToast();

const AppWrapper = () => {
  const { isAuthenticated, isNewAccount, applicant, form, activeResponse } =
    useSelector((state) => ({
      isAuthenticated: state.auth.isAuthenticated,
      isNewAccount: state.auth.isNewAccount,
      applicant: state.application.applicant,
      form: state.application.form,
      activeResponse: state.application.activeResponse,
    }));
  const { responseId } = activeResponse;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [brandingColors, setBrandingColors] = useState<any | null>(null);

  const getParamsObj = useGetURLParamsObj();

  const initializeLJApplication = useInitializeLJApplication();

  const parseAndValidateSearchParams = (): QueryParams | null => {
    const queryParams = getParamsObj();

    if (queryParams.entrataRedirectLink) {
      sessionStorage.setItem(
        'entrataRedirectLink',
        queryParams.entrataRedirectLink.replace(/\.com\/.*$/, '.com'),
      );
    }
    if (queryParams.websiteId && queryParams.rwxDomain && queryParams.track) {
      sessionStorage.setItem('queryParams', JSON.stringify(queryParams));
      return queryParams;
    }
    return null;
  };

  useEffect(() => {
    EntrataSession.setAppPath(
      `${window.location.protocol}//${window.location.host}${window.location.pathname}${window.location.search}`,
    );
    const storedBrandingColorsString = sessionStorage.getItem('brandingColors');
    const storedBrandingMediaString = sessionStorage.getItem('brandingMedia');

    const storedBrandingColors = JSON.parse(
      storedBrandingColorsString as string,
    );
    const storedBrandingMedia = JSON.parse(storedBrandingMediaString as string);

    if (storedBrandingColors) {
      setBrandingColors(storedBrandingColors);
    }
    if (storedBrandingMedia) {
      dispatch(setBrandingMedia(storedBrandingMedia));
    }

    if (!storedBrandingColors || !storedBrandingMedia) {
      const queryParams = parseAndValidateSearchParams();

      if (queryParams) {
        setLoading(true);
        const { websiteId, rwxDomain, track } = queryParams;
        const brandParams: any = {
          websiteId,
          domain: rwxDomain,
          releaseTrack: track,
        };

        const getBrandingData = async () => {
          try {
            const [responseColors, responseMedia] = await Promise.all([
              getBrandingColors(brandParams),
              getBrandingMedia(brandParams),
            ]);

            sessionStorage.setItem(
              'brandingColors',
              JSON.stringify(responseColors.data),
            );
            sessionStorage.setItem(
              'brandingMedia',
              JSON.stringify(responseMedia.data),
            );
            setBrandingColors(responseColors.data);
            dispatch(setBrandingMedia(responseMedia.data));
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error fetching data:', error);
          } finally {
            setLoading(false);
          }
        };

        getBrandingData();
      } else {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    async function initializeApp() {
      setLoading(true);
      if (responseId) {
        await initializeLJApplication({ form, applicant, responseId });
      }
      setLoading(false);
    }

    const isPreviewMode = location.pathname.includes('/preview/');

    if (EntrataSession.sessionExpired) {
      dispatch(setIsAuthenticated(false));
      return;
    }

    if (isAuthenticated && !isNewAccount && !isPreviewMode) {
      initializeApp();
    }

    if (
      location.pathname === '/' &&
      applicant.org?.id &&
      applicant.propertyId
    ) {
      navigate(`/${applicant.org.id}/${applicant.propertyId}/entry`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const theme = extendTheme({
    ...baseTheme,
    colors: {
      ...baseTheme.colors,
      brand: {
        ...baseTheme.colors.brand,
        base: brandingColors?.core['base-color']?.value
          ? `#${brandingColors.core['base-color'].value}`
          : baseTheme.colors.brand.base,
        text: brandingColors?.core['text-color']?.value
          ? `#${brandingColors.core['text-color'].value}`
          : baseTheme.colors.brand.text,
        accent1: brandingColors?.core['accent-color-1']?.value
          ? `#${brandingColors.core['accent-color-1'].value}`
          : baseTheme.colors.brand.accent1,
        accent2: brandingColors?.core['accent-color-2']?.value
          ? `#${brandingColors.core['accent-color-2'].value}`
          : baseTheme.colors.brand.accent2,
        accent3: brandingColors?.core['accent-color-3']?.value
          ? `#${brandingColors.core['accent-color-3'].value}`
          : baseTheme.colors.brand.accent3,
        accent4: brandingColors?.core['accent-color-4']?.value
          ? `#${brandingColors.core['accent-color-4'].value}`
          : baseTheme.colors.brand.accent4,
        background: brandingColors?.core['background-color']?.value
          ? `#${brandingColors.core['background-color'].value}`
          : baseTheme.colors.brand.background,
        header: brandingColors?.core['header-color']?.value
          ? `#${brandingColors.core['header-color'].value}`
          : baseTheme.colors.brand.header,
        link: brandingColors?.core['link-color']?.value
          ? `#${brandingColors.core['link-color'].value}`
          : baseTheme.colors.brand.link,
        primaryButton: brandingColors?.core['primary-button-color']?.value
          ? `#${brandingColors.core['primary-button-color'].value}`
          : baseTheme.colors.brand.primaryButton,
        secondaryButton: brandingColors?.core['secondary-button-color']?.value
          ? `#${brandingColors.core['secondary-button-color'].value}`
          : baseTheme.colors.brand.secondaryButton,
        tertiaryButton: brandingColors?.core['tertiary-button-color']?.value
          ? `#${brandingColors.core['tertiary-button-color'].value}`
          : baseTheme.colors.brand.tertiaryButton,
      },
    },
  });

  return (
    <ChakraProvider theme={theme} cssVarsRoot="body">
      <Routes>
        {loading ? (
          <Route path="/*" element={<Loader />} />
        ) : (
          <Route path="/*" element={<App />} />
        )}
      </Routes>
      <ToastContainer />
      <ReactQueryDevtools initialIsOpen={false} />
      <CustomDevTools />
    </ChakraProvider>
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

export { AppWrapper, queryClient };
