import { useDispatch, useSelector } from 'react-redux';
import { PageType } from '@common/entities';
import { setQuote } from 'state/slices/unitSelectionSlice';
import { RootState } from 'state/store';

import { getPageIndex } from 'utils/page-utils';

const useRemoveQuoteSelection = () => {
  const dispatch = useDispatch();
  const { pages } = useSelector((state: RootState) => state.application.form);
  const { quote, quoteLeaseTerm } = useSelector(
    (state: RootState) => state.unitSelection,
  );

  const isQuotesPageExist = () => {
    const pageIndex = getPageIndex(pages, PageType.Quotes);
    return pageIndex >= 0;
  };

  const isUpdateRequired = () => {
    if (quote === null && quoteLeaseTerm === null) return false;
    return true;
  };

  const removeQuoteSelection = () => {
    const isExist = isQuotesPageExist();
    if (!isExist) return;

    const isUpdateNeeded = isUpdateRequired();
    if (!isUpdateNeeded) return;

    dispatch(
      setQuote({
        quote: null,
        quoteLeaseTerm: null,
      }),
    );
  };

  return removeQuoteSelection;
};

export default useRemoveQuoteSelection;
