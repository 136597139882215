"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActiveApplication = void 0;
var ActiveApplication = /** @class */ (function () {
    function ActiveApplication() {
        this.applicationId = '';
        this.versionId = '';
        this.name = '';
    }
    return ActiveApplication;
}());
exports.ActiveApplication = ActiveApplication;
