import { useDispatch, useSelector } from 'react-redux';
import { PaymentTypeId } from '@common/entities';
import { useMutation } from '@tanstack/react-query';
import { PaymentAccount, setPaymentAccount } from 'state/slices/paymentSlice';
import { RootState } from 'state/store';

import { useGetCardChargesDue } from 'hooks/payments';
import {
  addPaymentAccount,
  getPaymentAccounts,
  makePayment,
  PaymentScreening,
} from 'services';
import { prepareACHRequest } from 'utils/payment/ach-utils';

export function useProcessACHPayment() {
  const dispatch = useDispatch();
  const { paymentInfo, paymentTypes, paymentSettings } = useSelector(
    (state: RootState) => state.payment,
  );
  const { responseId } = useSelector(
    (state: RootState) => state.application.activeResponse,
  );

  const { charges } = useSelector((state: RootState) => state.shoppingCart);

  const { isPending: isAddingPayment, mutateAsync: addPaymentCall } =
    useMutation({
      mutationFn: addPaymentAccount,
    });
  const { isPending: isPaymentProcessing, mutateAsync: makePaymentRequest } =
    useMutation({
      mutationFn: makePayment,
    });
  const {
    isPending: isGettingPaymentAccounts,
    mutateAsync: getPaymentAccountsCall,
  } = useMutation({
    mutationFn: getPaymentAccounts,
  });

  const getCardCharges = useGetCardChargesDue();

  function getDueNowCharges() {
    if (!charges.length) return 0;
    return charges
      .filter((charge) => charge.dueNow)
      .reduce((acc, { priceNumber }) => {
        return acc + priceNumber;
      }, 0);
  }

  async function completePayment(paymentAccount: any) {
    const { id: paymentAccountId, paymentTypeId } = paymentAccount;

    const dueNowCharges = getDueNowCharges();
    const paymentTypeCharges = getCardCharges();

    try {
      await makePaymentRequest({
        paymentAmount: dueNowCharges + paymentTypeCharges,
        paymentAccountId,
        paymentTypeId,
        responseId,
      });
    } catch (err: any) {
      throw new Error(err?.message ?? 'Something went wrong.');
    }
  }

  function getPaymentAccount(
    achRequest: any,
    paymentAccounts: PaymentAccount[],
  ): PaymentAccount | null {
    const achAccount = paymentAccounts.find(
      (a: any) => a.paymentTypeId === PaymentTypeId.E_CHECK_ID,
    );
    return achAccount ?? null;
  }

  // eslint-disable-next-line no-unused-vars
  async function processACHPayment(data?: PaymentScreening) {
    try {
      const request: any = prepareACHRequest(
        paymentInfo,
        paymentTypes,
        paymentSettings,
        data,
      );
      request.responseId = responseId;
      const paymentAccountsResponse = await getPaymentAccountsCall();
      const account = getPaymentAccount(request, paymentAccountsResponse.data);
      let paymentAccount: PaymentAccount;

      if (account) {
        paymentAccount = {
          id: account.id,
          paymentTypeId: request.paymentTypeId,
          expMonth: 0,
          expYear: 0,
          mask: '',
          name: '',
          paymentTypeName: '',
          type: '',
        };
      } else {
        const response = await addPaymentCall(request);
        paymentAccount = response.data;
      }

      dispatch(setPaymentAccount(paymentAccount));
      await completePayment(paymentAccount);
    } catch (err: any) {
      throw new Error(err?.message ?? 'Something went wrong.');
    }
  }

  return {
    isPaymentProcessing,
    processACHPayment,
    isAddingPayment,
    isGettingPaymentAccounts,
  };
}
