"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HOUSEHOLD_FLEEING_DOMESTIC_VIOLENCE_FIELD = void 0;
var entities_1 = require("@common/entities");
var constants_1 = require("../../constants");
exports.HOUSEHOLD_FLEEING_DOMESTIC_VIOLENCE_FIELD = [
    {
        label: 'Are any members of this household fleeing domestic violence?',
        type: entities_1.FieldType.YesNo,
        customType: entities_1.FieldType.YesNo,
        sequence: 1,
        name: 'household_fleeing_domestic_violence',
        required: false,
        enabled: true,
        options: constants_1.YES_NO_OPTIONS,
    },
];
