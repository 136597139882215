let newRelicAccountId: any;
let newRelicTrustKey: any;
let newRelicAgentID: any;
let newRelicLicenseKey: any;
let newRelicApplicationId: any;
let newRelicCrossOriginDomains: any;

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
  newRelicAccountId = process.env.NEW_RELIC_BROWSER_ACCOUNT_ID;
  newRelicTrustKey = process.env.NEW_RELIC_BROWSER_TRUST_KEY;
  newRelicAgentID = process.env.NEW_RELIC_BROWSER_AGENT_ID_VIEWER;
  newRelicLicenseKey = process.env.NEW_RELIC_BROWSER_LICENSE_KEY;
  newRelicApplicationId = process.env.NEW_RELIC_BROWSER_APPLICATION_ID_VIEWER;
  newRelicCrossOriginDomains =
    process.env.NEW_RELIC_BROWSER_CROSS_ORIGIN_DOMAIN;
} else {
  newRelicAccountId = (window as any).env?.NEW_RELIC_BROWSER_ACCOUNT_ID;
  newRelicTrustKey = (window as any).env?.NEW_RELIC_BROWSER_TRUST_KEY;
  newRelicAgentID = (window as any).env?.NEW_RELIC_BROWSER_AGENT_ID_VIEWER;
  newRelicLicenseKey = (window as any).env?.NEW_RELIC_BROWSER_LICENSE_KEY;
  newRelicApplicationId = (window as any).env
    ?.NEW_RELIC_BROWSER_APPLICATION_ID_VIEWER;
  newRelicCrossOriginDomains = (window as any).env
    ?.NEW_RELIC_BROWSER_CROSS_ORIGIN_DOMAIN;
}

const NEW_RELIC_BROWSER_ACCOUNT_ID = newRelicAccountId;
const NEW_RELIC_BROWSER_TRUST_KEY = newRelicTrustKey;
const NEW_RELIC_BROWSER_AGENT_ID_VIEWER = newRelicAgentID;
const NEW_RELIC_BROWSER_LICENSE_KEY = newRelicLicenseKey;
const NEW_RELIC_BROWSER_APPLICATION_ID_VIEWER = newRelicApplicationId;
const NEW_RELIC_BROWSER_CROSS_ORIGIN_DOMAIN = newRelicCrossOriginDomains;

export {
  NEW_RELIC_BROWSER_ACCOUNT_ID,
  NEW_RELIC_BROWSER_AGENT_ID_VIEWER,
  NEW_RELIC_BROWSER_APPLICATION_ID_VIEWER,
  NEW_RELIC_BROWSER_CROSS_ORIGIN_DOMAIN,
  NEW_RELIC_BROWSER_LICENSE_KEY,
  NEW_RELIC_BROWSER_TRUST_KEY,
};
