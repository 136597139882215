"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VEHICLE_FIELDS = void 0;
var entities_1 = require("@common/entities");
exports.VEHICLE_FIELDS = [
    {
        label: 'Make',
        type: entities_1.FieldType.Text,
        sequence: 1,
        name: 'make',
        customType: entities_1.FieldType.Text,
        required: true,
        enabled: true,
    },
    {
        label: 'Model',
        type: entities_1.FieldType.Text,
        sequence: 2,
        name: 'model',
        customType: entities_1.FieldType.Text,
        required: true,
        enabled: true,
    },
    {
        label: 'Color',
        type: entities_1.FieldType.Text,
        sequence: 3,
        name: 'color',
        customType: entities_1.FieldType.Text,
        required: true,
        enabled: true,
    },
    {
        label: 'Year',
        type: entities_1.FieldType.Number,
        sequence: 4,
        name: 'year',
        customType: entities_1.FieldType.Number,
        required: true,
        enabled: true,
    },
    {
        label: 'Plate Number',
        type: entities_1.FieldType.Text,
        sequence: 5,
        name: 'license_plate_number',
        customType: entities_1.FieldType.Text,
        required: true,
        enabled: true,
    },
    {
        customType: entities_1.FieldType.CountryDropdown,
        enabled: true,
        label: 'Country',
        name: 'country_code',
        options: [],
        required: true,
        sequence: 6,
        type: entities_1.FieldType.CountryDropdown,
    },
    {
        label: 'State',
        type: entities_1.FieldType.StateDropdown,
        sequence: 7,
        name: 'state_code',
        customType: entities_1.FieldType.StateDropdown,
        required: true,
        enabled: true,
    },
];
