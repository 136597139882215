"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouterTargetType = exports.ApplicationType = exports.DisplayLogicConditionalOperator = exports.ValueOperator = exports.DisplayAction = exports.StaticPageId = exports.PropertySettingType = exports.ApplicantContext = exports.ScreeningConditionSubsetType = exports.ScreeningConditionOperandType = exports.ReleaseTrack = exports.FeatureFlag = exports.DocumentSigningProcedure = exports.LeaseChargeType = exports.ChargeCodeType = exports.ScreeningConditionActionTakenType = exports.FinancialScreeningConditionType = exports.ScreeningConditionType = exports.IDTypes = exports.IdentificationApplicant = exports.FloorPlanType = exports.AddOnType = exports.UploadedFileVendor = exports.UploadedFileType = exports.EntrataApplicationStage = exports.EntrataApplicationStatus = exports.CustomerType = exports.RelationshipType = exports.AssetTypes = exports.AddressTypes = exports.IncomeTypes = exports.Interval = exports.ImageLayout = exports.SectionElementType = exports.BlockType = exports.PageType = exports.FieldType = exports.ResponseJsonDataName = exports.FormChangeAction = exports.FormVersionStatus = exports.OccupancyType = exports.FormType = void 0;
exports.mapEntrataCustomerTypeToRelationshipType = mapEntrataCustomerTypeToRelationshipType;
exports.LeaseChargeTypeIsRecurring = LeaseChargeTypeIsRecurring;
exports.LeaseChargeTypeToDisplayText = LeaseChargeTypeToDisplayText;
var FormType;
(function (FormType) {
    FormType["Application"] = "application";
    FormType["ApplicationTemplate"] = "application-template";
    FormType["Router"] = "router";
})(FormType || (exports.FormType = FormType = {}));
var OccupancyType;
(function (OccupancyType) {
    OccupancyType["Conventional"] = "conventional";
    OccupancyType["Student"] = "student";
    OccupancyType["Military"] = "military";
    OccupancyType["Affordable"] = "affordable";
    OccupancyType["FlexiblTerm"] = "flexible-term";
})(OccupancyType || (exports.OccupancyType = OccupancyType = {}));
var FormVersionStatus;
(function (FormVersionStatus) {
    FormVersionStatus["Draft"] = "draft";
    FormVersionStatus["Active"] = "active";
    FormVersionStatus["Inactive"] = "inactive";
    FormVersionStatus["DraftInactive"] = "draft-inactive";
})(FormVersionStatus || (exports.FormVersionStatus = FormVersionStatus = {}));
var FormChangeAction;
(function (FormChangeAction) {
    FormChangeAction["Updated"] = "updated";
    FormChangeAction["Deleted"] = "deleted";
    FormChangeAction["Created"] = "created";
    FormChangeAction["Published"] = "published";
})(FormChangeAction || (exports.FormChangeAction = FormChangeAction = {}));
var ResponseJsonDataName;
(function (ResponseJsonDataName) {
    ResponseJsonDataName["Applicant"] = "applicantData";
    ResponseJsonDataName["Coapplicant"] = "coApplicant";
    ResponseJsonDataName["Guarantor"] = "guarantor";
    ResponseJsonDataName["Floorplan"] = "floorPlanData";
    ResponseJsonDataName["CurrentAddress"] = "currentAddress";
    ResponseJsonDataName["PreviousAddress"] = "previousAddress";
})(ResponseJsonDataName || (exports.ResponseJsonDataName = ResponseJsonDataName = {}));
var FieldType;
(function (FieldType) {
    FieldType["Boolean"] = "boolean";
    FieldType["Checkbox"] = "checkbox";
    FieldType["ChooseMany"] = "choose-many";
    FieldType["ChooseManyDropdown"] = "choose-many-dropdown";
    FieldType["ChooseOne"] = "choose-one";
    FieldType["ChooseOneDropdown"] = "choose-one-dropdown";
    FieldType["CountPicker"] = "count-picker";
    FieldType["CountryDropdown"] = "country-dropdown";
    FieldType["Date"] = "date";
    FieldType["Dropdown"] = "dropdown";
    FieldType["DigitalSignature"] = "digital-signature";
    FieldType["Email"] = "email";
    FieldType["File"] = "file";
    FieldType["Height"] = "height";
    FieldType["InternationalPhoneNumber"] = "international-phone-number";
    FieldType["Number"] = "numeric";
    FieldType["LongText"] = "long-text";
    FieldType["OccupationDropDown"] = "occupation-dropdown";
    FieldType["PreviousHousingArrangements"] = "previous-housing-arrangements";
    FieldType["PreviousSubsidyTypes"] = "previous-subsidy-types";
    FieldType["DisplacementStatuses"] = "displacement-statuses";
    FieldType["Gender"] = "gender";
    FieldType["Title"] = "title";
    FieldType["Ethnicity"] = "ethnicity";
    FieldType["Race"] = "race";
    FieldType["IncomeExceptions"] = "income-exceptions";
    FieldType["StudentExceptions"] = "student-exceptions";
    FieldType["Password"] = "password";
    FieldType["PersonInformation"] = "person-information";
    FieldType["PhoneNumber"] = "phone-number";
    FieldType["Picker"] = "picker";
    FieldType["Radio"] = "radio";
    FieldType["ReferenceLink"] = "reference-link";
    FieldType["StateDropdown"] = "state-dropdown";
    FieldType["StaticText"] = "static-text";
    FieldType["Switch"] = "switch";
    FieldType["Text"] = "text";
    FieldType["TextArea"] = "text-area";
    FieldType["Weight"] = "weight";
    FieldType["YesNo"] = "yes-no";
    FieldType["ZipCode"] = "zip-code";
})(FieldType || (exports.FieldType = FieldType = {}));
var PageType;
(function (PageType) {
    PageType["Blank"] = "blank";
    PageType["Payment"] = "payment";
    PageType["Documents"] = "documents";
    PageType["Options"] = "options";
    PageType["Roommate"] = "roommate";
    PageType["Interests"] = "interests";
    PageType["Summary"] = "summary";
    PageType["ThankYou"] = "thank-you";
    PageType["FloorPlans"] = "floor-plans";
    PageType["ScreeningConditions"] = "screening-conditions";
    PageType["Quotes"] = "quotes";
    PageType["Router"] = "router";
})(PageType || (exports.PageType = PageType = {}));
var BlockType;
(function (BlockType) {
    BlockType["AddressCurrent"] = "ADDRESS_CURRENT";
    BlockType["AddressPrevious"] = "ADDRESS_PREVIOUS";
    BlockType["Asset"] = "FINANCIAL_ASSETS";
    BlockType["Coapplicant"] = "COAPPLICANT";
    BlockType["Documents"] = "DOCUMENTS";
    BlockType["IdentificationType"] = "IDENTIFICATION_TYPE";
    BlockType["Income"] = "INCOME";
    BlockType["Pet"] = "PET";
    BlockType["Vehicle"] = "VEHICLE";
    BlockType["Guarantor"] = "GUARANTOR";
    BlockType["GeneralQuestion"] = "GENERAL_QUESTION";
    BlockType["Disability"] = "DISABILITY";
    BlockType["Student"] = "STUDENT";
    BlockType["PoliceSecurityOfficer"] = "POLICE_SECURITY_OFFICER";
    BlockType["MilitaryVeteran"] = "MILITARY_VETERAN";
    BlockType["HouseholdFleeingDomesticViolence"] = "HOUSEHOLD_FLEEING_DOMESTIC_VIOLENCE";
    BlockType["HousedDeclaredDisaster"] = "HOUSED_DECLARED_DISASTER";
})(BlockType || (exports.BlockType = BlockType = {}));
var SectionElementType;
(function (SectionElementType) {
    SectionElementType["Block"] = "block";
    SectionElementType["Field"] = "field";
})(SectionElementType || (exports.SectionElementType = SectionElementType = {}));
var ImageLayout;
(function (ImageLayout) {
    ImageLayout["Grid"] = "grid";
    ImageLayout["Stacked"] = "stacked";
    ImageLayout["SideBySide"] = "side-by-side";
})(ImageLayout || (exports.ImageLayout = ImageLayout = {}));
var Interval;
(function (Interval) {
    Interval["Monthly"] = "monthly";
    Interval["Weekly"] = "weekly";
    Interval["Yearly"] = "yearly";
})(Interval || (exports.Interval = Interval = {}));
var IncomeTypes;
(function (IncomeTypes) {
    IncomeTypes["CurrentEmployer"] = "Current Employer";
    IncomeTypes["PreviousEmployer"] = "Previous Employer";
    IncomeTypes["SelfEmployer"] = "Self Employer";
    IncomeTypes["OtherIncome"] = "Other Income";
})(IncomeTypes || (exports.IncomeTypes = IncomeTypes = {}));
var AddressTypes;
(function (AddressTypes) {
    AddressTypes["CurrentAddress"] = "current-address";
    AddressTypes["PreviousAddress"] = "previous-address";
    AddressTypes["MailingAddress"] = "mailing-address";
    AddressTypes["BillingAddress"] = "billing-address";
})(AddressTypes || (exports.AddressTypes = AddressTypes = {}));
var AssetTypes;
(function (AssetTypes) {
    AssetTypes["CheckingAccount"] = "Checking Accounts";
    AssetTypes["SavingsAccount"] = "Savings Accounts";
    AssetTypes["CertificateOfDeposit"] = "Certificates Of Deposit";
    AssetTypes["StocksOrBonds"] = "Stocks Or Bonds";
    AssetTypes["RetirementAccount"] = "Retirement Accounts";
})(AssetTypes || (exports.AssetTypes = AssetTypes = {}));
var RelationshipType;
(function (RelationshipType) {
    RelationshipType["Primary"] = "primary";
    RelationshipType["Coapplicant"] = "coapplicant";
    RelationshipType["Guarantor"] = "guarantor";
    RelationshipType["Other"] = "other";
})(RelationshipType || (exports.RelationshipType = RelationshipType = {}));
var CustomerType;
(function (CustomerType) {
    // These values are defined in Entrata Core
    //
    CustomerType["Primary"] = "1";
    CustomerType["Responsible"] = "2";
    CustomerType["NotResponsible"] = "3";
    CustomerType["Guarantor"] = "4";
    CustomerType["NonLeasingOccupant"] = "5";
})(CustomerType || (exports.CustomerType = CustomerType = {}));
function mapEntrataCustomerTypeToRelationshipType(customerType) {
    switch (customerType) {
        case CustomerType.Primary:
            return RelationshipType.Primary;
        case CustomerType.Responsible:
            return RelationshipType.Coapplicant;
        case CustomerType.Guarantor:
            return RelationshipType.Guarantor;
        default:
            return RelationshipType.Other;
    }
}
var EntrataApplicationStatus;
(function (EntrataApplicationStatus) {
    // These values are defined in Entrata Core
    //
    EntrataApplicationStatus["Started"] = "started";
    EntrataApplicationStatus["PartiallyCompleted"] = "partially completed";
    EntrataApplicationStatus["Completed"] = "completed";
    EntrataApplicationStatus["Approved"] = "approved";
    EntrataApplicationStatus["Archived"] = "archived";
    EntrataApplicationStatus["Cancelled"] = "cancelled";
    EntrataApplicationStatus["Expired"] = "expired";
    EntrataApplicationStatus["ConditionallyApproved"] = "conditionally-approved";
    EntrataApplicationStatus["Denied"] = "denied";
})(EntrataApplicationStatus || (exports.EntrataApplicationStatus = EntrataApplicationStatus = {}));
var EntrataApplicationStage;
(function (EntrataApplicationStage) {
    EntrataApplicationStage["Application"] = "application";
    EntrataApplicationStage["Lease"] = "lease";
})(EntrataApplicationStage || (exports.EntrataApplicationStage = EntrataApplicationStage = {}));
var UploadedFileType;
(function (UploadedFileType) {
    UploadedFileType["Unknown"] = "unknown";
    UploadedFileType["PersonalId"] = "personal-id";
    UploadedFileType["ProofOfIncome"] = "proof-of-income";
    UploadedFileType["ProofOfAsset"] = "proof-of-asset";
    UploadedFileType["ProofOfIdentification"] = "proof-of-identification";
})(UploadedFileType || (exports.UploadedFileType = UploadedFileType = {}));
var UploadedFileVendor;
(function (UploadedFileVendor) {
    UploadedFileVendor["Unknown"] = "unknown";
    UploadedFileVendor["AmazonS3"] = "amazon_s3";
})(UploadedFileVendor || (exports.UploadedFileVendor = UploadedFileVendor = {}));
var AddOnType;
(function (AddOnType) {
    AddOnType[AddOnType["Assignable"] = 1] = "Assignable";
    AddOnType[AddOnType["Rentable"] = 2] = "Rentable";
    AddOnType[AddOnType["Service"] = 3] = "Service";
    AddOnType[AddOnType["Special"] = 4] = "Special";
})(AddOnType || (exports.AddOnType = AddOnType = {}));
var FloorPlanType;
(function (FloorPlanType) {
    FloorPlanType["Studio"] = "studio";
    FloorPlanType["OneBedroom"] = "one-bed-room";
    FloorPlanType["TwoBedrooms"] = "two-bed-rooms";
    FloorPlanType["ThreeBedrooms"] = "three-bed-rooms";
})(FloorPlanType || (exports.FloorPlanType = FloorPlanType = {}));
var IdentificationApplicant;
(function (IdentificationApplicant) {
    IdentificationApplicant["Person"] = "person";
    IdentificationApplicant["Entity"] = "entity";
})(IdentificationApplicant || (exports.IdentificationApplicant = IdentificationApplicant = {}));
var IDTypes;
(function (IDTypes) {
    IDTypes["SocialSecurityNumber"] = "Social Security Number";
    IDTypes["DriversLicense"] = "Drivers License";
    // Backend wants 'Goverment Issued Picture Id' not 'Government Issued Picture Id'
    IDTypes["GovernmentIssuedPhotoId"] = "Goverment Issued Picture ID";
    IDTypes["MilitaryId"] = "Military Id";
    IDTypes["Passport"] = "Passport Number";
    IDTypes["StudentIdentificationNumber"] = "Student Identification Number";
    IDTypes["Visa"] = "VISA";
})(IDTypes || (exports.IDTypes = IDTypes = {}));
var ScreeningConditionType;
(function (ScreeningConditionType) {
    ScreeningConditionType[ScreeningConditionType["Unknown"] = 0] = "Unknown";
    ScreeningConditionType[ScreeningConditionType["VerifyIdentity"] = 1] = "VerifyIdentity";
    ScreeningConditionType[ScreeningConditionType["VerifyIncome"] = 2] = "VerifyIncome";
    ScreeningConditionType[ScreeningConditionType["IncreaseSecurityDeposit"] = 3] = "IncreaseSecurityDeposit";
    ScreeningConditionType[ScreeningConditionType["IncreaseRent"] = 4] = "IncreaseRent";
    ScreeningConditionType[ScreeningConditionType["AddGuarantor"] = 5] = "AddGuarantor";
    ScreeningConditionType[ScreeningConditionType["CustomScreeningCondition"] = 9] = "CustomScreeningCondition";
    ScreeningConditionType[ScreeningConditionType["OtherCharges"] = 10] = "OtherCharges";
    ScreeningConditionType[ScreeningConditionType["ReplaceSecurityDeposit"] = 11] = "ReplaceSecurityDeposit";
    ScreeningConditionType[ScreeningConditionType["UpfrontRent"] = 14] = "UpfrontRent";
    ScreeningConditionType[ScreeningConditionType["DepositAlternative"] = 15] = "DepositAlternative";
    ScreeningConditionType[ScreeningConditionType["DepositAlternativeIncreaseCoverage"] = 16] = "DepositAlternativeIncreaseCoverage";
})(ScreeningConditionType || (exports.ScreeningConditionType = ScreeningConditionType = {}));
var FinancialScreeningConditionType;
(function (FinancialScreeningConditionType) {
    FinancialScreeningConditionType[FinancialScreeningConditionType["VerifyIncome"] = 2] = "VerifyIncome";
    FinancialScreeningConditionType[FinancialScreeningConditionType["IncreaseSecurityDeposit"] = 3] = "IncreaseSecurityDeposit";
    FinancialScreeningConditionType[FinancialScreeningConditionType["IncreaseRent"] = 4] = "IncreaseRent";
    FinancialScreeningConditionType[FinancialScreeningConditionType["OtherCharges"] = 10] = "OtherCharges";
    FinancialScreeningConditionType[FinancialScreeningConditionType["ReplaceSecurityDeposit"] = 11] = "ReplaceSecurityDeposit";
    FinancialScreeningConditionType[FinancialScreeningConditionType["UpfrontRent"] = 14] = "UpfrontRent";
    FinancialScreeningConditionType[FinancialScreeningConditionType["DepositAlternative"] = 15] = "DepositAlternative";
    FinancialScreeningConditionType[FinancialScreeningConditionType["DepositAlternativeIncreaseCoverage"] = 16] = "DepositAlternativeIncreaseCoverage";
})(FinancialScreeningConditionType || (exports.FinancialScreeningConditionType = FinancialScreeningConditionType = {}));
var ScreeningConditionActionTakenType;
(function (ScreeningConditionActionTakenType) {
    ScreeningConditionActionTakenType["Satisfied"] = "satisfied";
    ScreeningConditionActionTakenType["Declined"] = "declined";
})(ScreeningConditionActionTakenType || (exports.ScreeningConditionActionTakenType = ScreeningConditionActionTakenType = {}));
var ChargeCodeType;
(function (ChargeCodeType) {
    ChargeCodeType["Deposit"] = "deposit";
    ChargeCodeType["Rent"] = "rent";
    ChargeCodeType["ConvenienceFee"] = "convenience-fee";
    ChargeCodeType["Miscellaneous"] = "miscellaneous";
})(ChargeCodeType || (exports.ChargeCodeType = ChargeCodeType = {}));
var LeaseChargeType;
(function (LeaseChargeType) {
    // These values are defined in Entrata Core
    //
    // One time charges
    LeaseChargeType[LeaseChargeType["ApplicationCompleted"] = 102] = "ApplicationCompleted";
    LeaseChargeType[LeaseChargeType["OneTime"] = 201] = "OneTime";
    LeaseChargeType[LeaseChargeType["MoveIn"] = 202] = "MoveIn";
    LeaseChargeType[LeaseChargeType["LastMonthOfOccupancy"] = 204] = "LastMonthOfOccupancy";
    LeaseChargeType[LeaseChargeType["Notice"] = 205] = "Notice";
    LeaseChargeType[LeaseChargeType["MoveOut"] = 206] = "MoveOut";
    LeaseChargeType[LeaseChargeType["AnniversaryOfMoveIn"] = 207] = "AnniversaryOfMoveIn";
    LeaseChargeType[LeaseChargeType["EndOfLeaseTerm"] = 208] = "EndOfLeaseTerm";
    LeaseChargeType[LeaseChargeType["EndOfCalendarYear"] = 209] = "EndOfCalendarYear";
    LeaseChargeType[LeaseChargeType["FinalStatement"] = 210] = "FinalStatement";
    LeaseChargeType[LeaseChargeType["RenewalLeaseApproval"] = 211] = "RenewalLeaseApproval";
    LeaseChargeType[LeaseChargeType["RenewalStart"] = 212] = "RenewalStart";
    LeaseChargeType[LeaseChargeType["RenewalOfferAccepted"] = 213] = "RenewalOfferAccepted";
    LeaseChargeType[LeaseChargeType["FirstFullMonth"] = 214] = "FirstFullMonth";
    // Recurring charges
    LeaseChargeType[LeaseChargeType["Hourly"] = 301] = "Hourly";
    LeaseChargeType[LeaseChargeType["Daily"] = 302] = "Daily";
    LeaseChargeType[LeaseChargeType["Weekly"] = 303] = "Weekly";
    LeaseChargeType[LeaseChargeType["EveryTwoWeeks"] = 304] = "EveryTwoWeeks";
    LeaseChargeType[LeaseChargeType["TwicePerMonth"] = 305] = "TwicePerMonth";
    LeaseChargeType[LeaseChargeType["SpecificLeaseMonths"] = 306] = "SpecificLeaseMonths";
    LeaseChargeType[LeaseChargeType["Monthly"] = 307] = "Monthly";
    LeaseChargeType[LeaseChargeType["Quarterly"] = 308] = "Quarterly";
    LeaseChargeType[LeaseChargeType["TwicePerYear"] = 309] = "TwicePerYear";
    LeaseChargeType[LeaseChargeType["Yearly"] = 310] = "Yearly";
    LeaseChargeType[LeaseChargeType["PercentOfSales"] = 311] = "PercentOfSales";
    LeaseChargeType[LeaseChargeType["Nightly"] = 312] = "Nightly";
})(LeaseChargeType || (exports.LeaseChargeType = LeaseChargeType = {}));
function LeaseChargeTypeIsRecurring(type) {
    return [
        LeaseChargeType.Hourly,
        LeaseChargeType.Daily,
        LeaseChargeType.Weekly,
        LeaseChargeType.EveryTwoWeeks,
        LeaseChargeType.TwicePerMonth,
        LeaseChargeType.SpecificLeaseMonths,
        LeaseChargeType.Monthly,
        LeaseChargeType.Quarterly,
        LeaseChargeType.TwicePerYear,
        LeaseChargeType.Yearly,
        LeaseChargeType.PercentOfSales,
        LeaseChargeType.Nightly,
    ].includes(type);
}
function LeaseChargeTypeToDisplayText(type) {
    switch (type) {
        case LeaseChargeType.Hourly:
            return 'Hourly';
        case LeaseChargeType.Daily:
            return 'Daily';
        case LeaseChargeType.Weekly:
            return 'Weekly';
        case LeaseChargeType.EveryTwoWeeks:
            return 'Every Two Weeks';
        case LeaseChargeType.TwicePerMonth:
            return 'Twice Per Month';
        case LeaseChargeType.SpecificLeaseMonths:
            return 'Specific Lease Months';
        case LeaseChargeType.Monthly:
            return 'Monthly';
        case LeaseChargeType.Quarterly:
            return 'Quarterly';
        case LeaseChargeType.TwicePerYear:
            return 'Twice Per Year';
        case LeaseChargeType.Yearly:
            return 'Yearly';
        case LeaseChargeType.PercentOfSales:
            return 'Percent Of Sales';
        case LeaseChargeType.Nightly:
            return 'Nightly';
        default:
            return '';
    }
}
var DocumentSigningProcedure;
(function (DocumentSigningProcedure) {
    DocumentSigningProcedure["SinglePage"] = "one_page";
    DocumentSigningProcedure["MultiplePage"] = "multiple_page";
})(DocumentSigningProcedure || (exports.DocumentSigningProcedure = DocumentSigningProcedure = {}));
var FeatureFlag;
(function (FeatureFlag) {
    FeatureFlag["CoreConfigApiVersion"] = "core-config-api-version";
    FeatureFlag["DocxApiVersion"] = "docx-api-version";
    FeatureFlag["LeasingJourneyApiVersion"] = "leasing-journey-api-version";
    FeatureFlag["LeasingJourneyApiEnabled"] = "leasing-journey-api-enabled";
    FeatureFlag["ResidentPortalApiVersion"] = "resident-portal-api-version";
    FeatureFlag["ResidentPortalApiEnabled"] = "resident-portal-api-enabled";
    FeatureFlag["BillingApiVersion"] = "billing-api-version";
    FeatureFlag["ThemeApiVersion"] = "theme-api-version";
    FeatureFlag["ResidentVerifyApiVersion"] = "resident-verify-api-version";
    FeatureFlag["RateManagementApiVersion"] = "rate-management-api-version";
})(FeatureFlag || (exports.FeatureFlag = FeatureFlag = {}));
var ReleaseTrack;
(function (ReleaseTrack) {
    ReleaseTrack["Rapid"] = "Rapid";
    ReleaseTrack["Standard"] = "Standard";
})(ReleaseTrack || (exports.ReleaseTrack = ReleaseTrack = {}));
var ScreeningConditionOperandType;
(function (ScreeningConditionOperandType) {
    ScreeningConditionOperandType[ScreeningConditionOperandType["All"] = 0] = "All";
    ScreeningConditionOperandType[ScreeningConditionOperandType["And"] = 1] = "And";
    ScreeningConditionOperandType[ScreeningConditionOperandType["Or"] = 2] = "Or";
})(ScreeningConditionOperandType || (exports.ScreeningConditionOperandType = ScreeningConditionOperandType = {}));
var ScreeningConditionSubsetType;
(function (ScreeningConditionSubsetType) {
    ScreeningConditionSubsetType[ScreeningConditionSubsetType["One"] = 1] = "One";
    ScreeningConditionSubsetType[ScreeningConditionSubsetType["Two"] = 2] = "Two";
})(ScreeningConditionSubsetType || (exports.ScreeningConditionSubsetType = ScreeningConditionSubsetType = {}));
var ApplicantContext;
(function (ApplicantContext) {
    ApplicantContext["FormViewer"] = "form-viewer";
    ApplicantContext["LeadProfile"] = "lead-profile";
    ApplicantContext["ScreeningConditions"] = "screening-conditions";
})(ApplicantContext || (exports.ApplicantContext = ApplicantContext = {}));
var PropertySettingType;
(function (PropertySettingType) {
    PropertySettingType["ChargePetNonRecurringOnceForMultiplePets"] = "CHARGE_PET_NON_RECURRING_ONCE_FOR_MULTIPLE_PETS";
    PropertySettingType["HidePaymentForAppApprovedAndLeaseApproved"] = "HIDE_PAYMENT_BUTTON_FOR_APPLICATION_APPROVED_AND_LEASE_APPROVED";
    PropertySettingType["HistoricalApplicationDisplayDays"] = "HISTORICAL_APPLICATION_DISPLAY_DAYS";
    PropertySettingType["LockDownApplicationDisplayDays"] = "LOCK_DOWN_APPLICATION_AT";
    PropertySettingType["ShowCoApplicantInviteOnApplication"] = "SHOW_CO_APPLICANT_INVITE_ON_APPLICATION";
})(PropertySettingType || (exports.PropertySettingType = PropertySettingType = {}));
var StaticPageId;
(function (StaticPageId) {
    StaticPageId["Quotes"] = "8c66f564-171f-4bcf-811d-400861bf4533";
})(StaticPageId || (exports.StaticPageId = StaticPageId = {}));
var DisplayAction;
(function (DisplayAction) {
    DisplayAction["Show"] = "show";
    DisplayAction["Hide"] = "hide";
})(DisplayAction || (exports.DisplayAction = DisplayAction = {}));
var ValueOperator;
(function (ValueOperator) {
    ValueOperator["Equals"] = "eq";
    ValueOperator["GreaterThan"] = "gt";
    ValueOperator["LessThan"] = "lt";
    ValueOperator["Contains"] = "contains";
    ValueOperator["Includes"] = "includes";
    ValueOperator["NotEqual"] = "nql";
})(ValueOperator || (exports.ValueOperator = ValueOperator = {}));
var DisplayLogicConditionalOperator;
(function (DisplayLogicConditionalOperator) {
    DisplayLogicConditionalOperator["And"] = "and";
    DisplayLogicConditionalOperator["Or"] = "or";
})(DisplayLogicConditionalOperator || (exports.DisplayLogicConditionalOperator = DisplayLogicConditionalOperator = {}));
var ApplicationType;
(function (ApplicationType) {
    ApplicationType["Application"] = "application";
    ApplicationType["Router"] = "router";
})(ApplicationType || (exports.ApplicationType = ApplicationType = {}));
var RouterTargetType;
(function (RouterTargetType) {
    RouterTargetType["Application"] = "application";
    RouterTargetType["Field"] = "field";
})(RouterTargetType || (exports.RouterTargetType = RouterTargetType = {}));
