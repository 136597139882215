"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Field = void 0;
var Field = /** @class */ (function () {
    function Field() {
        this.sequence = 0;
        this.label = '';
        this.type = '';
        this.required = false;
    }
    return Field;
}());
exports.Field = Field;
