"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GUARANTOR_FIELDS = void 0;
var entities_1 = require("@common/entities");
exports.GUARANTOR_FIELDS = [
    {
        label: 'Relationship to Applicant',
        sequence: 1,
        name: 'relationship_to_applicant',
        required: true,
        enabled: true,
        options: [],
        type: entities_1.FieldType.Dropdown,
        customType: entities_1.FieldType.Dropdown,
        isAddress: false,
        nonRemovable: false,
    },
    {
        label: 'First Name',
        sequence: 2,
        name: 'guarantor_first_name',
        required: true,
        enabled: true,
        type: entities_1.FieldType.Text,
        customType: entities_1.FieldType.Text,
        nonRemovable: false,
        isAddress: false,
    },
    {
        label: 'Middle Name',
        sequence: 3,
        name: 'guarantor_middle_name',
        required: false,
        enabled: true,
        type: entities_1.FieldType.Text,
        customType: entities_1.FieldType.Text,
        nonRemovable: false,
        isAddress: false,
    },
    {
        label: 'Last Name',
        sequence: 4,
        name: 'guarantor_last_name',
        required: true,
        enabled: true,
        type: entities_1.FieldType.Text,
        customType: entities_1.FieldType.Text,
        nonRemovable: false,
        isAddress: false,
    },
    {
        label: 'Phone Number',
        sequence: 5,
        name: 'guarantor_phone_number',
        required: true,
        enabled: true,
        type: entities_1.FieldType.PhoneNumber,
        customType: entities_1.FieldType.PhoneNumber,
        nonRemovable: false,
        isAddress: false,
    },
    {
        label: 'Date of Birth',
        sequence: 6,
        name: 'guarantor_date_of_birth',
        required: true,
        enabled: true,
        type: entities_1.FieldType.Date,
        customType: entities_1.FieldType.Date,
        nonRemovable: false,
        isAddress: false,
    },
    {
        label: 'Address Line 1',
        sequence: 7,
        name: 'guarantor_address_line_1',
        required: false,
        enabled: false,
        type: entities_1.FieldType.Text,
        customType: entities_1.FieldType.Text,
        isAddress: true,
    },
    {
        label: 'Address Line 2',
        sequence: 8,
        name: 'guarantor_address_line_2',
        required: false,
        enabled: false,
        type: entities_1.FieldType.Text,
        customType: entities_1.FieldType.Text,
        isAddress: true,
    },
    {
        label: 'City',
        sequence: 9,
        name: 'guarantor_address_city',
        required: false,
        enabled: false,
        type: entities_1.FieldType.Text,
        customType: entities_1.FieldType.Text,
        isAddress: true,
    },
    {
        label: 'Country',
        sequence: 10,
        name: 'guarantor_address_country',
        required: false,
        enabled: false,
        type: entities_1.FieldType.CountryDropdown,
        customType: entities_1.FieldType.CountryDropdown,
        isAddress: true,
        options: [],
    },
    {
        label: 'State',
        sequence: 11,
        name: 'guarantor_address_state',
        required: false,
        enabled: false,
        type: entities_1.FieldType.StateDropdown,
        customType: entities_1.FieldType.StateDropdown,
        isAddress: true,
        options: [],
    },
    {
        label: 'Zip code',
        sequence: 12,
        name: 'guarantor_address_zip_code',
        required: false,
        enabled: false,
        type: entities_1.FieldType.ZipCode,
        isAddress: true,
        customType: entities_1.FieldType.ZipCode,
    },
    {
        label: 'Personal Reference #1',
        sequence: 13,
        name: 'personal_reference_1',
        required: false,
        enabled: false,
        type: entities_1.FieldType.Text,
        isAddress: false,
        customType: entities_1.FieldType.Text,
    },
    {
        label: 'Personal Reference #2',
        sequence: 14,
        name: 'personal_reference_2',
        required: false,
        enabled: false,
        type: entities_1.FieldType.Text,
        isAddress: false,
        customType: entities_1.FieldType.Text,
    },
];
