"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBlockFieldName = getBlockFieldName;
var enums_1 = require("./enums");
var INCOME_SUB_TYPE_NAMES = new Map([
    [enums_1.IncomeTypes.CurrentEmployer, 'current_employer'],
    [enums_1.IncomeTypes.PreviousEmployer, 'previous_employer'],
    [enums_1.IncomeTypes.SelfEmployer, 'self_employer'],
    [enums_1.IncomeTypes.OtherIncome, 'other_income'],
]);
var ASSET_SUB_TYPE_NAMES = new Map([
    [enums_1.AssetTypes.CheckingAccount, 'checking-account'],
    [enums_1.AssetTypes.SavingsAccount, 'savings-account'],
    [enums_1.AssetTypes.CertificateOfDeposit, 'certificate-of-deposit'],
    [enums_1.AssetTypes.RetirementAccount, 'retirement-account'],
    [enums_1.AssetTypes.StocksOrBonds, 'stocks-or-bonds'],
]);
function getBlockFieldName(blockType, fieldName, blockSubType) {
    var _a, _b;
    var blockTypeName = blockType.toLowerCase();
    switch (blockType) {
        case enums_1.BlockType.Income:
            return "".concat(blockTypeName, "-").concat((_a = INCOME_SUB_TYPE_NAMES.get(blockSubType)) !== null && _a !== void 0 ? _a : blockSubType, "-").concat(fieldName);
        case enums_1.BlockType.Asset:
            return "".concat(blockTypeName, "-").concat((_b = ASSET_SUB_TYPE_NAMES.get(blockSubType)) !== null && _b !== void 0 ? _b : blockSubType, "-").concat(fieldName);
        default:
            return "".concat(blockTypeName, "-").concat(fieldName);
    }
}
