"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddOnOption = exports.AddonRate = exports.PropertyAddOn = void 0;
var enums_1 = require("./enums");
var PropertyAddOn = /** @class */ (function () {
    function PropertyAddOn() {
        this.name = '';
        this.description = '';
        this.type = enums_1.AddOnType.Assignable;
        this.groupId = '';
        this.categoryId = '';
        this.media = null;
        this.monthlyRate = 0;
        this.addOnOptions = [];
    }
    return PropertyAddOn;
}());
exports.PropertyAddOn = PropertyAddOn;
var AddonRate = /** @class */ (function () {
    function AddonRate() {
        this.charge = 0;
        this.frequency = '';
        this.type = '';
    }
    return AddonRate;
}());
exports.AddonRate = AddonRate;
var AddOnOption = /** @class */ (function () {
    function AddOnOption() {
        this.id = '';
        this.groupId = '';
        this.name = '';
        this.description = '';
        this.rates = [];
        this.unitSpaceId = null;
    }
    return AddOnOption;
}());
exports.AddOnOption = AddOnOption;
