"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RETIREMENT_ACCOUNT_FIELDS = void 0;
var entities_1 = require("@common/entities");
var ASSET_FIELDS_1 = require("./ASSET_FIELDS");
exports.RETIREMENT_ACCOUNT_FIELDS = {
    name: 'retirement-account',
    label: 'Retirement Account',
    customType: entities_1.AssetTypes.RetirementAccount,
    elementType: entities_1.SectionElementType.Block,
    documentUpload: {
        maxFilesAllowed: '2',
        verifyDocumentUpload: true,
    },
    fields: (0, ASSET_FIELDS_1.getAssetFields)('retirement_accounts'),
};
