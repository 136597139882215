"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STUDENT_FIELDS = void 0;
var entities_1 = require("@common/entities");
var constants_1 = require("../../constants");
exports.STUDENT_FIELDS = [
    {
        label: 'Are you a student at an institute of higher learning?',
        type: entities_1.FieldType.YesNo,
        customType: entities_1.FieldType.YesNo,
        sequence: 1,
        name: 'is_student_higher_learning',
        required: false,
        enabled: true,
        options: constants_1.YES_NO_OPTIONS,
    },
    {
        label: 'Student Status',
        type: entities_1.FieldType.Radio,
        customType: entities_1.FieldType.Radio,
        sequence: 2,
        name: 'higher_learning_status',
        required: false,
        enabled: true,
        options: constants_1.HIGHER_LEARNING_STATUS_OPTIONS,
    },
];
