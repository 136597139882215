import { createSelector } from '@reduxjs/toolkit';

import { RootState } from './store';

const selectCurrentTabIndex = (state: RootState) =>
  state.navigation.currentTabIndex;
const selectActiveStepByTab = (state: RootState) =>
  state.navigation.activeStepByTab;
const selectStepStatusByTab = (state: RootState) => state.navigation.stepStatus;
const selectLeadProfileApplicant = (state: RootState) =>
  state.leadProfile.applicants;

const selectBrandingMedia = (state: RootState) => state.branding?.media ?? {};
const selectResponse = (state: RootState) => state.response;
const selectApplication = (state: RootState) => state.application;

export const selectActiveStep = createSelector(
  [selectActiveStepByTab, selectCurrentTabIndex],
  (activeStepByTab, currentTabIndex) => activeStepByTab[currentTabIndex] || 0,
);

export const selectCurrentTabStepStatus = createSelector(
  [selectStepStatusByTab, selectCurrentTabIndex],
  (stepStatus, currentTabIndex) => stepStatus[currentTabIndex] || {},
);

export const selectCurrentLeadProfileApplicant = createSelector(
  [selectLeadProfileApplicant, selectCurrentTabIndex],
  (applicants, currentTabIndex) => applicants[currentTabIndex],
);

export const brandingMediaSelector = createSelector(
  [selectBrandingMedia],
  (media) => media,
);

export const applicationBrandingResponseSelector = createSelector(
  [selectApplication, selectBrandingMedia, selectResponse],
  (application, branding, response) => ({
    ...application,
    ...response,
    media: branding?.media ?? {},
  }),
);
