"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Page = void 0;
var Page = /** @class */ (function () {
    function Page() {
        this.sequence = 0;
        this.name = '';
        this.description = '';
        this.type = '';
        this.sections = undefined;
    }
    return Page;
}());
exports.Page = Page;
