import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, GridItem, Heading, Image, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import { RootState } from 'state/store';

import { NoFloorPlanImage } from 'assets/images';
import type { FloorPlan, LeaseTerm, Unit } from 'types/floorplan-page-types';

export function SelectedUnitDetails() {
  const { floorPlan, unit, leaseTerm, moveInDate } = useSelector(
    (state: RootState) => state.unitSelection,
  );

  const { media, id: floorPlanId } = floorPlan as FloorPlan;
  const date = new Date(moveInDate as any);

  const moveInDateString = `${format(
    new Date(date.getFullYear(), date.getMonth(), date.getDate()),
    'MMM dd',
  )},  ${date.getFullYear()}`;

  const { number, id: unitId } = unit as Unit;
  const { amount, termMonth, id: leaseId } = leaseTerm as LeaseTerm;

  return (
    <Grid
      templateColumns="repeat(3, 1fr)"
      gap={2}
      p={2}
      mt={3}
      border="1px solid"
      borderColor="silver.highlight"
      borderRadius="16px"
      boxShadow="0px 1px 3px rgba(0, 0, 0, 0.15)"
    >
      <GridItem
        colSpan={1}
        key={`unit-image-${floorPlanId}`}
        position="relative"
      >
        <Image
          src={media[0]?.fullsizeUri || NoFloorPlanImage}
          alt={media[0]?.caption ?? 'floor-plan'}
          width="100%"
          height="100%"
          objectFit="contain"
          borderLeftRadius="12px"
          borderRightRadius="4px"
        />
      </GridItem>
      <GridItem colSpan={2} pl={1}>
        <Heading as="h5" size="sm" my={1} key={`unit-heading-${unitId}`}>
          {number}
        </Heading>
        <Text fontSize="xs" as="em">
          Move-In: {moveInDateString}
        </Text>
        <Text fontSize="xs" mt={1} key={`lease-info-${leaseId}`}>
          {termMonth} Month Lease - Lease {Number(amount ?? 0).toLocaleString()}
          /mo
        </Text>
      </GridItem>
    </Grid>
  );
}
