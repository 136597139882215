"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Form = void 0;
var Form = /** @class */ (function () {
    function Form() {
        this.name = '';
        this.description = '';
        this.type = '';
        this.clientId = null;
    }
    return Form;
}());
exports.Form = Form;
