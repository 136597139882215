import React from 'react';
import { useSelector } from 'react-redux';
import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { RootState } from 'state/store';

import { LogoutIcon } from 'components/custom-icons';
import { Loader } from 'components/Loader';
import FooterLinks from 'components/navigation/leasing-journey/common/FooterLinks';
import ShoppingCart from 'components/shopping-cart/CartSection';
import { useLogout } from 'hooks/auth';

export default function MobileMenuDrawer({ isOpen, onClose }: any) {
  const { value: responses } = useSelector(
    (state: RootState) => state.response,
  );
  const { isPreviewMode } = useSelector((state: RootState) => state.auth);

  const handleBackClick = () => {
    onClose();
  };

  const { handleLogout, isLoading } = useLogout();

  const drawerSize = useBreakpointValue({ base: 'xs', md: 'sm' });
  const drawerMaxWidth = useBreakpointValue({
    base: 'calc(100% - 40px)',
    md: '400px',
  });

  if (isLoading) return <Loader label="Logging out..." />;

  return (
    <Drawer
      onClose={onClose}
      placement="right"
      isOpen={isOpen}
      size={drawerSize}
    >
      <DrawerOverlay />
      <DrawerContent
        bg="white.base"
        borderTopLeftRadius="2xl"
        borderBottomLeftRadius="2xl"
        maxWidth={drawerMaxWidth}
        overflow="hidden"
      >
        <DrawerHeader bg="rgb(255 255 255 / 10%)" fontSize={18}>
          <Flex justifyContent="space-between">
            <IconButton
              aria-label="Menu"
              bg="none"
              icon={<CloseIcon />}
              onClick={handleBackClick}
            />
            {!isPreviewMode && (
              <Box textAlign="right">
                <Button
                  size="md"
                  leftIcon={<LogoutIcon />}
                  variant="link"
                  onClick={handleLogout}
                >
                  Logout
                </Button>
                <Text
                  fontSize="xs"
                  fontStyle="italic"
                  mb={1}
                  color="blackAlpha.700"
                >
                  {responses?.applicant_email_address}
                </Text>
              </Box>
            )}
          </Flex>
        </DrawerHeader>
        <DrawerBody p="0">
          <ShoppingCart
            chargesType={null}
            loadFromBillingApi
            handleDrawerClose={onClose}
          />
        </DrawerBody>
        <DrawerFooter justifyContent="center">
          <FooterLinks />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
