"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VISA = exports.STUDENT_IDENTIFICATION_NUMBER = exports.SOCIAL_SECURITY_NUMBER = exports.PASSPORT = exports.MILITARY_ID = exports.GOVERNMENT_ISSUED_PICTURE_ID = exports.ENTITY = exports.DRIVERS_LICENSE = void 0;
var DRIVERS_LICENSE_1 = require("./DRIVERS_LICENSE");
Object.defineProperty(exports, "DRIVERS_LICENSE", { enumerable: true, get: function () { return DRIVERS_LICENSE_1.DRIVERS_LICENSE; } });
var ENTITY_1 = require("./ENTITY");
Object.defineProperty(exports, "ENTITY", { enumerable: true, get: function () { return ENTITY_1.ENTITY; } });
var GOVERNMENT_ISSUED_PICTURE_ID_1 = require("./GOVERNMENT_ISSUED_PICTURE_ID");
Object.defineProperty(exports, "GOVERNMENT_ISSUED_PICTURE_ID", { enumerable: true, get: function () { return GOVERNMENT_ISSUED_PICTURE_ID_1.GOVERNMENT_ISSUED_PICTURE_ID; } });
__exportStar(require("./ID_TYPES"), exports);
var MILITARY_ID_1 = require("./MILITARY_ID");
Object.defineProperty(exports, "MILITARY_ID", { enumerable: true, get: function () { return MILITARY_ID_1.MILITARY_ID; } });
var PASSPORT_1 = require("./PASSPORT");
Object.defineProperty(exports, "PASSPORT", { enumerable: true, get: function () { return PASSPORT_1.PASSPORT; } });
var SOCIAL_SECURITY_NUMBER_1 = require("./SOCIAL_SECURITY_NUMBER");
Object.defineProperty(exports, "SOCIAL_SECURITY_NUMBER", { enumerable: true, get: function () { return SOCIAL_SECURITY_NUMBER_1.SOCIAL_SECURITY_NUMBER; } });
var STUDENT_IDENTIFICATION_NUMBER_1 = require("./STUDENT_IDENTIFICATION_NUMBER");
Object.defineProperty(exports, "STUDENT_IDENTIFICATION_NUMBER", { enumerable: true, get: function () { return STUDENT_IDENTIFICATION_NUMBER_1.STUDENT_IDENTIFICATION_NUMBER; } });
var VISA_1 = require("./VISA");
Object.defineProperty(exports, "VISA", { enumerable: true, get: function () { return VISA_1.VISA; } });
__exportStar(require("./PERSON"), exports);
