"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.OWNERSHIP_STATUS = exports.DISABILITY_TYPE_OPTIONS = exports.HIGHER_LEARNING_STATUS_OPTIONS = exports.YES_NO_OPTIONS = exports.PET_GENDER = exports.PAYMENT_INTERVAL = void 0;
var entities_1 = require("@common/entities");
exports.PAYMENT_INTERVAL = (_a = {},
    _a[entities_1.Interval.Monthly] = 'Monthly',
    _a[entities_1.Interval.Weekly] = 'Weekly',
    _a[entities_1.Interval.Yearly] = 'Yearly',
    _a);
exports.PET_GENDER = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
];
exports.YES_NO_OPTIONS = [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
];
exports.HIGHER_LEARNING_STATUS_OPTIONS = [
    {
        label: 'Full Time',
        value: 'full_time',
    },
    {
        label: 'Part Time',
        value: 'part_time',
    },
];
exports.DISABILITY_TYPE_OPTIONS = [
    {
        value: 'disability_mobility',
        label: 'Mobility',
    },
    {
        value: 'disability_hearing',
        label: 'Hearing',
    },
    {
        value: 'disability_visual',
        label: 'Visual',
    },
    {
        value: 'disability_mental',
        label: 'Mental',
    },
];
exports.OWNERSHIP_STATUS = [
    { label: 'Owner', value: 'owner' },
    {
        label: 'Renter',
        value: 'renter',
    },
];
