"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Amenity = void 0;
var Amenity = /** @class */ (function () {
    function Amenity() {
        this.name = '';
        this.description = '';
        this.typeId = 0;
        this.cascadeId = 0;
        this.media = [];
        this.rates = [];
    }
    return Amenity;
}());
exports.Amenity = Amenity;
