"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApplicationRouter = void 0;
var ApplicationRouter = /** @class */ (function () {
    function ApplicationRouter() {
        this.name = '';
        this.description = '';
        this.clientId = 0;
    }
    return ApplicationRouter;
}());
exports.ApplicationRouter = ApplicationRouter;
