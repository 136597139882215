import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';
import { EntrataSession } from 'auth/EntrataSession';
import {
  NEW_RELIC_BROWSER_ACCOUNT_ID,
  NEW_RELIC_BROWSER_AGENT_ID_VIEWER,
  NEW_RELIC_BROWSER_APPLICATION_ID_VIEWER,
  NEW_RELIC_BROWSER_CROSS_ORIGIN_DOMAIN,
  NEW_RELIC_BROWSER_LICENSE_KEY,
  NEW_RELIC_BROWSER_TRUST_KEY,
} from 'config/api/NewRelic';
import { NotificationStatus } from 'enums/notification-status';

import notification from 'components/notification';

const handleError = (errorDetail: string, userErrorMessage?: string) => {
  if (
    NEW_RELIC_BROWSER_ACCOUNT_ID &&
    !NEW_RELIC_BROWSER_ACCOUNT_ID.startsWith('%%')
  ) {
    try {
      const options = {
        info: {
          beacon: 'bam.nr-data.net',
          errorBeacon: 'bam.nr-data.net',
          licenseKey: NEW_RELIC_BROWSER_LICENSE_KEY,
          applicationID: NEW_RELIC_BROWSER_APPLICATION_ID_VIEWER,
          sa: 1,
        },
        loader_config: {
          accountID: NEW_RELIC_BROWSER_ACCOUNT_ID,
          trustKey: NEW_RELIC_BROWSER_TRUST_KEY,
          agentID: NEW_RELIC_BROWSER_AGENT_ID_VIEWER,
          licenseKey: NEW_RELIC_BROWSER_LICENSE_KEY,
          applicationID: NEW_RELIC_BROWSER_APPLICATION_ID_VIEWER,
        },
        init: {
          distributed_tracing: {
            enabled: true,
            exclude_newrelic_header: true,
            cors_use_newrelic_header: false,
            cors_use_tracecontext_headers: true,
            allowed_origins: [NEW_RELIC_BROWSER_CROSS_ORIGIN_DOMAIN],
          },
          privacy: { cookies_enabled: true },
        },
      };
      const nrAgent = new BrowserAgent(options);

      const errorDetailOptions = {
        ...EntrataSession.applicant,
        rpApiAuthToken: '(hidden)',
        leasingJourneyApiAuthToken: '(hidden)',
        paymentsApiToken: '(hidden)',
      };
      nrAgent.noticeError(errorDetail, errorDetailOptions);
    } catch {
      // eslint-disable-next-line no-console
      console.log(
        `An error occurred logging this error to NewRelic: ${errorDetail}`,
      );
    }
  }

  if (userErrorMessage) {
    notification(userErrorMessage, NotificationStatus.ERROR);
  }
};

export default handleError;
