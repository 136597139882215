"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PREVIOUS_ADDRESS_FIELDS = void 0;
var entities_1 = require("@common/entities");
var constants_1 = require("../../constants");
exports.PREVIOUS_ADDRESS_FIELDS = [
    {
        type: entities_1.FieldType.Text,
        label: 'Street Line 1',
        sequence: 1,
        name: 'street_line1',
        customType: entities_1.FieldType.Text,
        enabled: true,
        required: true,
        entrataRequired: true,
    },
    {
        label: 'Street Line 2',
        type: entities_1.FieldType.Text,
        sequence: 2,
        name: 'street_line2',
        customType: entities_1.FieldType.Text,
        enabled: true,
        required: false,
    },
    {
        label: 'Street Line 3',
        type: entities_1.FieldType.Text,
        sequence: 3,
        name: 'street_line3',
        customType: null,
        enabled: false,
        required: false,
    },
    {
        label: 'City',
        type: entities_1.FieldType.Text,
        sequence: 4,
        name: 'city',
        customType: entities_1.FieldType.Text,
        enabled: true,
        required: true,
        entrataRequired: true,
    },
    {
        label: 'Country',
        type: entities_1.FieldType.CountryDropdown,
        options: [],
        sequence: 5,
        name: 'country_code',
        customType: entities_1.FieldType.CountryDropdown,
        enabled: true,
        required: true,
        entrataRequired: true,
    },
    {
        label: 'State',
        type: entities_1.FieldType.StateDropdown,
        options: [],
        sequence: 6,
        name: 'state_code',
        customType: entities_1.FieldType.StateDropdown,
        enabled: true,
        required: true,
        entrataRequired: true,
    },
    {
        label: 'Zip Code',
        type: entities_1.FieldType.ZipCode,
        sequence: 7,
        name: 'zip_code',
        customType: entities_1.FieldType.ZipCode,
        enabled: true,
        required: true,
        entrataRequired: true,
    },
    {
        label: 'Residence Type',
        type: entities_1.FieldType.Dropdown,
        sequence: 8,
        name: 'residence_type',
        customType: entities_1.FieldType.Dropdown,
        enabled: false,
        required: false,
        options: [],
    },
    {
        label: 'Reason for Leaving',
        type: entities_1.FieldType.Dropdown,
        customType: entities_1.FieldType.Dropdown,
        sequence: 9,
        name: 'leaving_reason',
        enabled: false,
        required: false,
        options: [],
    },
    {
        label: 'Own/Rented',
        type: entities_1.FieldType.Dropdown,
        customType: entities_1.FieldType.Dropdown,
        sequence: 10,
        name: 'ownership_status',
        enabled: false,
        required: false,
        options: constants_1.OWNERSHIP_STATUS,
    },
    {
        label: 'Community Name',
        type: entities_1.FieldType.Text,
        customType: entities_1.FieldType.Text,
        sequence: 11,
        name: 'community_name',
        enabled: false,
        required: false,
    },
    {
        label: 'Manager First Name',
        type: entities_1.FieldType.Text,
        sequence: 12,
        name: 'manager_first_name',
        customType: entities_1.FieldType.Text,
        enabled: false,
        required: false,
    },
    {
        label: 'Manager Phone Number',
        type: entities_1.FieldType.PhoneNumber,
        sequence: 13,
        name: 'manager_phone_number',
        customType: entities_1.FieldType.PhoneNumber,
        enabled: false,
        required: false,
    },
    {
        label: 'Manager Fax Number',
        type: entities_1.FieldType.Number,
        sequence: 14,
        name: 'manager_fax_number',
        customType: entities_1.FieldType.Number,
        enabled: false,
        required: false,
    },
    {
        label: 'Manager Email',
        type: entities_1.FieldType.Email,
        sequence: 15,
        name: 'manager_email',
        customType: entities_1.FieldType.Email,
        enabled: false,
        required: false,
    },
    {
        label: 'Move-in Date',
        sequence: 16,
        name: 'move_in_date',
        type: entities_1.FieldType.Date,
        customType: entities_1.FieldType.Date,
        enabled: true,
        required: false,
    },
    {
        label: 'Move-out Date',
        type: entities_1.FieldType.Date,
        sequence: 17,
        name: 'move_out_date',
        customType: entities_1.FieldType.Date,
        enabled: true,
        required: false,
    },
    {
        label: 'Monthly Payment Amount',
        type: entities_1.FieldType.Number,
        customType: entities_1.FieldType.Number,
        sequence: 18,
        name: 'monthly_payment_amount',
        enabled: false,
        required: false,
    },
    {
        label: 'Payment Recipient',
        type: entities_1.FieldType.Text,
        customType: entities_1.FieldType.Text,
        sequence: 19,
        name: 'payment_recipient',
        enabled: false,
        required: false,
    },
];
