import React, { useState } from 'react';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';
import { CloseIcon, createIcon } from '@chakra-ui/icons';
import {
  Alert,
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { NotificationStatus } from 'enums/notification-status';

import notification from 'components/notification';
import { calculateFileSize } from 'utils/calculateFileSize';

import { DocumentIcon, HelpIcon, StopSignIcon } from '../custom-icons';

const UploadFileIcon = createIcon({
  displayName: 'UploadFileIcon',
  viewBox: '0 0 80 80',
  path: (
    <>
      <path
        d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
        fill="#4296DA"
      />
      <rect
        x="30.5"
        y="30.5"
        width="29"
        height="29"
        rx="3.5"
        stroke="#FDFDFD"
        strokeLinejoin="round"
        strokeDasharray="2 2"
        fill="#4296DA"
      />
      <path
        d="M50 46.25C50 47.2446 49.6049 48.1984 48.9017 48.9017C48.1984 49.6049 47.2446 50 46.25 50H23.75C22.7554 50 21.8016 49.6049 21.0984 48.9017C20.3951 48.1984 20 47.2446 20 46.25V23.75C20 22.7554 20.3951 21.8016 21.0984 21.0984C21.8016 20.3951 22.7554 20 23.75 20H46.25C47.2446 20 48.1984 20.3951 48.9017 21.0984C49.6049 21.8016 50 22.7554 50 23.75V46.25Z"
        fill="#EBEBEB"
      />
      <path
        d="M46.25 20H23.75C22.7554 20 21.8016 20.3951 21.0984 21.0984C20.3951 21.8016 20 22.7554 20 23.75V46.25C20 46.8436 20.1412 47.4286 20.4119 47.9568C20.6827 48.4849 21.0753 48.9411 21.5572 49.2875L49.2875 21.5572C48.9411 21.0753 48.4849 20.6827 47.9568 20.4119C47.4286 20.1412 46.8436 20 46.25 20Z"
        fill="#FDFDFD"
      />
      <path
        d="M46.7714 39.3154C46.6431 39.2623 46.5019 39.2485 46.3658 39.2755C46.2296 39.3026 46.1045 39.3694 46.0062 39.4675L44.7225 40.7512L41.2476 37.2763C40.9735 37.0021 40.5291 37.0021 40.255 37.2763L37.2767 40.2545C37.0026 40.5287 37.0026 40.973 37.2767 41.2471L40.7517 44.7221L39.468 46.0058C39.3696 46.1039 39.3027 46.2291 39.2755 46.3653C39.2484 46.5016 39.2623 46.6428 39.3154 46.7712C39.3686 46.8995 39.4587 47.0092 39.5743 47.0863C39.6898 47.1634 39.8257 47.2045 39.9646 47.2044L46.2084 46.9291C46.3006 46.9291 46.3919 46.911 46.4771 46.8757C46.5622 46.8404 46.6396 46.7887 46.7048 46.7235C46.77 46.6584 46.8217 46.581 46.8569 46.4958C46.8922 46.4106 46.9104 46.3194 46.9104 46.2272L47.2052 39.9638C47.205 39.825 47.1637 39.6893 47.0865 39.5739C47.0093 39.4585 46.8997 39.3685 46.7714 39.3154Z"
        fill="#135791"
      />
    </>
  ),
});

interface FetchedFile {
  fileName: string;
  fileType: string;
  groupKey: string;
  id: string;
  responseId: string;
  userId: string;
}

interface Props {
  readonly header?: string;
  readonly rules: {
    readonly maxFiles?: number;
    readonly multiple?: boolean;
    readonly accept?: Accept;
    readonly maxSize?: number;
    readonly minSize?: number;
  };
  readonly acceptFileTypes: string;
  isOpen: boolean;
  onClose: () => void;
  saveFiles: (files: File[]) => void;
  uploadedFiles?: FetchedFile[];
  deleteUploadFile?: (id: string) => void;
}

export function FileUploadModal(props: Props) {
  const {
    header = 'Upload Document',
    acceptFileTypes = '',
    rules = {
      maxFiles: 1,
      multiple: false,
      accept: {},
      maxSize: 1000000,
      minSize: 0,
    },
    isOpen,
    onClose,
    saveFiles,
    uploadedFiles = [],
    deleteUploadFile,
  } = props;

  const [files, setFiles] = useState<File[]>([]);
  const [error, setError] = useState('');

  /**
   * Remove file which is already present in the state
   * @param file
   */
  function removeDuplicateFile(file: any) {
    if (files.length) {
      const { name, size, lastModified } = file;

      for (let i = 0; i < files.length; i += 1) {
        const {
          name: eName,
          size: eSize,
          lastModified: eLastModified,
        } = files[i];
        if (
          eName === name &&
          eSize === size &&
          eLastModified === lastModified
        ) {
          removeFile(file as never);
          break;
        }
      }
    }
  }

  /**
   * Validate uploaded files
   * @param acceptedFiles
   * @param rejectedFiles
   * @returns
   */
  function validate(acceptedFiles: any, rejectedFiles: FileRejection[]) {
    // If there is already an error
    if (rejectedFiles.length) {
      setError(rejectedFiles[0]?.errors[0]?.message);
      return false;
    }

    if (!acceptedFiles.length) return false;

    // Prevent from uploading more that maxFiles
    const { maxFiles = 1 } = rules;
    const totalFiles =
      acceptedFiles.length + files.length + uploadedFiles.length;
    if (totalFiles > maxFiles) {
      setError(`Upload max is ${maxFiles} documents`);
      return false;
    }

    // Prevent duplicate fields
    acceptedFiles.forEach((i: any) => removeDuplicateFile(i));

    return true;
  }

  /**
   * Validate files and update the files state
   * @param acceptedFiles
   * @param rejectedFiles
   * @returns
   */
  const onDrop = (acceptedFiles: any, rejectedFiles: FileRejection[]) => {
    const isValid = validate(acceptedFiles, rejectedFiles);
    if (!isValid) return;

    setError('');
    setFiles((prevState) => prevState.concat(acceptedFiles));
  };

  const { getRootProps, getInputProps } = useDropzone({
    ...rules,
    onDrop,
  });

  /**
   * Remove files
   * @param file
   */
  function removeFile(file: File) {
    const allFiles = [...files];
    allFiles.splice(allFiles.indexOf(file), 1);
    setFiles(allFiles);
  }

  function handleSave() {
    if (files.length === 0) {
      notification('Please select file', NotificationStatus.ERROR);
      return;
    }
    saveFiles(files);
  }

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        bg="white.base"
        border="1px"
        borderColor="gray.light"
        borderRadius={8}
      >
        <ModalHeader>
          <Flex alignItems="center">
            {header}
            <Spacer />
            <HelpIcon />
          </Flex>
        </ModalHeader>

        <ModalBody>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <Box>
              <Center>
                <Text fontSize="18px">
                  Drag and drop your files here
                  <Center>
                    <Text fontSize="xs" as="em">
                      (max. allowed is {rules?.maxFiles})
                    </Text>
                  </Center>
                </Text>
              </Center>
              <Center mt={5}>
                <UploadFileIcon boxSize="80px" />
              </Center>
              <Center mt={5}>
                <Text fontSize="18px" color="blue.bright">
                  browse files
                </Text>
              </Center>
            </Box>
            {error !== '' && (
              <Box px={5}>
                <Alert
                  status="error"
                  py={3}
                  px={4}
                  alignItems="center"
                  justifyContent="center"
                  wordBreak="break-all"
                >
                  <StopSignIcon boxSize="22px" />
                  <Text ml={2} fontSize="16px" color="red.darkest">
                    {error}
                  </Text>
                </Alert>
              </Box>
            )}

            <Grid templateColumns="repeat(2, 1fr)" gap={6} p={4}>
              {uploadedFiles?.map((file: any) => (
                <GridItem
                  key={`file-upload-${file.id}`}
                  bg="gray.highlighthalf"
                  py={2}
                  px={6}
                  borderRadius="md"
                >
                  <Flex alignItems="center">
                    <Flex alignItems="flex-start">
                      <DocumentIcon mt={2} />
                      <Box ml={3}>
                        <Text fontWeight={500} fontSize="15px">
                          {file?.fileName}
                        </Text>
                        <Text
                          fontWeight={500}
                          fontSize="10px"
                          color="gray.dark"
                        >
                          {calculateFileSize(file.fileSize)}
                        </Text>
                      </Box>
                    </Flex>
                    <Spacer />
                    <IconButton
                      colorScheme="teal"
                      aria-label="Close button"
                      size="xs"
                      bg="transparent"
                      _hover={{ background: 'transparent' }}
                      icon={<CloseIcon color="gray.dark" />}
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteUploadFile && deleteUploadFile(file.id);
                      }}
                    />
                  </Flex>
                </GridItem>
              ))}
              {files.map((file: any, index) => (
                <GridItem
                  key={`file-upload-${file?.lastModified}-${index}`}
                  bg="gray.highlighthalf"
                  py={2}
                  px={6}
                  borderRadius="md"
                >
                  <Flex alignItems="center">
                    <Flex alignItems="flex-start">
                      <DocumentIcon mt={2} />
                      <Box ml={3}>
                        <Text fontWeight={500} fontSize="15px">
                          {file?.name}
                        </Text>
                        <Text
                          fontWeight={500}
                          fontSize="10px"
                          color="gray.dark"
                        >
                          {calculateFileSize(file.size)}
                        </Text>
                      </Box>
                    </Flex>
                    <Spacer />
                    <IconButton
                      colorScheme="teal"
                      aria-label="Close button"
                      size="xs"
                      bg="transparent"
                      _hover={{ background: 'transparent' }}
                      icon={<CloseIcon color="gray.dark" />}
                      onClick={(e) => {
                        e.stopPropagation();
                        removeFile(file);
                      }}
                    />
                  </Flex>
                </GridItem>
              ))}
            </Grid>
            {acceptFileTypes !== '' && (
              <Text fontSize="10px" px={4} as="em" color="gray.dark">
                Accepted file types: {acceptFileTypes}
              </Text>
            )}
          </div>
        </ModalBody>
        <ModalFooter
          borderBottomRightRadius={8}
          borderBottomLeftRadius={8}
          bg="silver.essence"
        >
          <Button
            size="md"
            variant="outline"
            borderColor="blue.dusty"
            color="blue.dusty"
            onClick={onClose}
            mr={4}
          >
            Close
          </Button>
          <Button
            size="md"
            bg="blue.dusty"
            color="white.base"
            onClick={handleSave}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
