import {
  Applicant,
  defaultApplicant,
  PageType,
  RelationshipType,
  Section,
  SectionElementType,
} from '@common/entities';
import { ModalApplicationStore } from 'state/modalApplicationReducer';

import type { Block, Field } from 'types/fields';
import type { Form } from 'types/form';
import type { AnyPage } from 'types/page';

import { getTodaysDate } from './date';
import { getActiveResponse } from './getActiveResponse';

const filterOutElements = (
  elements: Array<Block | Field>,
  secondaryApplicantType: RelationshipType,
) => {
  return elements.filter((element) => {
    const { elementType } = element;
    const hideFromCoApplicant =
      elementType === SectionElementType.Block &&
      ((secondaryApplicantType === RelationshipType.Coapplicant &&
        element?.configuration?.hideFromCoapplicant) ||
        (secondaryApplicantType === RelationshipType.Guarantor &&
          element?.configuration?.hideFromGuarantor));
    return hideFromCoApplicant ? false : element;
  });
};

const filterOutSections = (
  sections: Section[],
  secondaryApplicantType: RelationshipType,
) => {
  return sections.map((section) => {
    return {
      ...section,
      elements: filterOutElements(section.elements, secondaryApplicantType),
    };
  });
};

const getCoApplicantPages = (
  pagesArr: AnyPage[],
  secondaryApplicantType: RelationshipType,
) =>
  pagesArr.reduce((acc: any, page: any) => {
    const sections = filterOutSections(page.sections, secondaryApplicantType);
    const visibleSections: any[] = [];
    sections.forEach((section) => {
      const visibleElements = filterOutElements(
        section.elements,
        secondaryApplicantType,
      );
      if (visibleElements?.length > 0) {
        visibleSections.push(section);
      }
    });
    const hidePage =
      (secondaryApplicantType === RelationshipType.Coapplicant &&
        page?.configuration?.hideFromCoApplicant) ||
      (secondaryApplicantType === RelationshipType.Guarantor &&
        page?.configuration?.hideFromGuarantor) ||
      (visibleSections.length === 0 && page?.type !== PageType.Summary);

    if (!hidePage) {
      return [
        ...acc,
        {
          ...page,
          sections: visibleSections,
        },
      ];
    }
    return acc;
  }, []);

export const getSecondaryApplicantStoreJson = (
  form: Form,
  applicant: Applicant,
  secondaryResponseId: string,
  secondaryApplicantType: RelationshipType = RelationshipType.Coapplicant,
): ModalApplicationStore => {
  const { pages } = form;
  const updatedPages = getCoApplicantPages(pages, secondaryApplicantType);
  const activeResponse = getActiveResponse(applicant, secondaryResponseId);

  return {
    response: { value: [] },
    leadProfile: {
      primaryResponseId: '',
      primaryApplicant: defaultApplicant,
      applicationForm: {
        id: '',
        name: '',
        description: '',
        type: '',
        clientId: 0,
        pages: [],
      },
      applicants: [],
      applicantResponses: [],
    },
    fieldSettings: {
      disabledFields: [],
      isApplicationLocked: false,
    },
    application: {
      applicant: defaultApplicant,
      form: { ...form, pages: updatedPages },
      activeResponse,
    },
    navigation: {
      currentTabIndex: 0,
      activeStepByTab: {},
      stepStatus: {},
      leasingJourneyErrors: Array.from(Array(updatedPages?.length).values()),
      modalNavigationActiveStep: 0,
      previewModeActiveStep: 0,
    },
    payment: {
      paymentTypes: [],
      selectedPaymentType: null,
      paymentInfo: {},
      savePaymentInfo: false,
      termsAndConditionAck: false,
      paymentAccount: null,
      paymentSettings: {},
      error: [],
      moneyGramAccountInfo: null,
    },
    shoppingCart: {
      charges: [],
    },
    unitSelection: {
      floorPlan: null,
      unit: null,
      leaseTerm: null,
      addOns: [],
      selectedSpecials: [],
      moveInDate: getTodaysDate(),
      quote: null,
      quoteLeaseTerm: null,
      propertySpecials: null,
      floorplanSpecials: null,
      unitSpaceSpecials: null,
      unitTypeSpecials: null,
      promoCodeSpecials: null,
    },
    pets: {
      selectedPets: [],
    },
  };
};
