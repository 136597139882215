"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpecialRate = exports.Special = exports.SpecialLeaseTerm = exports.SpecialGroup = void 0;
var SpecialGroup = /** @class */ (function () {
    function SpecialGroup() {
        this.groupId = 0;
        this.orgId = 0;
        this.propertyId = 0;
        this.groupName = '';
        this.isActive = false;
        this.showOnWebsite = false;
        this.hideEndDate = false;
        this.groupTypeId = 0;
        this.specialLimit = 0;
        this.specialRecipientIds = [];
        this.limitQuantity = false;
        this.isSelectable = false;
        this.leaseTermId = 0;
        this.leaseStartWindowId = 0;
        this.leaseTermName = '';
        this.arCascadeId = 0;
        this.arCascadeReferenceIds = [];
        this.autoQualified = false;
        this.specialCount = 0;
        this.specials = [];
        this.leaseTerms = [];
    }
    return SpecialGroup;
}());
exports.SpecialGroup = SpecialGroup;
var SpecialLeaseTerm = /** @class */ (function () {
    function SpecialLeaseTerm() {
        this.leaseTermId = 0;
        this.leaseTermName = '';
    }
    return SpecialLeaseTerm;
}());
exports.SpecialLeaseTerm = SpecialLeaseTerm;
var Special = /** @class */ (function () {
    function Special() {
        this.specialId = 0;
        this.groupId = 0;
        this.giftValue = 0;
        this.specialName = '';
        this.specialTypeId = 0;
        this.specialTypeName = '';
        this.rates = [];
    }
    return Special;
}());
exports.Special = Special;
var SpecialRate = /** @class */ (function () {
    function SpecialRate() {
        this.id = 0;
        this.rateAmount = 0;
        this.leaseTermId = 0;
    }
    return SpecialRate;
}());
exports.SpecialRate = SpecialRate;
