/* istanbul ignore file */
import { createStandaloneToast } from '@chakra-ui/react';
import { NotificationStatus } from 'enums/notification-status';

const notification = (message: string, type = NotificationStatus.ERROR) => {
  const { toast } = createStandaloneToast();
  toast({
    size: 'xs',
    title: message,
    status: type,
    duration: 3000,
    containerStyle: {
      color: 'white.base',
    },
  });
};

export default notification;
