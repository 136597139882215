import { BlockType, OccupancyType, ProspectDocument } from '@common/entities';
import { get, post } from 'config/api/config';

const DOCUMENT_CONTROLLER = '/viewer/document';
const POLICY_DOCUMENT_ENDPOINT = `${DOCUMENT_CONTROLLER}/policy-documents`;
const PROSPECT_DOCUMENT_ENDPOINT = `${DOCUMENT_CONTROLLER}/prospect-documents`;
const GENERATE_DOCUMENT_ENDPOINT = `${DOCUMENT_CONTROLLER}/generate`;
const GENERATE_POLICY_DOCUMENTS_ENDPOINT =
  '/viewer/document/policy-documents/generate';

export const generatePolicyDocuments = async () => {
  const response = await post(GENERATE_POLICY_DOCUMENTS_ENDPOINT);
  return response;
};

export const getPolicyDocument = async (fileAssociationId: number) => {
  const response = await get(
    `${POLICY_DOCUMENT_ENDPOINT}/${fileAssociationId}`,
    {
      responseType: 'arraybuffer',
    },
  );
  return response;
};

export const getPolicyDocumentForSecondaryApplicant = async (
  fileAssociationId: number,
  email: string,
) => {
  const response = await get(
    `${POLICY_DOCUMENT_ENDPOINT}/secondary/${fileAssociationId}/${email}`,
    {
      responseType: 'arraybuffer',
    },
  );
  return response;
};

export const getPolicyDocuments = async (email?: string) => {
  const url = email
    ? `${POLICY_DOCUMENT_ENDPOINT}?email=${email}`
    : POLICY_DOCUMENT_ENDPOINT;

  const response = await get(url);
  return response;
};

export const getPolicyDocumentsForSecondaryApplicant = async (
  email: string,
) => {
  const response = await get(`${POLICY_DOCUMENT_ENDPOINT}/secondary/${email}`);
  return response;
};

export const signPolicyDocument = async (
  fileAssociationId: number,
  initials: string,
) => {
  const response = await get(
    `${POLICY_DOCUMENT_ENDPOINT}/sign/${fileAssociationId}/${initials}`,
  );
  return response;
};

export const signPolicyDocumentForSecondaryApplicant = async (
  fileAssociationId: number,
  initials: string,
  email: string,
) => {
  const response = await get(
    `${POLICY_DOCUMENT_ENDPOINT}/sign/secondary/${fileAssociationId}/${initials}/${email}`,
  );
  return response;
};

export const getProspectDocuments = async () => {
  const response = await get<ProspectDocument[]>(PROSPECT_DOCUMENT_ENDPOINT);
  return response?.data;
};

export const getProspectDocument = async (documentId: number) => {
  if (!documentId) throw new Error('Document Id is missing.');

  const response = await get(`${PROSPECT_DOCUMENT_ENDPOINT}/${documentId}`, {
    responseType: 'arraybuffer',
  });
  return response;
};

export const signProspectDocument = async (
  documentId: number,
  fullName: string,
) => {
  const response = await post(
    `${PROSPECT_DOCUMENT_ENDPOINT}/signDocument/${documentId}`,
    {
      documentId,
      fullName,
    },
  );
  return response;
};

interface GenerateDocReq {
  documentId: number[];
  userResponse: string;
  type:
    | BlockType.Disability
    | BlockType.Student
    | BlockType.PoliceSecurityOfficer
    | BlockType.MilitaryVeteran
    | BlockType.HouseholdFleeingDomesticViolence
    | BlockType.HousedDeclaredDisaster;
}
interface GenerateDocResponse {
  response: {
    requestId: number;
    code: number;
    result: {
      status: number;
      files: {
        id: number;
        file_name: string;
        require_confirmation: string;
        sign_initiated_on: string;
        file_signed_on: string;
        is_viewed: number;
      }[];
    };
  };
}

export const generateDocument = async (
  data: GenerateDocReq[],
  occupancyType = OccupancyType.Conventional,
) => {
  const response = await post<GenerateDocResponse>(
    `${GENERATE_DOCUMENT_ENDPOINT}/${occupancyType}`,
    data,
  );
  return response?.data?.response?.result?.files;
};
