/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetStoreAction } from 'state/commonActions';

export interface StepStatus {
  errorCount?: number;
  touched?: boolean;
  complete?: boolean;
}

export type NavigationState = {
  currentTabIndex: number;
  activeStepByTab: { [tabIndex: number]: string };
  stepStatus: { [tabIndex: number]: { [stepId: string]: StepStatus } };
  leasingJourneyErrors: (number | null)[];
  modalNavigationActiveStep: number;
  activePageId?: string | null;
  previewModeActiveStep: number;
};

const initialState: NavigationState = {
  currentTabIndex: 0,
  activeStepByTab: {},
  stepStatus: {},
  leasingJourneyErrors: [],
  modalNavigationActiveStep: 0,
  activePageId: null,
  previewModeActiveStep: 0,
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setCurrentTabIndex: (state, action: PayloadAction<number>) => {
      state.currentTabIndex = action.payload;
      if (state.activeStepByTab[action.payload] === undefined) {
        state.activeStepByTab[action.payload] = '';
      }
    },
    setActiveStep: (
      state,
      action: PayloadAction<{ tabIndex: number; step: string }>,
    ) => {
      const { tabIndex, step } = action.payload;
      state.activeStepByTab[tabIndex] = step;
    },
    resetActiveStepForTab: (state, action: PayloadAction<number>) => {
      state.activeStepByTab[action.payload] = '';
    },
    updateStepStatus: (
      state,
      action: PayloadAction<{
        tabIndex: number;
        step: string;
        status: StepStatus;
      }>,
    ) => {
      const { tabIndex, step, status } = action.payload;
      if (!state.stepStatus[tabIndex]) {
        state.stepStatus[tabIndex] = {};
      }

      state.stepStatus[tabIndex][step] = status;
    },
    setLeasingJourneyError(
      state,
      action: PayloadAction<{ pages: any[]; errors: (number | null)[] }>,
    ) {
      const { errors } = action.payload;
      return { ...state, leasingJourneyErrors: errors };
    },
    updateLeasingJourneyErrors: (
      state,
      action: PayloadAction<{ pageIndex: number; count: number | null }>,
    ) => {
      const { pageIndex, count } = action.payload;
      const updatedErrors = state.leasingJourneyErrors.map(
        (errorCount, index) => {
          if (index === pageIndex) {
            return count;
          }
          return errorCount;
        },
      );
      return { ...state, leasingJourneyErrors: updatedErrors };
    },
    setActivePageId: (state, action: PayloadAction<string | null>) => {
      state.activePageId = action.payload;
    },
    updateModalNavigationActiveStep: (state, action: PayloadAction<number>) => {
      return { ...state, modalNavigationActiveStep: action.payload };
    },
    updatePreviewModeActiveStep: (state, action: PayloadAction<number>) => {
      return { ...state, previewModeActiveStep: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStoreAction, () => initialState);
  },
});

export const {
  resetActiveStepForTab,
  setActiveStep,
  setCurrentTabIndex,
  setLeasingJourneyError,
  updateLeasingJourneyErrors,
  setActivePageId,
  updateModalNavigationActiveStep,
  updateStepStatus,
  updatePreviewModeActiveStep,
} = navigationSlice.actions;

export default navigationSlice.reducer;
