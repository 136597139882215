/* eslint-disable no-param-reassign */

// To disable specific fields in Lead Profile use.
// dispatch(setDisabledFields(['applicant_name_first', 'applicant_name_last', 'currentAddress.0.street_line1']));

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetStoreAction } from 'state/commonActions';

export interface FieldSettingsState {
  disabledFields: string[];
  isApplicationLocked: boolean;
}

const initialState: FieldSettingsState = {
  disabledFields: [],
  isApplicationLocked: false,
};

const fieldSettingsSlice = createSlice({
  name: 'fieldSettings',
  initialState,
  reducers: {
    setDisabledFields(state, action: PayloadAction<string[]>) {
      state.disabledFields = action.payload;
    },
    addDisabledField(state, action: PayloadAction<string>) {
      if (!state.disabledFields.includes(action.payload)) {
        state.disabledFields.push(action.payload);
      }
    },
    removeDisabledField(state, action: PayloadAction<string>) {
      state.disabledFields = state.disabledFields.filter(
        (field) => field !== action.payload,
      );
    },
    clearDisabledFields(state) {
      state.disabledFields = [];
    },
    setIsApplicationLocked(state, action: PayloadAction<boolean>) {
      state.isApplicationLocked = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStoreAction, () => initialState);
  },
});

export const {
  setDisabledFields,
  addDisabledField,
  removeDisabledField,
  clearDisabledFields,
  setIsApplicationLocked,
} = fieldSettingsSlice.actions;
export default fieldSettingsSlice.reducer;
