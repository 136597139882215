import {
  ScreeningCondition,
  ScreeningConditionOperandType,
  ScreeningConditionSet,
} from '@common/entities';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetStoreAction } from 'state/commonActions';

export type ApplicationScreeningRequiredItems = {
  operandType: ScreeningConditionOperandType;
  requiredItemsInSubsetOne: number;
  requiredItemsInSubsetTwo: number;
};

export type ApplicationScreeningReducer = {
  conditionSets: ScreeningConditionSet[];
  subsetHeadings: any;
  requiredItems: ApplicationScreeningRequiredItems | null;
  selectedFinancialConditions: ScreeningCondition[];
  applicantName: string;
  applicationName: string;
  confirmationMessage: string;
};

const initialState: ApplicationScreeningReducer = {
  conditionSets: [],
  subsetHeadings: {},
  requiredItems: null,
  selectedFinancialConditions: [],
  applicantName: '',
  applicationName: '',
  confirmationMessage: '',
};

const getSubsetHeadings = (conditionSets: ScreeningConditionSet[]) => {
  if (conditionSets.length === 0) return {};
  const headings: any = {};
  const { subsets } = conditionSets[0];
  subsets.forEach((subset) => {
    let label = `Choose ${
      subset.requiredItems === subset.conditions.length
        ? 'all'
        : subset.requiredItems
    } of these options`;
    if (Object.keys(headings).length > 0) {
      label = `${
        conditionSets[0].operandType === ScreeningConditionOperandType.Or
          ? 'or '
          : 'and '
      }${label}`;
    }
    headings[subset.id] = label;
  });

  return headings;
};

const getRequiredItems = (
  conditionSets: ScreeningConditionSet[],
): ApplicationScreeningRequiredItems | null => {
  if (conditionSets.length === 0) return null;

  const requiredItems: ApplicationScreeningRequiredItems = {
    operandType: conditionSets[0].operandType,
    requiredItemsInSubsetOne:
      conditionSets[0].subsets.length > 0
        ? conditionSets[0].subsets[0].requiredItems
        : 0,
    requiredItemsInSubsetTwo:
      conditionSets[0].subsets.length > 1
        ? conditionSets[0].subsets[1].requiredItems
        : 0,
  };
  return requiredItems;
};

export const applicationScreeningSlice = createSlice({
  name: 'applicationScreening',
  initialState,
  reducers: {
    setApplicationScreening: (
      state,
      action: PayloadAction<ApplicationScreeningReducer>,
    ) => {
      return {
        ...state,
        conditionSets: action.payload.conditionSets,
        subsetHeadings: getSubsetHeadings(action.payload.conditionSets),
        requiredItems: getRequiredItems(action.payload.conditionSets),
        selectedFinancialConditions: action.payload.selectedFinancialConditions,
        applicantName: action.payload.applicantName,
        applicationName: action.payload.applicationName,
        confirmationMessage: action.payload.confirmationMessage,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStoreAction, () => initialState);
  },
});

export const { setApplicationScreening } = applicationScreeningSlice.actions;
export default applicationScreeningSlice.reducer;
