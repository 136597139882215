import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'state/store';

export function useGetActiveStepIndex() {
  const { form } = useSelector((state: RootState) => state.application);
  const { pages } = form;

  const { pageId } = useParams() as {
    pageId: string;
  };

  const getActiveStepIndex = React.useCallback(
    () => pages.findIndex((page) => page.id === pageId),
    [pageId, pages],
  );

  return getActiveStepIndex;
}

export function useActiveStepIndexFromRedux() {
  const { currentTabIndex, activeStepByTab } = useSelector(
    (state: RootState) => state.navigation,
  );
  const activeStepId = activeStepByTab[currentTabIndex] || '';

  const { form } = useSelector((state: RootState) => state.application);
  const { pages } = form;

  const activeStepIndex = pages.findIndex((page) => page.id === activeStepId);

  return activeStepIndex;
}
