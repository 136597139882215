import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from 'state/store';

const ProtectedRoute = ({ children }: any) => {
  const navigate = useNavigate();
  const { orgId, propertyId } = useParams();

  const { isAuthenticated } = useSelector((state: RootState) => {
    return state.auth;
  });

  if (!isAuthenticated && orgId && propertyId) {
    navigate(`/${orgId}/${propertyId}`, { replace: true });
    return null;
  }

  return children;
};

export default ProtectedRoute;
