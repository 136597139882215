"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentResponse = void 0;
var PaymentResponse = /** @class */ (function () {
    function PaymentResponse() {
        this.success = false;
        this.totalAmount = 0;
        this.payments = [];
    }
    return PaymentResponse;
}());
exports.PaymentResponse = PaymentResponse;
