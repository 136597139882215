"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SOCIAL_SECURITY_NUMBER = void 0;
var entities_1 = require("@common/entities");
exports.SOCIAL_SECURITY_NUMBER = {
    name: 'social_security_number',
    label: 'Social Security Number',
    customType: entities_1.IDTypes.SocialSecurityNumber,
    elementType: entities_1.SectionElementType.Block,
    fields: [
        {
            customType: entities_1.FieldType.Number,
            enabled: true,
            label: 'Social Security Number',
            name: 'value',
            required: true,
            sequence: 1,
            type: entities_1.FieldType.Number,
        },
    ],
};
