import { Page, PageType, RelationshipType } from '@common/entities';
import { ApplicationForm } from 'state/slices/leadProfileSlice';

interface Element {
  id: string;
  name: string;
  type: string;
  label?: string;
}

// Blocks to exclude for the lead profile.
const BLOCK_NAMES_TO_EXCLUDE = ['coapplicant_block', 'guarantors'];

export function filterApplicationPages(
  applicationForm: ApplicationForm | null,
  relationshipType: RelationshipType,
  isLeadProfile = false,
): Page[] {
  if (!applicationForm || !applicationForm.pages) {
    return [];
  }

  // First, filter the pages based on the relationshipType and page type.
  const filteredPages = applicationForm.pages.filter((page) => {
    const shouldHide =
      page.configuration &&
      ((page.configuration.hideFromCoApplicant &&
        relationshipType === RelationshipType.Coapplicant) ||
        (page.configuration.hideFromGuarantor &&
          relationshipType === RelationshipType.Guarantor));
    return page.type !== PageType.ThankYou && !shouldHide;
  });

  // Now, filter out specific blocks within the sections of each page.
  // Only show the page if there are any elements left to show.
  const cleanedPages = filteredPages
    .map((page) => {
      if (page.type === PageType.Blank) {
        const newSections = page.sections
          .map((section: any) => {
            const newElements = section.elements.filter((element: Element) => {
              if (isLeadProfile) {
                return !BLOCK_NAMES_TO_EXCLUDE.includes(element.name);
              }
              return true;
            });
            return { ...section, elements: newElements };
          })
          .filter((section: any) => section.elements.length > 0);

        if (newSections.length > 0) {
          return { ...page, sections: newSections };
        }
        return null;
      }
      return page;
    })
    .filter((page) => page !== null);

  // Finally, sort the pages by sequence.
  return cleanedPages.sort((a, b) => a.sequence - b.sequence);
}
