import { get } from 'config/api/config';

const SCHEDULED_CHARGES_ENDPOINTS = '/viewer/scheduled-charges';

interface ScheduleChargeResponse {
  orgId: number;
  leaseId: number;
  propertyId: number;
  leaseIntervalId: number;
  arTriggerId: number;
  arTriggerName: string;
  chargeStartDate: string;
  chargeEndDate: string;
  installmentId: number;
  endsWithMoveOut: boolean;
  chargeAmount: {
    amountNumber: number;
    amountFormatted: string;
    currencyCode: string;
  };
  totalValue: string;
  deletedOn: string;
  ledgerFilterId: number;
  arCodeName: string;
  isHistorical: number;
  customerName: string;
  taxAmount: string;
  summarizationGroupId: number;
}
export const getScheduledCharges = async (
  propertyId: number,
  leaseId: number,
  leaseIntervalId?: number,
) => {
  if (!propertyId || !leaseId) throw new Error('Missing parameter.');

  const response = await get<ScheduleChargeResponse[]>(
    `${SCHEDULED_CHARGES_ENDPOINTS}?propertyId=${propertyId}&leaseId=${leaseId}&leaseIntervalId=${leaseIntervalId}`,
  );
  return response;
};
