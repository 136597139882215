import React from 'react';
import { useSelector } from 'react-redux';
import {   
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel, 
  Box, 
  Grid,
  Text 
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { RootState } from 'state/store';

import { getAllSpecialsForUnit } from 'utils/specials';

export function SpecialsCard() {
  const { 
    floorPlan, 
    unit, 
    propertySpecials, 
    floorplanSpecials, 
    unitSpaceSpecials, 
    unitTypeSpecials,
    selectedSpecials,
  } = useSelector(
    (state: RootState) => state.unitSelection,
  );
  const allAvailableSpecialsForUnit = getAllSpecialsForUnit(
    floorPlan, unit, propertySpecials, floorplanSpecials, unitSpaceSpecials, unitTypeSpecials);

  return (
    unit && selectedSpecials.length > 0 && (
      <Accordion defaultIndex={[0, 1, 2]} allowMultiple>
        <AccItems>
          <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              Special Promotions
            </Box>
            <AccIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          { allAvailableSpecialsForUnit.map((specialGroup) => {
            const selectedSpecialsInGroup = selectedSpecials.filter((special) => special.groupId === specialGroup.groupId);
            if (selectedSpecialsInGroup.length === 0) 
              return null;
            return (            
              <Box mb={5} mt={5}>
                <Grid        
                  gap={2}
                  p={2}
                  mt={3}
                  border="1px solid"
                  borderColor="silver.highlight"
                  borderRadius="16px"
                  boxShadow="0px 1px 3px rgba(0, 0, 0, 0.15)"
                >
                  <Text  whiteSpace="pre-line" fontWeight="bold" fontSize="xs">
                    {specialGroup.groupName}
                  </Text>
                  {selectedSpecialsInGroup.map((special: any) => (
                    <Text whiteSpace="pre-line" fontSize="xs">
                      {special.specialName}
                    </Text>
                  ))}
                </Grid>
              </Box>
            );
          })}

        </AccordionPanel>
        </AccItems>
      </Accordion>
  )
);
}

const AccItems = styled(AccordionItem)`
  border-top: 1px solid ${({ theme }: any) => theme.colors.gray.light};
`;
const AccIcon = styled(AccordionIcon)`
  background: ${({ theme }: any) => theme.colors.gray.light};
  border-radius: 4px;
  width: 25px;
  height: 25px;
`;
