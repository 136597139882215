"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouterTarget = void 0;
var enums_1 = require("./enums");
var RouterTarget = /** @class */ (function () {
    function RouterTarget() {
        this.id = '';
        this.type = enums_1.RouterTargetType.Application;
    }
    return RouterTarget;
}());
exports.RouterTarget = RouterTarget;
