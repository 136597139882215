/* istanbul ignore file */
import { useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { ProspectDocument } from '@common/entities';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { LeasingJourneyHeader } from 'components/header';
import { Loader } from 'components/Loader';
import { getProspectDocument, signProspectDocument } from 'services';
import handleError from 'utils/handleError';

import PdfRenderer from './PdfRenderer';

export function convertUTCDateToLocalDate(dateString: string) {
  let newDate = new Date(dateString);
  newDate = new Date(
    newDate.getTime() + newDate.getTimezoneOffset() * 60 * 1000,
  );

  const offset = newDate.getTimezoneOffset() / 60;
  const hours = newDate.getHours();

  newDate.setHours(hours - offset);

  return newDate
    .toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
    .concat(' ', newDate.toLocaleTimeString());
}

function PdfSigner() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { orgId, propertyId } = useParams();

  const [documentUrl, setDocumentUrl] = useState('');
  const [isSigning, setIsSigning] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [remainingUnsigned, setRemainingUnsigned] = useState(0);

  const { firstName, lastName, digitalSignature } = location.state;

  const currentDocumentIndex = location.state.documentList.findIndex(
    (d: any) => d.id.toString() === location.state.documentId,
  );

  const currentDocument = location.state.documentList[currentDocumentIndex];

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

  const { data, error } = useQuery({
    queryKey: [
      'sign-prospect-document-get-document',
      location.state.documentId,
    ],
    queryFn: () => getProspectDocument(currentDocument.id),
  });

  useEffect(() => {
    if (data) {
      const fileData = new Blob([data.data], { type: 'application/pdf' });
      setDocumentUrl(URL.createObjectURL(fileData));
      setIsSigning(false);
      setRemainingUnsigned(
        location.state.documentList.filter((d: any) => !d.isSigned).length,
      );
      setIsLoading(false);
    }
  }, [data, location.state.documentList]);

  if (error instanceof Error) {
    handleError(
      `Form Viewer - Error while signing PDF: ${error}`,
      'Error signing PDF.',
    );
  }

  const handleSignDocument = async () => {
    setIsSigning(true);
    const signedDocumentResponse = await signProspectDocument(
      currentDocument.id ?? 0,
      digitalSignature,
    );
    const signedDocument: any = signedDocumentResponse.data;
    currentDocument.isSigned = signedDocument.isSigned;
    currentDocument.signedDate = signedDocument.signedDate;
    setIsSigning(false);
    if (
      location.state.documentList.some(
        (d: any) => d.requiresSigning && !d.isSigned,
      )
    ) {
      handleNextDocument(location.state.documentList);
    } else {
      // eslint-disable-next-line no-console
      console.log('Returning to entry page');
      navigate(-1); // back to entry page
    }
  };

  const handleNextDocument = async (
    updatedDocumentsList?: ProspectDocument[],
  ) => {
    const nextDocumentIndex =
      location.state.documentList.length === currentDocumentIndex + 1
        ? 0
        : currentDocumentIndex + 1;

    navigateToDocument(
      nextDocumentIndex,
      updatedDocumentsList
        ?.filter((d) => d.requiresSigning)
        ?.sort((d1, d2) => (!d1.isSigned && d2.isSigned ? -1 : 1)),
    );
  };

  const handlePreviousDocument = async () => {
    const previousDocumentIndex =
      location.state.documentList.length === currentDocumentIndex > 0
        ? currentDocumentIndex - 1
        : 0;

    navigateToDocument(previousDocumentIndex);
  };

  function navigateToDocument(
    documentIndex: number,
    updatedDocumentsList?: ProspectDocument[],
  ) {
    queryClient.invalidateQueries({ queryKey: ['signProspectDocument'] });
    // setDocumentUrl(''); (necessary if we navigate back to the same document...)
    setIsLoading(true);
    const navigationState = {
      replace: true,
      state: {
        documentId: location.state.documentList[documentIndex].id.toString(),
        documentList: updatedDocumentsList ?? location.state.documentList,
        firstName,
        lastName,
        digitalSignature,
      },
    };
    // eslint-disable-next-line no-console
    console.log('Navigating to document:', navigationState);
    navigate(`/${orgId}/${propertyId}/signPdf`, navigationState);
  }

  return (
    <Flex flexDir="column" h="100%">
      <LeasingJourneyHeader />
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        marginLeft={5}
        marginTop={5}
      >
        <Flex marginTop={14} direction="column">
          <Text fontSize="md" fontWeight="semibold">
            {`${currentDocument.name}`}
          </Text>
          <Text fontStyle="italic" mt={1} fontSize="sm" fontWeight="semibold">
            {`1 of ${remainingUnsigned} unsigned documents`}
          </Text>
        </Flex>
        {!currentDocument.isSigned && (
          <Button
            size="md"
            marginTop={20}
            marginRight={20}
            onClick={() => handleSignDocument()}
            bg="orange.300"
          >
            Sign Document
          </Button>
        )}
        {currentDocument.isSigned && (
          <Box
            height={9}
            bg="green.100"
            marginTop={20}
            marginRight={20}
            borderRadius="md"
            padding={2}
          >
            <Text fontSize="sm">
              {`Signed: ${convertUTCDateToLocalDate(
                currentDocument.signedDate,
              )}`}
            </Text>
          </Box>
        )}
      </Flex>
      {!isSigning && (isLoading || !documentUrl) && (
        <Loader label="Loading document..." />
      )}
      {isSigning && <Loader label="Signing document..." />}
      {!isLoading && documentUrl && (
        <PdfRenderer
          enablePaging={false}
          documentUrl={documentUrl}
          documentName={currentDocument.name}
          showFooter
        />
      )}
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        marginBottom={5}
        marginRight={10}
      >
        {location.state.documentList.length > 1 && (
          <>
            <Button
              size="sm"
              marginLeft={12}
              onClick={() => handlePreviousDocument()}
              bg="gray.800"
              color="white"
              isDisabled={currentDocumentIndex === 0}
            >
              Previous
            </Button>
            <Button
              size="sm"
              marginRight={12}
              onClick={() => handleNextDocument()}
              bg="gray.800"
              color="white"
              isDisabled={
                currentDocumentIndex === location.state.documentList.length - 1
              }
            >
              Next
            </Button>
          </>
        )}
      </Flex>
    </Flex>
  );
}

export default PdfSigner;
