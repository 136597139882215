"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PASSPORT = void 0;
var entities_1 = require("@common/entities");
exports.PASSPORT = {
    name: 'passport',
    label: 'Passport',
    customType: entities_1.IDTypes.Passport,
    elementType: entities_1.SectionElementType.Block,
    fields: [
        {
            customType: entities_1.FieldType.Text,
            enabled: true,
            label: 'Passport',
            name: 'value',
            required: true,
            sequence: 1,
            type: entities_1.FieldType.Text,
        },
        {
            customType: entities_1.FieldType.Date,
            enabled: true,
            label: 'Issue Date',
            name: 'issue_date',
            required: true,
            sequence: 2,
            type: entities_1.FieldType.Date,
        },
        {
            customType: entities_1.FieldType.Date,
            enabled: true,
            label: 'Expiration Date',
            name: 'expiry_date',
            required: true,
            sequence: 3,
            type: entities_1.FieldType.Date,
        },
        {
            customType: entities_1.FieldType.CountryDropdown,
            enabled: true,
            label: 'Country',
            name: 'country_code',
            required: true,
            sequence: 4,
            type: entities_1.FieldType.CountryDropdown,
            options: [],
        },
    ],
};
