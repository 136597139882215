/* istanbul ignore file */
import { useSelector } from 'react-redux';
import { RootState } from 'state/store';

import { applicationComplete } from 'services';

interface PaymentInfoProp {
  enabled: boolean;
  processed: boolean;
}
export function useCompleteApplication() {
  const { applicant, activeResponse } = useSelector(
    (state: RootState) => state.application,
  );

  const { email, propertyId } = applicant;
  const { applicationId, responseId, versionId } = activeResponse;

  function validateRequestData() {
    if (!applicationId) throw new Error('Application Id is not available.');
    if (!versionId) throw new Error('Version Id is not available.');
    if (!email) throw new Error('Email Id is not available.');
    if (!propertyId) throw new Error('Property Id is not available.');
  }

  async function completeApplication(
    digitalSignature: string,
    paymentInfo: PaymentInfoProp,
  ) {
    try {
      validateRequestData();
      const ipAddress = '174.253.83.192';

      const response = await applicationComplete({
        propertyId,
        applicationId,
        versionId,
        emailAddress: email,
        payment: paymentInfo,
        digitalSignature,
        ipAddress,
        responseId: responseId as string,
      });
      if (response.status < 200 && response.status > 300) {
        throw new Error(
          'Something went wrong with submitting your application. Please try again later.',
        );
      }
    } catch (err: any) {
      throw new Error(err.message);
    }
  }

  return completeApplication;
}
