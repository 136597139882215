"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Quote = void 0;
var unit_entity_1 = require("./unit.entity");
var Quote = /** @class */ (function () {
    function Quote() {
        this.id = 0;
        this.moveInDate = '';
        this.createdOn = '';
        this.acceptedOn = '';
        this.expiresOn = '';
        this.cancelledOn = '';
        this.leaseTerms = [];
        this.unitDetails = new unit_entity_1.Unit();
        this.applicationId = '';
        this.versionId = '';
        this.entrataApplicationId = 0;
        this.entrataLeaseId = 0;
    }
    return Quote;
}());
exports.Quote = Quote;
