/* istanbul ignore file */
import { FieldErrors, FieldValues } from 'react-hook-form';

type ErrorCountArrOfObj = { [key: string]: any }[];
const getErrorCountFromArrOfObj = (arr: ErrorCountArrOfObj) => {
  if (!arr.length) return 0;

  let count = 0;
  arr.forEach((item) => {
    if (item) count += Object.keys(item).length;
  });
  return count;
};

type IncomeBlockErrors = {
  incomeTypes: ErrorCountArrOfObj;
  income_question: any;
};
const getIncomeBlockErrorCount = (errors: IncomeBlockErrors): number => {
  const { incomeTypes, income_question: incomeQuestion } = errors;

  let count = 0;
  if (incomeQuestion) {
    count += 1;
  }

  if (incomeTypes && incomeTypes.length > 0) {
    count += getErrorCountFromArrOfObj(incomeTypes);
  } else if (!incomeQuestion && incomeTypes.length === 0) {
    count += 1;
  }

  return count;
};

type AssetsBlockErrors = {
  assetTypes: ErrorCountArrOfObj;
  assets_question: any;
};
const getAssetsBlockErrorCount = (errors: AssetsBlockErrors): number => {
  const { assetTypes, assets_question: assetsQuestion } = errors;

  let count = 0;
  if (assetsQuestion) {
    count += 1;
  }

  if (assetTypes && assetTypes.length > 0) {
    count += getErrorCountFromArrOfObj(assetTypes);
  } else if (!assetsQuestion && assetTypes.length === 0) {
    count += 1;
  }

  return count;
};

type PetBlockErrors = {
  petList: ErrorCountArrOfObj;
};
const getPetsBlockErrorCount = (errors: PetBlockErrors): number => {
  const { petList } = errors;
  let count = 0;

  if (petList) {
    count += getErrorCountFromArrOfObj(petList);
  }

  return count;
};

type CoApplicantBlockError = {
  coApplicantList: ErrorCountArrOfObj;
};
const getCoApplicantBlockErrorCount = (
  errors: CoApplicantBlockError,
): number => {
  const { coApplicantList } = errors;

  let count = 0;
  if (coApplicantList) {
    count += getErrorCountFromArrOfObj(coApplicantList);
  }

  return count;
};

type VehicleBlockError = {
  vehicleList: { [key: string]: any }[];
};
const getVehiclesBlockErrorCount = (errors: VehicleBlockError): number => {
  const { vehicleList } = errors;

  let count = 0;
  if (vehicleList) {
    count += getErrorCountFromArrOfObj(vehicleList);
  }
  return count;
};

type IdentificationErrorType = {
  additionalPrimaryId: ErrorCountArrOfObj;
  alternateId: ErrorCountArrOfObj;
  primaryId: ErrorCountArrOfObj;
};
const getIdentificationBlockErrorCount = (
  errors: IdentificationErrorType,
): number => {
  const { additionalPrimaryId, alternateId, primaryId } = errors;
  let count = 0;

  if (primaryId) {
    count += getErrorCountFromArrOfObj(primaryId);
  }
  if (additionalPrimaryId) {
    count += getErrorCountFromArrOfObj(additionalPrimaryId);
  }
  if (alternateId) {
    count += getErrorCountFromArrOfObj(alternateId);
  }
  return count;
};

type GuarantorBlockError = {
  guarantorList: ErrorCountArrOfObj;
};
const getGuarantorBlockErrorCount = (errors: GuarantorBlockError): number => {
  const { guarantorList } = errors;
  let count = 0;
  if (guarantorList) {
    count += getErrorCountFromArrOfObj(guarantorList);
  }

  return count;
};

const getAddressBlockErrorCount = (errors: ErrorCountArrOfObj): number => {
  let count = 0;
  if (errors) {
    count += getErrorCountFromArrOfObj(errors);
  }
  return count;
};

const getGeneralQuestionBlockErrorCount = (errors: ErrorCountArrOfObj) => {
  let count = 0;
  if (errors) {
    count += Object.values(errors)?.length || 0;
  }
  return count;
};

const getAffordableBlockErrorCount = (errors: ErrorCountArrOfObj) => {
  let count = 0;

  if (errors) {
    count += Object.values(errors)?.length || 0;
  }

  return count;
};

const BLOCK_KEYS = {
  assetDetails: getAssetsBlockErrorCount,
  coApplicant: getCoApplicantBlockErrorCount,
  guarantor: getGuarantorBlockErrorCount,
  identification: getIdentificationBlockErrorCount,
  incomeDetails: getIncomeBlockErrorCount,
  pet: getPetsBlockErrorCount,
  vehicleDetails: getVehiclesBlockErrorCount,
  currentAddress: getAddressBlockErrorCount,
  previousAddress: getAddressBlockErrorCount,
  generalQuestions: getGeneralQuestionBlockErrorCount,
  disability: getAffordableBlockErrorCount,
  militaryVeteran: getAffordableBlockErrorCount,
  student: getAffordableBlockErrorCount,
  housedDeclaredDisaster: getAffordableBlockErrorCount,
  householdFleeingDomesticViolence: getAffordableBlockErrorCount,
  policeSecurityOfficer: getAffordableBlockErrorCount,
};

type Keys = keyof typeof BLOCK_KEYS;

function getBlockFieldErrorCount(key: Keys, errors: any): number {
  const fn = BLOCK_KEYS[key];
  return fn(errors);
}

export function getErrorCount(errors: FieldErrors<FieldValues>) {
  if (!Object.keys(errors)) return 0;
  let count = 0;
  Object.keys(errors).forEach((error: string) => {
    if (
      typeof errors[error] === 'object' &&
      Object.keys(BLOCK_KEYS).includes(error)
    ) {
      count += getBlockFieldErrorCount(error as Keys, errors[error]);
      return;
    }

    count += 1;
  });

  return count;
}
