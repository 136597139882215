"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STOCK_OR_BOND_FIELDS = void 0;
var entities_1 = require("@common/entities");
var ASSET_FIELDS_1 = require("./ASSET_FIELDS");
exports.STOCK_OR_BOND_FIELDS = {
    name: 'stocks-or-bonds',
    label: 'Stocks Or Bonds',
    customType: entities_1.AssetTypes.StocksOrBonds,
    elementType: entities_1.SectionElementType.Block,
    documentUpload: {
        maxFilesAllowed: '2',
        verifyDocumentUpload: true,
    },
    fields: (0, ASSET_FIELDS_1.getAssetFields)('stocks_or_bonds').filter(function (f) {
        return !f.name.endsWith('account_number') && !f.name.endsWith('routing_number');
    }),
};
