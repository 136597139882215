import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { RootState } from 'state/store';

import { AddonOption } from 'types/addon';

interface Props {
  modalTitle: string;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  elementList: AddonOption[];
  selectedAddons: AddonOption[];
  handleSave: (addOns: AddonOption[]) => void;
}
export function AddonModal(props: Props) {
  const {
    elementList,
    isOpen,
    modalTitle,
    onClose,
    title,
    selectedAddons = [],
    handleSave,
  } = props;

  const [addOns, setAddOn] = useState<AddonOption[]>([...selectedAddons]);

  const { unit } = useSelector((state: RootState) => state.unitSelection);

  function handleAddOnSelection(
    e: React.ChangeEvent<HTMLInputElement>,
    element: AddonOption,
  ) {
    const isSelected = e.target.checked;

    if (isSelected) {
      setAddOn((prevState) => [...prevState, element]);
    } else {
      setAddOn((prevState) => prevState.filter((i) => i.id !== element.id));
    }
  }

  useEffect(() => {
    setAddOn(selectedAddons);
  }, [selectedAddons]);

  function handleSaveAddOn() {
    handleSave(addOns);
  }

  const selectedIds = addOns.map((i) => i.id);
  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      closeOnOverlayClick={false}
    >
      <ModalOverlay
        bg="black.200"
        backdropFilter="blur(12px) hue-rotate(0deg)"
      />
      <ModalContent background="white.base" borderRadius="8px">
        <ModalHeader
          background="blue.base"
          color="white.base"
          p={2}
          fontSize={16}
          borderTopLeftRadius="8px"
          borderTopRightRadius="8px"
        >
          {modalTitle}
        </ModalHeader>
        <ModalCloseButton color="white.base" />
        <ModalBody minHeight={100}>
          <Box mt={5}>
            <Heading as="h5" size="sm">
              {title ?? 'Hello'}
            </Heading>
            <Box mt={5}>
              {elementList.map((element) => {
                if (element.unitSpaceId !== null && !unit) return null;

                return (
                  <Flex
                    key={`element-list-${element.id}`}
                    justifyContent="space-between"
                    my={2}
                  >
                    {element.unitSpaceId ? (
                      <Checkbox size="sm" isChecked>
                        {element.name}
                      </Checkbox>
                    ) : (
                      <Checkbox
                        size="sm"
                        defaultChecked={selectedIds.includes(element.id)}
                        onChange={(e) => handleAddOnSelection(e, element)}
                      >
                        {element.name}
                      </Checkbox>
                    )}
                    <Text fontSize="sm">
                      {element.rates.length
                        ? `$${element.rates[0].charge} / ${element.rates[0].frequency}`
                        : 'Free'}
                    </Text>
                  </Flex>
                );
              })}
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Button
            size="sm"
            bg="silver.highlighthalf"
            color="black.dark"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button size="sm" variant="brand" onClick={handleSaveAddOn}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
