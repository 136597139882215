import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'state/store';

import { Loader } from 'components/Loader';

import { AppWrapper, queryClient } from './AppWrapper';

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <PersistGate loading={<Loader />} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <AppWrapper />
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </Router>
  </React.StrictMode>,
);
