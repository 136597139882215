import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CloseIcon } from '@chakra-ui/icons';
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Page, RelationshipType } from '@common/entities';
import { RootState } from 'state/store';

import ApplicationNavigation from 'components/navigation/leasing-journey/main/ApplicationNavigation';
import { filterApplicationPages } from 'utils/filterApplicationPages';

export default function MobileNavigation({
  isOpen,
  onClose,
  onFilteredPages,
}: any) {
  const [filteredPages, setFilteredPages] = useState<Page[]>([]);

  const { isLeadProfile } = useSelector(
    (state: RootState) => state.auth ?? { isLeadProfile: false },
  );
  const applicationForm = useSelector(
    (state: RootState) => state.application.form,
  );
  const { currentTabIndex, activeStepByTab } = useSelector(
    (state: RootState) => state.navigation,
  );
  const activeStep = activeStepByTab[currentTabIndex];

  const drawerPlacement: 'top' | 'left' =
    useBreakpointValue({ base: 'top', md: 'left' }) || 'top';
  const drawerSize: 'sm' | 'xs' =
    useBreakpointValue({ base: 'sm', md: 'xs' }) || 'sm';

  useEffect(() => {
    // Filter pages to remove hideFrom, hideFromGuarantor, and the thank you page.
    const filteredApplicationPages: Page[] = filterApplicationPages(
      applicationForm,
      RelationshipType.Primary,
      isLeadProfile,
    );

    setFilteredPages(filteredApplicationPages);
    onFilteredPages(filteredApplicationPages);
  }, [activeStep, applicationForm, onFilteredPages, isLeadProfile]);

  const handleBackClick = () => {
    onClose();
  };

  return (
    <Drawer
      onClose={onClose}
      placement={drawerPlacement}
      isOpen={isOpen}
      size={drawerSize}
    >
      <DrawerOverlay />
      <DrawerContent
        bg="white.base"
        borderBottomLeftRadius={{ base: '2xl', md: 'none' }}
        borderTopRightRadius={{ base: 'none', md: '2xl' }}
        borderBottomRightRadius={{ base: '2xl', md: '2xl' }}
        maxH={{ base: 'calc(100% - 80px)', md: '100%' }}
      >
        <DrawerHeader
          bg="rgb(255 255 255 / 10%)"
          fontWeight={800}
          fontSize={18}
        >
          <IconButton
            aria-label="Menu"
            bg="none"
            icon={<CloseIcon />}
            onClick={handleBackClick}
          />
        </DrawerHeader>
        <DrawerBody px="30px" pt="0" pb={8}>
          <ApplicationNavigation
            filteredPages={filteredPages}
            onClose={onClose}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
