"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FLOOR_PLANS = void 0;
exports.FLOOR_PLANS = [
    {
        id: 1129142,
        cid: 17495,
        property_id: 1082542,
        unit_kind_id: 0,
        name: 'Studio',
        description: '',
        min_rent: 534,
        max_rent: 534,
        min_deposit: 0,
        max_deposit: 0,
        min_square_feet: 0,
        max_square_feet: 0,
        total_units: 0,
        total_available_units: 10,
        total_floors: 0,
        total_rooms: 0,
        total_bedrooms: 1,
        total_bathrooms: 1,
        bedroom_description: '',
        bathroom_description: '',
        is_cats_allowed: false,
        is_dogs_allowed: false,
        has_space_configuration_terms: false,
        is_published: true,
        media_storage_reference: null,
        media_thumbnailer_path: null,
        media_fullsize_uri: null,
        details: {
            floorplan_marketing_media_association: {},
        },
        medias: {
            media_storage_reference: '17495/MLv3/4/22/2022/3/8/9845/606d91a845ae57.75632018890.jpg',
            media_thumbnailer_path: 'http://common.vagrant.entrata.lcl/images/?src=17495/MLv3/4/22/2022/3/8/9845/606d91a845ae57.75632018890.jpg&w=220&h=150',
            media_fullsize_uri: 'http://medialibrarycf.entratadev.com/17495/MLv3/4/22/2022/3/8/9845/606d91a845ae57.75632018890.jpg',
            media_caption: 'images',
        },
    },
    {
        id: 1129144,
        cid: 17495,
        property_id: 1082542,
        unit_kind_id: 0,
        name: '1BHK',
        description: '',
        min_rent: 0,
        max_rent: 0,
        min_deposit: 0,
        max_deposit: 0,
        min_square_feet: 0,
        max_square_feet: 0,
        total_units: 0,
        total_available_units: 9,
        total_floors: 0,
        total_rooms: 0,
        total_bedrooms: 1,
        total_bathrooms: 1,
        bedroom_description: '',
        bathroom_description: '',
        is_cats_allowed: false,
        is_dogs_allowed: false,
        has_space_configuration_terms: false,
        is_published: true,
        media_storage_reference: null,
        media_thumbnailer_path: null,
        media_fullsize_uri: null,
        details: {
            floorplan_marketing_media_association: {},
        },
        medias: {
            media_storage_reference: '17495/MLv3/4/22/2022/03/06/084642/62258022d34bb2.18523932194.jpg',
            media_thumbnailer_path: 'http://common.vagrant.entrata.lcl/images/?src=17495/MLv3/4/22/2022/03/06/084642/62258022d34bb2.18523932194.jpg&w=220&h=150',
            media_fullsize_uri: 'http://medialibrarycf.entratadev.com/17495/MLv3/4/22/2022/03/06/084642/62258022d34bb2.18523932194.jpg',
            media_caption: 'f',
        },
    },
    {
        id: 1129146,
        cid: 17495,
        property_id: 1082542,
        unit_kind_id: 0,
        name: 'Waitlist',
        description: '',
        min_rent: 0,
        max_rent: 0,
        min_deposit: 0,
        max_deposit: 0,
        min_square_feet: 0,
        max_square_feet: 0,
        total_units: 0,
        total_available_units: 0,
        total_floors: 0,
        total_rooms: 0,
        total_bedrooms: 2,
        total_bathrooms: 2,
        bedroom_description: '',
        bathroom_description: '',
        is_cats_allowed: false,
        is_dogs_allowed: false,
        has_space_configuration_terms: false,
        is_published: true,
        media_storage_reference: null,
        media_thumbnailer_path: null,
        media_fullsize_uri: null,
        details: null,
        medias: [],
    },
    {
        id: 1129148,
        cid: 17495,
        property_id: 1082542,
        unit_kind_id: 0,
        name: '3BHK',
        description: '',
        min_rent: 0,
        max_rent: 0,
        min_deposit: 0,
        max_deposit: 0,
        min_square_feet: 0,
        max_square_feet: 0,
        total_units: 0,
        total_available_units: 10,
        total_floors: 0,
        total_rooms: 0,
        total_bedrooms: 3,
        total_bathrooms: 3,
        bedroom_description: '',
        bathroom_description: '',
        is_cats_allowed: false,
        is_dogs_allowed: false,
        has_space_configuration_terms: false,
        is_published: true,
        media_storage_reference: null,
        media_thumbnailer_path: null,
        media_fullsize_uri: null,
        details: null,
        medias: [],
    },
    {
        id: 1129150,
        cid: 17495,
        property_id: 1082542,
        unit_kind_id: 0,
        name: '2BHK',
        description: '',
        min_rent: 0,
        max_rent: 0,
        min_deposit: 0,
        max_deposit: 0,
        min_square_feet: 0,
        max_square_feet: 0,
        total_units: 0,
        total_available_units: 10,
        total_floors: 0,
        total_rooms: 0,
        total_bedrooms: 2,
        total_bathrooms: 2,
        bedroom_description: '',
        bathroom_description: '',
        is_cats_allowed: false,
        is_dogs_allowed: false,
        has_space_configuration_terms: false,
        is_published: true,
        media_storage_reference: null,
        media_thumbnailer_path: null,
        media_fullsize_uri: null,
        details: null,
        medias: [],
    },
];
