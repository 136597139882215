"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PROPERTIES = void 0;
exports.PROPERTIES = [
    { id: 439, name: 'Kensington Manor Apartments' },
    { id: 1114710, name: 'Amol Parent Property' },
    { id: 1114711, name: 'CULKER Test Property' },
    { id: 1110164, name: 'Test_Affordable_Regression' },
    { id: 1081507, name: 'Application Team [Rapid]' },
    { id: 1131353, name: 'Entrata_live28' },
    { id: 1114655, name: 'MediaTest' },
    { id: 1133805, name: 'Regression17thAug' },
    { id: 1132926, name: 'RecurringPayment1' },
    { id: 1126041, name: 'Application Dates' },
    { id: 1131345, name: 'Regression28thJuly' },
    { id: 1126089, name: 'PC Parent' },
    { id: 1126091, name: 'PC Child' },
    { id: 1125939, name: 'Live1' },
    { id: 1111528, name: 'Destination_Duplicant_applicant' },
    { id: 1126294, name: 'OfferProperty' },
    { id: 1125971, name: 'RenewalTest' },
    { id: 1125988, name: 'SourceLead' },
    { id: 1129053, name: 'FOr RV' },
    { id: 1115621, name: 'Automation Test' },
    { id: 1113678, name: 'Nilesh N Military' },
    { id: 1143792, name: 'US Property(Military)' },
    { id: 1114697, name: 'Test Data Renewal for Affordable' },
    { id: 1125926, name: '26thMayReg' },
    { id: 1123479, name: 'RepaymentData' },
    { id: 1159959, name: 'Sagar Conventional Property' },
    { id: 1125840, name: 'Poonam Conventional' },
    { id: 1110863, name: 'TestMsg4' },
    { id: 1081880, name: 'Sarika_military' },
    { id: 1166323, name: 'DestinationTest' },
    { id: 1114704, name: 'Amol Conventional Property' },
    { id: 1115548, name: 'Alex_Military' },
    { id: 1155420, name: 'OLA' },
    { id: 1171183, name: '27042022 military' },
    { id: 1081750, name: 'Zuber_Conventional' },
    { id: 1113748, name: 'TransferTest243' },
    { id: 1081774, name: 'Alex_Conventional' },
    { id: 1100828, name: 'AssetVerification' },
    { id: 1081521, name: 'Conventional_Saurabh' },
    { id: 1143790, name: 'US Property(Conventional)' },
    { id: 1122864, name: 'SG Child Property' },
    { id: 1122862, name: 'SG Parent Property' },
    { id: 1122870, name: 'SG Military Property' },
    { id: 1082542, name: 'Vaarahi Apartment' },
    { id: 1083958, name: 'Parent Property' },
    { id: 1143796, name: 'US Property(Affordable)' },
    { id: 1122860, name: 'SG Property' },
    { id: 1112653, name: 'Test_Regression_17032021' },
    { id: 1081529, name: 'Test Harshad (Do not Touch)' },
    { id: 1083029, name: 'Nilesh N Conventional' },
    { id: 1083960, name: 'Child Property' },
    { id: 1083962, name: 'Student Property' },
    { id: 1087610, name: 'Ajay Conventional Property' },
    { id: 1091637, name: 'migrationss' },
    { id: 1103558, name: 'WithTest612020' },
    { id: 1103544, name: 'Migration Test' },
];
