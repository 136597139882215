/* eslint-disable no-param-reassign */
import { Applicant, defaultApplicant } from '@common/entities';
import { ApplicantApplicationResponse } from '@common/entities/src/applicant-application-response.entity';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetStoreAction } from 'state/commonActions';

import type { Form } from 'types/form';
import { getActiveResponse } from 'utils/getActiveResponse';

export type ApplicationReducer = {
  applicant: Applicant;
  form: Form;
  activeResponse: ApplicantApplicationResponse;
};

export const defaultFormState: Form = {
  id: '',
  name: '',
  description: '',
  type: '',
  clientId: 0,
  pages: [],
  occupancyType: '',
};

export const defaultActiveResponse: any = {
  responseId: undefined,
  versionId: undefined,
  applicationId: undefined,
  entrataApplicationId: undefined,
  leaseId: undefined,
  entrataUserId: undefined,
  relationshipType: undefined,
};

const initialState: ApplicationReducer = {
  applicant: {
    ...defaultApplicant,
    responses: defaultApplicant.responses || [],
  },
  form: defaultFormState,
  activeResponse: defaultActiveResponse,
};

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setApplicationData: (
      state,
      action: PayloadAction<{
        applicant: Applicant;
        form: Form;
        activeResponseId?: string;
      }>,
    ) => {
      const { applicant, form, activeResponseId } = action.payload;
      if (activeResponseId) {
        return {
          ...state,
          applicant,
          form,
          activeResponse: getActiveResponse(applicant, activeResponseId),
        };
      }
      return { ...state, applicant, form };
    },
    setActiveResponse: (state, action: PayloadAction<string>) => {
      state.activeResponse = getActiveResponse(state.applicant, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStoreAction, () => initialState);
  },
});

export const { setApplicationData, setActiveResponse } =
  applicationSlice.actions;
export default applicationSlice.reducer;
