"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CURRENT_EMPLOYER_FIELDS = void 0;
var entities_1 = require("@common/entities");
var EMPLOYER_FIELDS_1 = require("./EMPLOYER_FIELDS");
exports.CURRENT_EMPLOYER_FIELDS = {
    name: 'current_employer',
    label: 'Current Employer',
    customType: entities_1.IncomeTypes.CurrentEmployer,
    elementType: entities_1.SectionElementType.Block,
    paymentInterval: entities_1.Interval.Monthly,
    documentUpload: {
        maxFilesAllowed: '2',
        verifyDocumentUpload: true,
    },
    fields: EMPLOYER_FIELDS_1.EMPLOYER_FIELDS,
};
