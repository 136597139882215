import { ApplicantContext } from '@common/entities';
import { get, post } from 'config/api/config';

const GET_CO_APPLICANTS_ENDPOINT = '/viewer/co-applicants';
const GET_GUARANTORS_ENDPOINT = '/viewer/guarantors';
const SIGNUP_SECONDARY_APPLICANT_ENDPOINT = '/viewer/signup/secondaryapplicant';
const INVITE_SECONDARY_APPLICANT = '/viewer/applicant/invite';

export interface SecondaryApplicantData {
  applicationId: string;
  orgId: number;
  propertyId: number;
  relationshipType: string;
  customerRelationshipId: number;
  primaryApplicationResponseId: string;
  applicant: {
    firstName: string;
    middleName?: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    birthDate?: string;
  };
  context?: ApplicantContext;
}

export interface InviteSecondaryApplicant {
  orgId: number;
  applicationId: string;
  propertyId: string | number;
  formVersionId: string;
  emailAddress: string;
}

export const getCoApplicants = async (responseId: string) => {
  if (!responseId) throw new Error('Missing parameter.');
  const response = await get(`${GET_CO_APPLICANTS_ENDPOINT}/${responseId}`);
  return response;
};

export const getGuarantors = async (responseId: string) => {
  if (!responseId) throw new Error('Missing parameter.');
  const response = await get(`${GET_GUARANTORS_ENDPOINT}/${responseId}`);
  return response;
};

export const signupSecondaryApplicant = async (
  applicantData: SecondaryApplicantData,
) => {
  const response = await post(
    SIGNUP_SECONDARY_APPLICANT_ENDPOINT,
    applicantData,
  );
  return response;
};

export const postInviteSecondaryApplicant = async (
  data: InviteSecondaryApplicant,
): Promise<any> => {
  const response = await post(INVITE_SECONDARY_APPLICANT, data);
  return response;
};

export const checkRequiredFieldsForSecondaryAccount = (
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  customerRelationshipId: number,
) => {
  // First Name, Last Name, Phone Number, Email are required for account creation
  const missingFields: string[] = [];
  if (!firstName || firstName.length === 0) missingFields.push('First Name');
  if (!lastName || lastName.length === 0) missingFields.push('Last Name');
  if (!email || email.length === 0) missingFields.push('Email');
  if (!phoneNumber || phoneNumber.length === 0)
    missingFields.push('Phone Number');
  if (!customerRelationshipId || customerRelationshipId < 1)
    missingFields.push('Relationship To Applicant');
  if (missingFields.length > 0) {
    return `Please fill out the following fields: ${missingFields.join(', ')}`;
  }
  return '';
};
