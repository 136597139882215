/* istanbul ignore file */
import React from 'react';

import { ChargesAccordionSection } from './ChargesAccordionSection';

export interface SubCharge {
  label: string;
  priceNumber: number;
  priceFormatted?: string;
  recurringFrequency?: string;
  currencyCode?: string;
}

interface Props {
  recurringCharges: SubCharge[];
  dueNow: SubCharge[];
  dueAtMoveIn: SubCharge[];
}

export function ChargesSection({
  recurringCharges,
  dueNow,
  dueAtMoveIn,
}: Props) {
  return (
    <>
      <ChargesAccordionSection
        key="shopping_cart_recurring"
        title="Recurring Charges"
        charges={recurringCharges}
        isRecurring
      />
      <ChargesAccordionSection
        key="shopping_cart_duenow"
        title="Due Now"
        charges={dueNow}
      />
      <ChargesAccordionSection
        key="shopping_cart_dueatmovein"
        title="Due At Move-in"
        charges={dueAtMoveIn}
      />
    </>
  );
}
