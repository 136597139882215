import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { Special, SpecialGroup } from '@common/entities';

interface Props {
  modalTitle: string;
  isOpen: boolean;
  onClose: () => void;
  specialGroup: SpecialGroup;
  selectedSpecials: Special[];
  handleSave: (specials: Special[], specialGroup: SpecialGroup) => void;
}
export function SpecialsModal(props: Props) {
  const {
    specialGroup,
    isOpen,
    modalTitle,
    onClose,
    selectedSpecials = [],
    handleSave,
  } = props;

  const [specials, setSpecials] = useState<Special[]>([...selectedSpecials]);

  function handleSpecialSelection(
    e: React.ChangeEvent<HTMLInputElement>,
    element: Special,
  ) {
    const isSelected = e.target.checked;

    if (isSelected) {
      setSpecials((prevState) => [...prevState, element]);
    } else {
      setSpecials((prevState) => prevState.filter((i) => i.specialId !== element.specialId));
    }
  }

  useEffect(() => {
    setSpecials(selectedSpecials);
  }, [selectedSpecials]);

  function handleSaveSpecials() {
    handleSave(specials, specialGroup);
  }
  
  const selectedIds = specials?.map((i) => i.specialId);
  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      closeOnOverlayClick={false}
    >
      <ModalOverlay
        bg="black.200"
        backdropFilter="blur(12px) hue-rotate(0deg)"
      />
      <ModalContent background="white.base" borderRadius="8px">
        <ModalHeader
          background="blue.base"
          color="white.base"
          p={2}
          fontSize={16}
          borderTopLeftRadius="8px"
          borderTopRightRadius="8px"
        >
          {modalTitle}
        </ModalHeader>
        <ModalCloseButton color="white.base" />
        <ModalBody minHeight={100}>
          <Box mt={5}>
            <Heading as="h5" size="sm">
              {specialGroup?.groupName}
            </Heading>
            <Text fontSize="xs" marginTop={5}>
              {specialGroup?.description} 
            </Text>
            <Tooltip
              label={<Text whiteSpace="pre-line">{specialGroup?.termsAndConditions}</Text>}
              placement='top'
            >
              <Text textDecoration='underline' color='blue' fontSize="xs" marginTop={5}>
                Terms & Conditions
              </Text>
            </Tooltip>  
            <Text fontSize="xs" marginTop={5}>
              Please select <b>{specialGroup?.specialLimit}</b> of the following options:
            </Text>           
            <Box mt={5}>
              {specialGroup?.specials?.map((element) => {
                return (
                  <Flex
                    key={`element-list-${element.specialId}`}
                    justifyContent="space-between"
                    my={2}
                  >               
                    <Checkbox
                      size="sm"
                      isChecked={selectedIds.includes(element.specialId)}
                      onChange={(e) => handleSpecialSelection(e, element)}
                      isDisabled={Number(specialGroup?.specialLimit) === specials.length && !selectedIds.includes(element.specialId)}
                    >
                      {element.specialName}
                    </Checkbox>
                  </Flex>
                );
              })}
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Button
            size="sm"
            bg="silver.highlighthalf"
            color="black.dark"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button size="sm" variant="brand" onClick={handleSaveSpecials}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
