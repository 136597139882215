import { useSelector } from 'react-redux';
import { RootState } from 'state/store';

import type { CardInfo, PaymentType } from 'types/payments';

interface CardFee {
  fee: number | undefined;
  internationalFee: number | undefined;
  commercialFee: number | undefined;
}
export function useGetCardChargesDue() {
  const { paymentTypes, paymentInfo } = useSelector((state: RootState) => {
    return state?.payment;
  });

  function calculateCardCharges(
    fees: CardFee,
    isCommercial: boolean,
    isInternational: boolean,
  ) {
    const { fee = 0, internationalFee = 0, commercialFee = 0 } = fees;

    let cardCharges = fee;
    if (isCommercial.toString() === 'true') cardCharges += internationalFee;
    if (isInternational.toString() === 'true') cardCharges += commercialFee;
    return cardCharges;
  }

  function getCreditCardCharges(cardInfo: CardInfo, type: PaymentType) {
    const {
      is_international_card: isInternationalCard,
      is_commercial_card: isCommercialCard,
    } = cardInfo;

    const { fee, internationalFee, commercialFee } = type;

    return calculateCardCharges(
      {
        fee,
        internationalFee,
        commercialFee,
      },
      isCommercialCard,
      isInternationalCard,
    );
  }

  function getDebitCardCharges(cardInfo: CardInfo, type: PaymentType) {
    const {
      is_international_card: isInternationalCard,
      is_commercial_card: isCommercialCard,
    } = cardInfo;

    const feeObj = {
      fee: type.debitFee,
      internationalFee: type.internationalDebitFee,
      commercialFee: type.commercialDebitFee,
    };

    return calculateCardCharges(feeObj, isCommercialCard, isInternationalCard);
  }

  function getCardChargeDue() {
    if (!paymentInfo) return 0;
    if (!Object.keys(paymentInfo).length) return 0;

    const { card_number: cardNumber } = paymentInfo;

    if (!cardNumber) return 0;

    let cardCharges = 0;
    const {
      is_debit_card: isDebitCard,
      is_credit_card: isCreditCard,
      payment_type_id: paymentTypeId,
    } = cardNumber;

    const selectedType = paymentTypes.filter((i) => i.id === paymentTypeId);
    if (!selectedType.length) return 0;

    if (isCreditCard) {
      cardCharges = getCreditCardCharges(cardNumber, selectedType[0]);
    }
    if (isDebitCard) {
      cardCharges = getDebitCardCharges(cardNumber, selectedType[0]);
    }
    return cardCharges;
  }

  return getCardChargeDue;
}
