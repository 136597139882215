"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HOUSED_DECLARED_DISASTER_FIELD = void 0;
var entities_1 = require("@common/entities");
var constants_1 = require("../../constants");
exports.HOUSED_DECLARED_DISASTER_FIELD = [
    {
        label: 'Are you housed temporarily due to Presidentially Declared Disaster?',
        type: entities_1.FieldType.YesNo,
        customType: entities_1.FieldType.YesNo,
        sequence: 1,
        name: 'housed_declared_disaster',
        required: false,
        enabled: true,
        options: constants_1.YES_NO_OPTIONS,
    },
];
