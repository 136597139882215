"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var styles = {
    global: {
        'html, body, #root': {
            height: '100%',
            margin: '0',
        },
        '.form-viewer': {
            bg: '#FDFDFD',
        },
        '.form-builder': {
            bg: 'gray.highlighthalf',
        },
    },
};
exports.default = styles;
