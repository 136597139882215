"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Property = void 0;
var Property = /** @class */ (function () {
    function Property() {
        this.propertyId = 0;
        this.propertyName = '';
    }
    return Property;
}());
exports.Property = Property;
