import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { BlockType } from '@common/entities';
import { RootState } from 'state/store';

import { getPageWiseFields } from '../utils/steps';

import {
  useActiveStepIndexFromRedux,
  useGetActiveStepIndex,
} from './useGetActiveStepIndex';

export const useGetCurrentPageData = () => {
  const { getValues } = useFormContext();

  const { form } = useSelector((state: RootState) => state.application);
  const { pages } = form;

  const getActiveStepIndex = useGetActiveStepIndex();
  const activeStepIndexRedux = useActiveStepIndexFromRedux();

  const pageWiseFields = React.useMemo(() => {
    return pages.map(({ sections, type }: any) => {
      return getPageWiseFields(sections, type);
    });
  }, [pages]);

  /**
   * Get single fields form value with name
   */
  function getSingleFieldValue(fields: string[]) {
    if (!fields?.length) return [];

    const applicantData: { [key: string]: string } = {};

    fields.forEach((field) => {
      applicantData[field] = getValues(field) ?? '';
    });
    return [{ applicantData }];
  }

  /**
   * Get block field form values
   * @param fields
   * @returns
   */
  function getBlockFieldsValue(fields: string[]) {
    if (!fields?.length) return [];

    const returnData: { [key: string]: any }[] = [];

    fields.forEach((field) => {
      switch (field) {
        case BlockType.Income:
          returnData.push({
            incomeDetails: getValues('incomeDetails'),
          });
          break;
        case BlockType.Asset:
          returnData.push({
            assetDetails: getValues('assetDetails'),
          });
          break;
        case BlockType.Pet:
          returnData.push({ pet: getValues('pet') });
          break;
        case BlockType.Coapplicant:
          returnData.push({
            coApplicant: getValues('coApplicant'),
          });
          break;
        case BlockType.Vehicle:
          returnData.push({
            vehicleDetails: getValues('vehicleDetails'),
          });
          break;
        case BlockType.IdentificationType:
          returnData.push({
            identification: getValues('identification'),
          });
          break;
        case BlockType.Guarantor:
          returnData.push({
            guarantor: getValues('guarantor'),
          });
          break;
        case BlockType.AddressCurrent:
          returnData.push({
            currentAddress: getValues('currentAddress'),
          });
          break;
        case BlockType.AddressPrevious:
          returnData.push({
            previousAddress: getValues('previousAddress'),
          });
          break;
        case BlockType.GeneralQuestion:
          returnData.push({
            generalQuestions: getValues('generalQuestions'),
          });
          break;
        case BlockType.Disability:
          returnData.push({
            disability: getValues('disability'),
          });
          break;
        case BlockType.Student:
          returnData.push({
            student: getValues('student'),
          });
          break;
        case BlockType.HousedDeclaredDisaster:
          returnData.push({
            housedDeclaredDisaster: getValues('housedDeclaredDisaster'),
          });
          break;
        case BlockType.HouseholdFleeingDomesticViolence:
          returnData.push({
            householdFleeingDomesticViolence: getValues(
              'householdFleeingDomesticViolence',
            ),
          });
          break;
        case BlockType.MilitaryVeteran:
          returnData.push({
            militaryVeteran: getValues('militaryVeteran'),
          });
          break;
        case BlockType.PoliceSecurityOfficer:
          returnData.push({
            policeSecurityOfficer: getValues('policeSecurityOfficer'),
          });
          break;
        default:
          break;
      }
    });
    return returnData;
  }

  /**
   * Prepare the data for sending it to backend
   * @returns
   */
  const prepareData = () => {
    const activeStepIndex =
      activeStepIndexRedux >= 0 ? activeStepIndexRedux : getActiveStepIndex();

    const singleField = getSingleFieldValue(
      pageWiseFields[activeStepIndex].singleFields,
    );

    const blockField = getBlockFieldsValue(
      pageWiseFields[activeStepIndex].blockFields,
    );

    return [...singleField, ...blockField];
  };

  return prepareData;
};
