"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProspectDocument = void 0;
var enums_1 = require("./enums");
var ProspectDocument = /** @class */ (function () {
    function ProspectDocument() {
        this.fileAssociationId = 0;
        this.id = 0;
        this.name = '';
        this.isSigned = false;
        this.requiresSigning = false;
        this.signingProcedure = enums_1.DocumentSigningProcedure.SinglePage;
    }
    return ProspectDocument;
}());
exports.ProspectDocument = ProspectDocument;
