"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Application = void 0;
var Application = /** @class */ (function () {
    function Application() {
        this.name = '';
        this.description = '';
        this.occupancyType = '';
        this.clientId = 0;
    }
    return Application;
}());
exports.Application = Application;
