/* istanbul ignore file */
import React from 'react';
import styled from '@emotion/styled';

interface Props {
  label: string;
  priceFormatted?: string;
  priceNumber: number;
  currencyCode?: string;
  total?: boolean;
}

function CartItem({
  label,
  priceFormatted,
  priceNumber,
  currencyCode,
  total = false,
}: Props) {
  let priceToDisplay = priceFormatted;
  // As of 2/20/24, the formatted price is not correct, see https://entrata.slack.com/archives/C062N6CBD61/p1708453746146959?thread_ts=1708449814.504279&cid=C062N6CBD61
  // For now, we will always format the price using the currency code and not rely on the formatted price.
  // if (!priceFormatted) {
  if (!currencyCode || currencyCode === '') {
    priceToDisplay = priceNumber.toFixed(2);
  } else {
    const formatter = new Intl.NumberFormat(navigator.language, {
      style: 'currency',
      currency: currencyCode,
    });
    priceToDisplay = formatter.format(priceNumber);
  }
  // }

  return (
    <Item total={total}>
      <Label>{label}</Label>
      <DottedArea />
      <Price>{priceToDisplay}</Price>
    </Item>
  );
}

export default CartItem;

const Label = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const DottedArea = styled.div`
  width: 100%;
  flex-grow: 1;
  border-bottom: 2px dotted ${({ theme }: any) => theme.colors.gray.base};
  content: '';
  margin: 5px;
`;

const Item = styled.div<{
  total: boolean;
}>`
  display: flex;
  justify-content: space-between;
  padding: 2px 0;
  font-size: 13px;
  font-style: normal;
  font-weight: normal;
  color: ${({ theme }: any) => theme.colors.gray.drakest};
  ${({ total }) => total && ' font-weight: bold;'}
`;

const Price = styled.div`
  flex-shrink: 0;
`;
