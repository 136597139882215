"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormSetting = void 0;
var FormSetting = /** @class */ (function () {
    function FormSetting() {
        this.name = '';
        this.value = '';
        this.object_id = '';
    }
    return FormSetting;
}());
exports.FormSetting = FormSetting;
