import { get } from 'config/api/config';

const THEME_COLORS = '/viewer/theme/website-theme-colors';
const WEBSITE_MEDIA = '/viewer/theme/website-media';

type BrandRequestParams = {
  websiteId: number;
  domain: string;
  releaseTrack: string;
};

export const getBrandingColors = async (data: BrandRequestParams) => {
  const { websiteId, domain, releaseTrack } = data;
  const response = await get(THEME_COLORS, {
    params: {
      websiteId,
      domain,
      releaseTrack,
    },
  });
  return response;
};

export const getBrandingMedia = async (data: BrandRequestParams) => {
  const { websiteId, domain, releaseTrack } = data;
  const response = await get(WEBSITE_MEDIA, {
    params: {
      websiteId,
      domain,
      releaseTrack,
    },
  });
  return response;
};
