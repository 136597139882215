import { useDispatch } from 'react-redux';
import {
  PropertySetting,
  setPropertySettings,
} from 'state/slices/propertySettingsSlice';

import { getPropertySettings } from 'services';

export function useGetPropertySettings() {
  const dispatch = useDispatch();
  async function initializePropertySettings(propertyId: number) {
    const { data: propertySettings } = await getPropertySettings(propertyId);

    if (propertySettings) {
      const settings: PropertySetting[] = [];
      propertySettings.forEach((setting: any) => {
        settings.push({
          settingType: setting.key,
          value: setting.selected_value,
        });
      });
      dispatch(setPropertySettings(settings));
    }
  }

  return initializePropertySettings;
}
