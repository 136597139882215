"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SAVINGS_ACCOUNT_FIELDS = void 0;
var entities_1 = require("@common/entities");
var ASSET_FIELDS_1 = require("./ASSET_FIELDS");
exports.SAVINGS_ACCOUNT_FIELDS = {
    name: 'savings-account',
    label: 'Savings Account',
    customType: entities_1.AssetTypes.SavingsAccount,
    elementType: entities_1.SectionElementType.Block,
    documentUpload: {
        maxFilesAllowed: '2',
        verifyDocumentUpload: true,
    },
    fields: (0, ASSET_FIELDS_1.getAssetFields)('savings_accounts'),
};
