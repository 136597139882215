import { get } from 'config/api/config';

import { FloorPlan, Unit, UnitAmenities } from 'types/floorplan-page-types';

const PROPERTIES_ENDPOINT = '/viewer/properties';
const PROPERTY_SETTINGS_ENDPOINT = '/viewer/propertySettings';
const PROPERTY_OPTIONS_ENDPOINT = '/viewer/property-addons';

interface PropertySettingsResult {
  key: string;
  label: string;
  default_value: any;
  selected_value: boolean;
}
/**
 * To GET property settings
 * @returns promise
 */
export const getPropertySettings = async (propertyId: number) => {
  if (!propertyId) throw new Error('Property Id is missing.');

  const response = await get<PropertySettingsResult[]>(
    `${PROPERTY_SETTINGS_ENDPOINT}/${propertyId}`,
  );
  return response;
};

/**
 * To GET floor plans
 * @param {string} moveInDate
 * @returns promise
 */
export const getFloorPlans = async (propertyId: number, moveInDate: string) => {
  if (!propertyId || !moveInDate) throw new Error('Missing parameter.');

  const response = await get<FloorPlan[]>(
    `${PROPERTIES_ENDPOINT}/${propertyId}/floor-plans`,
    { params: { moveInDate } },
  );
  return response?.data;
};

/**
 * To GET available units
 * @param {string} floorPlanId
 * @returns promise
 */
export const getAvailableUnitsAndLeaseTerm = async (
  propertyId: number,
  floorPlanId: number,
  moveInDate: string,
  leaseId?: number,
) => {
  if (!propertyId || !floorPlanId || !moveInDate) {
    throw new Error('A parameter is missing while fetching units.');
  }
  const params: any = { moveInDate };
  if (leaseId) {
    params.leaseId = leaseId;
  }
  const URL = `${PROPERTIES_ENDPOINT}/${propertyId}/floor-plans/${floorPlanId}/available-units`;
  const response = await get<Unit[]>(URL, { params: { moveInDate, leaseId } });
  return response?.data;
};

/**
 * To GET reserved unit
 * @returns promise
 */
export const getReservedUnitForApplicant = async (propertyId: number) => {
  if (!propertyId) throw new Error('Missing parameter.');

  const response = await get<Unit>(
    `${PROPERTIES_ENDPOINT}/${propertyId}/reserved-unit`,
  );
  return response;
};

/**
 * To GET add-ons
 * @returns promise
 */
export const getAddOns = async (propertyId: number, moveInDate: string) => {
  if (!propertyId || !moveInDate) throw new Error('Missing parameter.');

  const response = await get(`${PROPERTY_OPTIONS_ENDPOINT}/${propertyId}`, {
    params: { moveInDate },
  });
  return response;
};

/**
 * To GET amenities
 * @param {string} floorPlanId
 * @returns promise
 */
export const getFloorPlanAmenities = async (
  propertyId: number,
  floorPlanId: number,
) => {
  if (!propertyId || !floorPlanId) throw new Error('Missing parameter.');

  const response = await get<UnitAmenities[]>(
    `${PROPERTIES_ENDPOINT}/${propertyId}/floor-plans/${floorPlanId}/amenities`,
  );
  return response?.data;
};

