import { get } from 'config/api/config';

const OCCUPATIONS_ENDPOINT = '/viewer/occupations';
const PET_TYPE_ENDPOINT = '/viewer/pet-types';
const RESIDENT_TYPE_ENDPOINT = '/viewer/resident-types';
const LEAVING_REASON_ENDPOINT = '/viewer/reason-for-leaving';
const CUSTOMER_RELATIONSHIPS = '/viewer/customer-relationships';
const PREVIOUS_HOUSING_ARRANGEMENTS = '/viewer/previous-housing-arrangements';
const PREVIOUS_SUBSIDY_TYPES = '/viewer/previous-subsidy-types';
const DISPLACEMENT_STATUSES = '/viewer/displacement-statuses';
const INCOME_EXCEPTIONS = '/viewer/income-exceptions';
const STUDENT_EXCEPTIONS = '/viewer/student-exceptions';
const TITLE = '/viewer/title';
const ETHNICITY = '/viewer/ethnicity';
const RACE = '/viewer/race';
const GENDER = '/viewer/gender';

/**
 * GET occupations pick list
 * @returns promise
 */
export const getOccupations = async (propertyId: number) => {
  if (!propertyId) throw new Error('Property Id is missing.');

  const response = await get(`${OCCUPATIONS_ENDPOINT}/${propertyId}`);
  return response;
};

interface PetTypeResponse {
  id: string;
  name: string;
  description: string;
  monthlyRate: number;
  deposit: number;
}
/**
 * GET pet type pick list
 * @returns promise
 */
export const getPetType = async (propertyId: number) => {
  if (!propertyId) throw new Error('Missing parameter.');

  const response = await get<PetTypeResponse[]>(
    `${PET_TYPE_ENDPOINT}/${propertyId}`,
  );
  return response;
};

export interface ResidentTypeResponse {
  description: string;
  id: number;
  is_published: number;
  name: string;
  order_num: number;
}
/**
 * GET pet type pick list
 * @returns promise
 */
export const getResidentTypes = async () => {
  const response = await get<ResidentTypeResponse[]>(RESIDENT_TYPE_ENDPOINT);
  return response?.data;
};

interface LeavingReasonResponse {
  default_list_item_id: number;
  default_list_item_name: any;
  is_system: boolean;
  list_type_id: number;
  list_type_name: string;
  mapping_id: any;
  name: string;
  order_num: number;
  org_id: number;
  remote_primary_key: any;
  show_in_entrata: boolean;
  show_on_website: boolean;
}
/**
 * GET pet type pick list
 * @returns promise
 */
export const getReasonForLeaving = async () => {
  const response = await get<LeavingReasonResponse[]>(LEAVING_REASON_ENDPOINT);
  return response?.data;
};

/**
 * GET Customer Relationships pick list
 * @returns promise
 */
export const getCustomerRelationships = async () => {
  const response = await get(CUSTOMER_RELATIONSHIPS);
  return response;
};

/**
 * GET Previous Housing Arrangements pick list
 * @returns promise
 */
export const getPreviousHousingArrangements = async () => {
  const response = await get(PREVIOUS_HOUSING_ARRANGEMENTS);
  return response;
};

/**
 * GET Previous Subsidy Types pick list
 * @returns promise
 */
export const getPreviousSubsidyTypes = async () => {
  const response = await get(PREVIOUS_SUBSIDY_TYPES);
  return response;
};

/**
 * GET Displacement Statuses pick list
 * @returns promise
 */
export const getDisplacementStatuses = async () => {
  const response = await get(DISPLACEMENT_STATUSES);
  return response;
};

/**
 * GET Income Exceptions pick list
 * @returns promise
 */
export const getIncomeExceptions = async () => {
  const response = await get(INCOME_EXCEPTIONS);
  return response;
};

/**
 * GET Student Exceptions pick list
 * @returns promise
 */
export const getStudentExceptions = async () => {
  const response = await get(STUDENT_EXCEPTIONS);
  return response;
};

/**
 * GET Title pick list
 * @returns promise
 */
export const getTitle = async () => {
  const response = await get(TITLE);
  return response;
};

/**
 * GET Ethnicity pick list
 * @returns promise
 */
export const getEthnicity = async () => {
  const response = await get(ETHNICITY);
  return response;
};

/**
 * GET Race and Sub-race pick list
 * @returns promise
 */
export const getRace = async () => {
  const response = await get(RACE);
  return response;
};

/**
 * GET Gender pick list
 * @returns promise
 */
export const getGender = async () => {
  const response = await get(GENDER);
  return response;
};
