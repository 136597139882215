/* istanbul ignore file */
import * as React from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Center,
  Flex,
  Heading,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';

import { getPropertyApplications } from 'services';

export interface Version {
  updatedDate: string;
}

export interface PropertyApplication {
  id: string;
  name: string;
  description: string;
  versions: Version[];
}

interface Props {
  applicationSelected: any;
  setSelectedApplicationId: (id: string) => void;
  currentApplicationId?: string;
}
export function SelectApplication(props: Props) {
  const {
    applicationSelected,
    setSelectedApplicationId,
    currentApplicationId,
  } = props;

  const { propertyId } = useParams();

  const { data: applications, isPending } = useQuery({
    queryKey: ['select-application-property-applications'],
    queryFn: () => getPropertyApplications(Number(propertyId)),
  });

  const sortedApplications = applications?.data.sort((a: PropertyApplication, b: PropertyApplication) => {
    const mostRecentDateA = a.versions.reduce((prevDate, currVersion) => {
      const currentDate = new Date(currVersion.updatedDate);
      return currentDate > prevDate ? currentDate : prevDate;
    }, new Date(0));

    const mostRecentDateB = b.versions.reduce((prevDate, currVersion) => {
      const currentDate = new Date(currVersion.updatedDate);
      return currentDate > prevDate ? currentDate : prevDate;
    }, new Date(0));

    return mostRecentDateB.getTime() - mostRecentDateA.getTime();
  });

  if (isPending) return <Spinner />;

  return (
    <>
      <Heading size="lg" marginTop={5} marginBottom={5}>
        Please select an application type
      </Heading>
      <RadioGroup
        onChange={setSelectedApplicationId}
        value={currentApplicationId}
      >
        <Stack>
          {sortedApplications?.map((application: any) => (
            <Flex
              key={`select-application-${application.id}`}
              direction="column"
              marginBottom={3}
            >
              <Radio size="lg" value={application.id}>
                <Text fontSize="md" fontWeight="bold">
                  {application.name}
                </Text>
              </Radio>
              <Text marginLeft={8} size="sm" maxWidth={400}>
                {application.description}
              </Text>
            </Flex>
          ))}
          <Center>
            <Button
              variant="brand"
              marginTop={8}
              size="lg"
              width={300}
              onClick={applicationSelected}
            >
              Continue
            </Button>
          </Center>
        </Stack>
      </RadioGroup>
    </>
  );
}
