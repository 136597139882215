import { useNavigate } from 'react-router-dom';
import { NotificationStatus } from 'enums/notification-status';

import notification from 'components/notification';
import { postNewApplication } from 'services';
import { Applicant } from 'types/applicant';
import { Form } from 'types/form';

import { useInitializeLJApplication } from './useInitializeLJApplication';

export function useCreateNewApplicationForUser() {
  const initializeLJApp = useInitializeLJApplication();
  const navigate = useNavigate();

  const initializeApp = async (
    applicant: Applicant,
    form: Form,
    responseId: string,
  ) => {
    const { form: updatedForm } = await initializeLJApp({
      applicant,
      form,
      responseId,
    });
    return updatedForm;
  };

  async function createNewApplication(
    orgId: number,
    propertyId: number,
    applicationId: string,
    email: string,
  ) {
    try {
      const response = await postNewApplication({
        orgId,
        propertyId,
        applicationId,
        previewFormVersionId: '',
        relationshipType: 'primary',
        applicant: {
          email,
        },
      });

      const { applicant, form } = response.data;

      const updatedForm = await initializeApp(
        applicant,
        form,
        applicant.currentResponseId,
      );

      const currentPageId = updatedForm.pages[0].id;

      notification(
        'New application created successfully',
        NotificationStatus.SUCCESS,
      );

      navigate(`/${orgId}/${propertyId}/${currentPageId}`);
    } catch (err: any) {
      throw new Error(err.message);
    }
  }

  return createNewApplication;
}
