import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { purgeStoredState } from 'redux-persist';
import { resetStoreAction } from 'state/commonActions';
import { persistConfig } from 'state/store';

import { postUserLogout } from 'services';
import handleError from 'utils/handleError';

const useLogout = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orgId, propertyId } = useParams();

  const handleLogout = useCallback(async () => {
    try {
      setIsLoading(true);
      await postUserLogout();
      dispatch(resetStoreAction());
      purgeStoredState(persistConfig);
      sessionStorage.removeItem('viewer.applicant');
      navigate(`/${orgId}/${propertyId}`);
    } catch (error) {
      purgeStoredState(persistConfig);
      sessionStorage.removeItem('viewer.applicant');
      navigate(`/${orgId}/${propertyId}`);
      handleError(
        `Form Viewer - Error during logout: ${error}`,
        `Error during logout`,
      );
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, navigate, orgId, propertyId]);

  return { handleLogout, isLoading };
};

export default useLogout;
