"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Card = {
    baseStyle: {
        container: {
            borderColor: '#ECEDEF',
            borderWidth: '1px',
            backgroundColor: '#FDFDFD',
            borderRadius: '16px',
        },
    },
};
exports.default = Card;
