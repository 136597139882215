"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IDENTIFICATION_CITIZEN_QUESTION = exports.PERSON = exports.ID_SECTION = void 0;
var entities_1 = require("@common/entities");
var SOCIAL_SECURITY_NUMBER_1 = require("./SOCIAL_SECURITY_NUMBER");
var constants_1 = require("../../constants");
exports.ID_SECTION = {
    required: false,
    documentUpload: {
        enabled: false,
        required: true,
        maxFilesAllowed: '2',
        verifyDocumentUpload: true,
    },
    idTypes: [],
};
exports.PERSON = {
    showCitizenQuestion: true,
    primaryId: __assign(__assign({}, exports.ID_SECTION), { idTypes: [SOCIAL_SECURITY_NUMBER_1.SOCIAL_SECURITY_NUMBER] }),
    additionalPrimaryId: exports.ID_SECTION,
    alternateIds: __assign(__assign({}, exports.ID_SECTION), { idsRequired: 0 }),
};
exports.IDENTIFICATION_CITIZEN_QUESTION = [
    {
        name: 'show_citizen_question',
        label: 'Are you a U.S. Citizens?',
        options: constants_1.YES_NO_OPTIONS,
        defaultValue: '',
        type: entities_1.FieldType.Radio,
        customType: entities_1.FieldType.Radio,
        enabled: true,
    },
];
