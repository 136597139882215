"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DISABILITY_FIELDS = void 0;
var entities_1 = require("@common/entities");
var constants_1 = require("../../constants");
exports.DISABILITY_FIELDS = [
    {
        label: 'Are you disabled?',
        type: entities_1.FieldType.YesNo,
        customType: entities_1.FieldType.YesNo,
        sequence: 1,
        name: 'is_disabled',
        required: false,
        enabled: true,
        options: constants_1.YES_NO_OPTIONS,
    },
    {
        label: 'Disability Type',
        type: entities_1.FieldType.ChooseManyDropdown,
        customType: entities_1.FieldType.ChooseManyDropdown,
        sequence: 2,
        name: 'disability_type',
        required: false,
        enabled: true,
        options: constants_1.DISABILITY_TYPE_OPTIONS,
    },
];
