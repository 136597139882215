import { PageType } from '@common/entities';

import { AnyPage } from 'types/page';

export const getPageIndex = (pages: AnyPage[], type: PageType) => {
  if (!Array.isArray(pages)) throw new Error('Invalid page array.');
  if (!type) throw new Error('Type is missing.');

  return pages.findIndex((page) => page.type === type);
};

export const getPage = (pages: AnyPage[], type: PageType) => {
  if (!Array.isArray(pages)) throw new Error('Invalid page array.');
  if (!type) throw new Error('Type is missing.');

  return pages.find((page) => page.type === type);
};
