"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GENERAL_QUESTION_FIELDS = void 0;
var entities_1 = require("@common/entities");
var constants_1 = require("../constants");
exports.GENERAL_QUESTION_FIELDS = [
    {
        label: 'Have you ever been sued for rent?',
        type: entities_1.FieldType.Radio,
        sequence: 1,
        name: 'was_sued_for_rent',
        customType: entities_1.FieldType.Radio,
        required: true,
        enabled: true,
        settingLabel: 'Sued for Rent',
        options: constants_1.YES_NO_OPTIONS,
    },
    {
        label: 'Please provide more details',
        type: entities_1.FieldType.TextArea,
        sequence: 2,
        name: 'sued_for_rent_comments',
        customType: entities_1.FieldType.TextArea,
        required: true,
        enabled: true,
    },
    {
        label: 'Have you ever been sued for damages?',
        type: entities_1.FieldType.Radio,
        sequence: 3,
        name: 'was_sued_for_damages',
        customType: entities_1.FieldType.Radio,
        required: true,
        enabled: true,
        settingLabel: 'Sued for Damages',
        options: constants_1.YES_NO_OPTIONS,
    },
    {
        label: 'Please provide more details',
        type: entities_1.FieldType.TextArea,
        sequence: 4,
        name: 'sued_for_damage_comments',
        customType: entities_1.FieldType.TextArea,
        required: true,
        enabled: true,
    },
    {
        label: 'Have you ever been evicted?',
        type: entities_1.FieldType.Radio,
        sequence: 5,
        name: 'has_been_evicted',
        customType: entities_1.FieldType.Radio,
        required: true,
        enabled: true,
        settingLabel: 'Evicted',
        options: constants_1.YES_NO_OPTIONS,
    },
    {
        label: 'Please provide more details',
        type: entities_1.FieldType.TextArea,
        sequence: 6,
        name: 'evicted_comments',
        customType: entities_1.FieldType.TextArea,
        required: true,
        enabled: true,
    },
    {
        label: 'Have you ever defaulted on a lease?',
        type: entities_1.FieldType.Radio,
        sequence: 7,
        name: 'has_broken_lease',
        customType: entities_1.FieldType.Radio,
        required: true,
        enabled: true,
        settingLabel: 'Defaulted on Lease',
        options: constants_1.YES_NO_OPTIONS,
    },
    {
        label: 'Please provide more details',
        type: entities_1.FieldType.TextArea,
        sequence: 8,
        name: 'broken_lease_comments',
        customType: entities_1.FieldType.TextArea,
        required: true,
        enabled: true,
    },
    {
        label: 'Have you ever been convicted of a felony that involved an offense against property, persons, government officials, or that involved firearms, illegal drugs, or sex or sex crimes?',
        type: entities_1.FieldType.Radio,
        sequence: 9,
        name: 'has_felony_conviction',
        customType: entities_1.FieldType.Radio,
        required: true,
        enabled: true,
        settingLabel: 'Felony',
        options: constants_1.YES_NO_OPTIONS,
    },
    {
        label: 'Please provide more details',
        type: entities_1.FieldType.TextArea,
        sequence: 10,
        name: 'felony_conviction_comments',
        customType: entities_1.FieldType.TextArea,
        required: true,
        enabled: true,
    },
    {
        label: 'Have you ever had any public record suits, liens, judgments or repossessions?',
        type: entities_1.FieldType.Radio,
        sequence: 11,
        name: 'has_public_judgment',
        customType: entities_1.FieldType.Radio,
        required: true,
        enabled: true,
        settingLabel: 'Public Suits',
        options: constants_1.YES_NO_OPTIONS,
    },
    {
        label: 'In what year?',
        type: entities_1.FieldType.Text,
        sequence: 12,
        name: 'public_judgment_year',
        customType: entities_1.FieldType.Text,
        required: true,
        enabled: true,
    },
    {
        label: 'Have you ever filed for bankruptcy protection?',
        type: entities_1.FieldType.Radio,
        sequence: 13,
        name: 'has_bankruptcy',
        customType: entities_1.FieldType.Radio,
        required: true,
        enabled: true,
        settingLabel: 'Bankruptcy',
        options: constants_1.YES_NO_OPTIONS,
    },
    {
        label: 'County Where Filed',
        type: entities_1.FieldType.Text,
        sequence: 14,
        name: 'bankruptcy_county',
        customType: entities_1.FieldType.Text,
        required: true,
        enabled: true,
    },
    {
        label: 'Which Year?',
        type: entities_1.FieldType.Number,
        sequence: 15,
        name: 'bankruptcy_year',
        customType: entities_1.FieldType.Number,
        required: true,
        enabled: true,
    },
    {
        label: 'State/Province Where Filed',
        type: entities_1.FieldType.StateDropdown,
        sequence: 16,
        name: 'bankruptcy_state_code',
        customType: entities_1.FieldType.StateDropdown,
        required: true,
        enabled: true,
    },
    {
        label: "Do you have renter's insurance?",
        type: entities_1.FieldType.Radio,
        sequence: 17,
        name: 'has_renters_insurance',
        customType: entities_1.FieldType.Radio,
        required: true,
        enabled: true,
        settingLabel: 'Renters Insurance',
        options: constants_1.YES_NO_OPTIONS,
    },
    {
        label: 'Provider Name',
        type: entities_1.FieldType.Text,
        sequence: 18,
        name: 'insurance_provider_name',
        customType: entities_1.FieldType.Text,
        required: true,
        enabled: true,
    },
    {
        label: 'Policy Number',
        type: entities_1.FieldType.Text,
        sequence: 19,
        name: 'insurance_policy_number',
        customType: entities_1.FieldType.Text,
        required: true,
        enabled: true,
    },
    {
        label: 'Do you smoke?',
        type: entities_1.FieldType.Radio,
        sequence: 20,
        name: 'smokes',
        customType: entities_1.FieldType.Radio,
        required: true,
        enabled: true,
        settingLabel: 'Smoking',
        options: constants_1.YES_NO_OPTIONS,
    },
];
