"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Checkbox = {
    variants: {
        dark: {
            container: {
                display: 'flex',
                alignItems: 'flex-start',
            },
            control: {
                width: '20px',
                height: '20px',
                borderRadius: '0',
                _checked: {
                    bg: 'black.base',
                    borderColor: 'black.base',
                },
            },
        },
    },
};
exports.default = Checkbox;
