/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { resetStoreAction } from '../commonActions';

type AuthReducer = {
  isAuthenticated: boolean;
  isNewAccount: boolean;
  allowStatusPage: boolean;
  isLeadProfile: boolean;
  isPreviewMode: boolean;
};

const initialState: AuthReducer = {
  isAuthenticated: false,
  isNewAccount: false,
  allowStatusPage: false,
  isLeadProfile: false,
  isPreviewMode: false,
};

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setIsNewAccount: (state, action: PayloadAction<boolean>) => {
      state.isNewAccount = action.payload;
    },
    setAllowStatusPage: (state, action: PayloadAction<boolean>) => {
      state.allowStatusPage = action.payload;
    },
    setIsLeadProfile: (state, action: PayloadAction<boolean>) => {
      state.isLeadProfile = action.payload;
    },
    setIsPreviewMode: (state, action: PayloadAction<boolean>) => {
      state.isPreviewMode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStoreAction, () => initialState);
  },
});

export const {
  setIsAuthenticated,
  setIsNewAccount,
  setAllowStatusPage,
  setIsLeadProfile,
  setIsPreviewMode,
} = authSlice.actions;
export default authSlice.reducer;
