import { EntrataSession } from 'auth/EntrataSession';
import axios from 'axios';

import { BASE_URL } from './URL';

axios.defaults.timeout = 300000;

const apiClient = axios.create({ baseURL: BASE_URL });

apiClient.interceptors.request.use(
  (config: any) => {
    const { headers } = config;
    return {
      ...config,
      withCredentials: true,
      headers: {
        'content-type': 'application/json',
        'x-form-viewer-id': EntrataSession.applicant?.sid
          ? EntrataSession.applicant.sid
          : '',
        ...headers,
      },
    };
  },
  (error) => Promise.reject(error),
);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      EntrataSession.setSessionExpired(true);
      sessionStorage.removeItem('viewer.applicant');
      window.location.href = EntrataSession.appPath || '/';
      return error;
    }
    const serverErrorMessage =
      error?.response?.data?.message ||
      error.message ||
      'An unspecified error occurred.';
    const augmentedError = new Error(serverErrorMessage);

    return Promise.reject(augmentedError);
  },
);

const { get, post, patch, put, delete: destroy } = apiClient;
export { destroy, get, patch, post, put };
