"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultOrganization = exports.Organization = void 0;
var Organization = /** @class */ (function () {
    function Organization() {
        this.id = 0;
    }
    return Organization;
}());
exports.Organization = Organization;
exports.defaultOrganization = {
    id: 0,
};
