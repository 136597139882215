/* istanbul ignore file */
import React from 'react';
import { Box, Center, Heading, Text } from '@chakra-ui/react';

const PageNotFound = () => {
  return (
    <Center height="100vh">
      <Box textAlign="center">
        <Heading as="h1" size="2xl" mb="4">
          404 - Not Found
        </Heading>
        <Text fontSize="lg">The page you are looking for does not exist.</Text>
      </Box>
    </Center>
  );
};

export default PageNotFound;
