import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EntrataApplicationStatus } from '@common/entities';
import { queryClient } from 'AppWrapper';
import { setApplicationData } from 'state/slices/applicationSlice';
import { setIsAuthenticated, setIsPreviewMode } from 'state/slices/authSlice';
import { updateResponseState } from 'state/slices/responseSlice';

import { useInitializeLJApplication } from 'hooks/useInitializeLJApplication';
import { getResponse } from 'services';
import {
  ApplicationStatusResponse,
  getApplicantApplications,
} from 'services/applicant';
import { Applicant } from 'types/applicant';
import { Form } from 'types/form';
import { prepareFormInitializingData } from 'utils/form';

const useAuthRedirection = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const initializeLJApplication = useInitializeLJApplication();

  const isPageIdAvailable = (pageId: string, { pages }: Form) => {
    if (!pageId) return false;
    const isAvailable = pages.findIndex((page) => page.id === pageId);
    return isAvailable >= 0;
  };

  const getPageIdForRedirection = (response: string, form: Form) => {
    const parseResponse = JSON.parse(response);
    const { pages } = parseResponse;
    const activePage = pages.find((page: any) => page.isCurrentPage);
    if (isPageIdAvailable(activePage?.pageId, form)) return activePage.pageId;

    return form.pages[0].id;
  };

  const getLatestApplicantResponse = async (responseId: string) => {
    try {
      const { data } = await queryClient.fetchQuery({
        queryKey: ['applicant-response', responseId],
        queryFn: () => getResponse(responseId),
      });
      return data;
    } catch (e: any) {
      throw new Error(e.message);
    }
  };

  const loadLatestResponse = async (responseIds: string[]) => {
    if (!responseIds?.length) return undefined;
    try {
      const response = await getLatestApplicantResponse(responseIds[0]);
      const formValue = prepareFormInitializingData(response);
      dispatch(updateResponseState(formValue));
      return response?.details;
    } catch (err: any) {
      throw new Error(err.message);
    }
  };

  const getRedirectionRoute = (
    applicantApplications: ApplicationStatusResponse[],
    userResponse: string,
    form: Form,
  ) => {
    const activeApplications = applicantApplications.filter(
      (application) =>
        application.applicationStatus === EntrataApplicationStatus.Started,
    );

    if (activeApplications.length === 1) {
      // Only resume is all other applications are either "cancelled" or "expired"
      const allOtherApplicationsValid = applicantApplications.every(
        (application) =>
          application.applicationStatus === EntrataApplicationStatus.Started ||
          application.applicationStatus ===
            EntrataApplicationStatus.Cancelled ||
          application.applicationStatus === EntrataApplicationStatus.Expired,
      );

      if (allOtherApplicationsValid) {
        const pageId = getPageIdForRedirection(userResponse, form);
        return { route: `${pageId}`, initialization: true };
      }
    }

    // Redirect to the entry page for all other cases
    return { route: 'entry', initialization: false };
  };

  const initializeAppAndRedirect = async (
    applicantApplication: ApplicationStatusResponse[],
    userResponse: string,
    form: Form,
    applicant: Applicant,
    orgId: number,
  ) => {
    const { route, initialization } = getRedirectionRoute(
      applicantApplication,
      userResponse,
      form,
    );

    dispatch(setApplicationData({ applicant, form }));

    const { responseId } = applicant.responses[0];

    if (initialization) {
      await initializeLJApplication({ applicant, form, responseId });
    }

    await Promise.all([
      dispatch(setIsPreviewMode(!initialization)),
      dispatch(setIsAuthenticated(true)),
    ]);

    navigate(`/${orgId}/${applicant.propertyId}/${route}`);
  };

  const getApplicationsApplication = async () => {
    try {
      const { data } = await queryClient.fetchQuery({
        queryKey: ['applicant-application'],
        queryFn: () => getApplicantApplications(),
      });

      return data;
    } catch (e: any) {
      throw new Error(e?.message);
    }
  };

  const authRedirection = async (
    form: Form,
    applicant: Applicant,
    responseId: string[],
    orgId: number,
  ) => {
    try {
      const applicantApplication = await getApplicationsApplication();

      const userResponse = await loadLatestResponse(responseId);
      await initializeAppAndRedirect(
        applicantApplication,
        userResponse as string,
        form,
        applicant,
        orgId,
      );
    } catch (err: any) {
      throw new Error(err?.message);
    }
  };

  return authRedirection;
};

export default useAuthRedirection;
