import { useSearchParams } from 'react-router-dom';

interface Params {
  entrataRedirectLink: string;
  orgId: number;
  rwxDomain: string;
  track: string;
  websiteId: string;
}
const useGetURLParamsObj = () => {
  const [search] = useSearchParams();

  const getParamsObject = (params: any) => Object.fromEntries(params) as Params;

  const getDecodedString = (params: string) => decodeURIComponent(params);

  const getURLParamsObj = () => {
    const params = getParamsObject(search);
    const { entrataRedirectLink } = params;
    if (!entrataRedirectLink) return params;

    const decodeRedirectLink = getDecodedString(entrataRedirectLink);
    return { ...params, entrataRedirectLink: decodeRedirectLink };
  };

  return getURLParamsObj;
};

export default useGetURLParamsObj;
