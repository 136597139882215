"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FloorPlan = void 0;
var FloorPlan = /** @class */ (function () {
    function FloorPlan() {
        this.id = 0;
        this.propertyId = 0;
        this.name = '';
        this.description = '';
        this.numberOfBedrooms = 0;
        this.numberOfBathrooms = 0;
        this.minSquareFeet = 0;
        this.maxSquareFeet = 0;
        this.minRent = 0;
        this.maxRent = 0;
        this.minDeposit = 0;
        this.maxDeposit = 0;
        this.availableUnits = 0;
        this.depositNotes = '';
        this.addons = [];
        this.amenities = [];
        this.media = [];
    }
    return FloorPlan;
}());
exports.FloorPlan = FloorPlan;
