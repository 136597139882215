"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScreeningConditionSubset = void 0;
var enums_1 = require("./enums");
var ScreeningConditionSubset = /** @class */ (function () {
    function ScreeningConditionSubset() {
        this.id = 0;
        this.requiredItems = 0;
        this.conditions = [];
        this.type = enums_1.ScreeningConditionSubsetType.One;
    }
    return ScreeningConditionSubset;
}());
exports.ScreeningConditionSubset = ScreeningConditionSubset;
