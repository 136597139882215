import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetStoreAction } from 'state/commonActions';

export enum CartChargeType {
  AddOn = 'addon',
  Rent = 'rent',
  Utility = 'utility',
  RentDeposit = 'rentdeposit',
  PetDeposit = 'petdeposit',
  ConditionalScreening = 'conditionalscreening',
}

export type CartCharge = {
  id: number | string;
  type: CartChargeType;
  label: string;
  priceNumber: number;
  priceFormatted?: string;
  currencyCode?: string;
  dueNow: boolean;
  dueAtMoveIn: boolean;
  isRecurring: boolean;
  recurringFrequency?: string;
};

export type ShoppingCartReducer = {
  charges: CartCharge[];
};

const initialState: ShoppingCartReducer = {
  charges: [],
};

export const shoppingCartSlice = createSlice({
  name: 'shoppingCart',
  initialState,
  reducers: {
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    setCartCharges: (state, action: PayloadAction<CartCharge[]>) => {
      return { ...state };
      // For now, disabling manually updating the shopping cart. It will allways be
      // loaded from the billing api response.
      // return { ...state, charges: action.payload };
    },
    resetCartCharges: (state, action: PayloadAction<CartCharge[]>) => {
      return { charges: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStoreAction, () => initialState);
  },
});

export const { setCartCharges, resetCartCharges } = shoppingCartSlice.actions;
export default shoppingCartSlice.reducer;
