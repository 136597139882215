import { UploadedFileType } from '@common/entities';
import { destroy, get, post } from 'config/api/config';

const FILES_ENDPOINT = '/uploaded-files';

export interface UploadFileDetails {
  applicationId: string;
  responseId: string;
  propertyId: number;
  formId: string | null;
  fileType: UploadedFileType;
  groupKey: string | null;
  files: any[];
  versionId: string;
}

export const uploadFiles = async (fileData: UploadFileDetails) => {
  const formData = new FormData();
  formData.append('applicationId', fileData.applicationId);
  formData.append('responseId', fileData.responseId);
  formData.append('applicationFormVersionId', fileData.versionId);
  formData.append('propertyId', fileData.propertyId?.toString());
  formData.append('fileType', fileData.fileType);
  formData.append('groupKey', fileData.groupKey ?? '');
  fileData.files.forEach((f) => {
    formData.append('files', f);
  });
  const response = await post(FILES_ENDPOINT, formData);
  return response;
};

export const deleteUploadedFile = async (id: string) => {
  if (!id) throw new Error('File Id is missing.');

  const response = await destroy(`${FILES_ENDPOINT}/${id}`);
  return response;
};

export const getUploadedFiles = async (responseId: string) => {
  if (!responseId) throw new Error('Response Id is missing.');

  const response = await get(`${FILES_ENDPOINT}/responses/${responseId}`);
  return response;
};

export async function getUploadedFileByGroupKeys(
  responseId: string,
  groupKeys: string[],
) {
  if (!responseId) throw new Error('Response Id is missing.');
  if (!groupKeys.length) throw new Error('Group Id is missing.');

  const params = { groupKeys };
  const response = await get(
    `${FILES_ENDPOINT}/responses/${responseId}/groups`,
    { params },
  );
  return response;
}

interface DeleteFilesByGroupKeys {
  responseId: string;
  groupKeys: string[];
}
export const deleteUploadedFileByGroupKeys = async (
  data: DeleteFilesByGroupKeys,
) => {
  const { responseId, groupKeys } = data;
  const response = await destroy(
    `${FILES_ENDPOINT}/responses/${responseId}/groups`,
    {
      params: { groupKeys },
    },
  );
  return response;
};
