import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RelationshipType, ResponseJsonDataName } from '@common/entities';
import { useMutation } from '@tanstack/react-query';
import { NotificationStatus } from 'enums/notification-status';
import {
  resetSaveTrigger,
  setLoadingState,
  setSaveCompleted,
} from 'state/slices/actionsSlice';
import { updateApplicantResponses } from 'state/slices/leadProfileSlice';
import { updateLeasingJourneyErrors } from 'state/slices/navigationSlice';
import { RootState } from 'state/store';

import notification from 'components/notification';
import {
  checkRequiredFieldsForSecondaryAccount,
  patchResponseForId,
  signupSecondaryApplicant,
} from 'services';
import { areObjectsEqual, deepCopy } from 'utils';
import { extractErrorMessage } from 'utils/extractErrorMessage';
import { prepareApplicantResponseData } from 'utils/form';
import { getErrorCount } from 'utils/getErrors';
import handleError from 'utils/handleError';

import { useGetCurrentPageData } from './useGetCurrentPageData';

interface Props {
  activeStepIndex: number;
  responseId?: string;
}
export const useSaveBlankPageData = (props: Props) => {
  const { activeStepIndex, responseId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');

  const {
    getValues,
    formState: { errors },
  } = useFormContext();

  const dispatch = useDispatch();
  const { form } = useSelector((state: RootState) => state.application);
  const { saveTriggerCounter = 0 } = useSelector(
    (state: RootState) => state.actions || {},
  );
  const { currentTabIndex } = useSelector(
    (state: RootState) => state.navigation,
  );
  const { applicant, activeResponse } = useSelector(
    (state: RootState) => state.application,
  );
  const { isLeadProfile } = useSelector(
    (state: RootState) => state.auth ?? { isLeadProfile: false },
  );
  const { applicationId: LPApplicationId } = useSelector(
    (state: RootState) => state.leadProfile.primaryApplicant.currentResponse,
  );

  const { org, propertyId } = applicant;
  const { applicationId, responseId: currentResponseId } = activeResponse;

  const responseDetails = useSelector(
    (state: RootState) =>
      state.leadProfile.applicants[currentTabIndex]?.responseDetails,
  );

  const { pages } = form;
  const { id: orgId } = org;

  const [initialFormValues, setInitialFormValues] = useState({});

  useEffect(() => {
    if (responseDetails) {
      setInitialFormValues(prepareApplicantResponseData(responseDetails));
    }
  }, [responseDetails]);

  const { mutateAsync: createSecondaryApplicantResponse } = useMutation({
    mutationFn: signupSecondaryApplicant,
  });

  const { mutateAsync: updateResponseForId } = useMutation({
    mutationFn: patchResponseForId,
  });

  const { mutateAsync: updateResponseLeadProfile } = useMutation({
    mutationFn: patchResponseForId,
    onSuccess: () => {
      // Optimistically update the response data
      const formValues = getValues();
      const formValuesCopy = deepCopy(formValues);
      dispatch(
        updateApplicantResponses({
          tabIndex: currentTabIndex,
          data: formValuesCopy,
        }),
      );

      dispatch(setSaveCompleted(true));

      if (saveTriggerCounter > 0) {
        dispatch(resetSaveTrigger());
        notification('Save successful', NotificationStatus.SUCCESS);
      }
    },
    onError: (error) => {
      dispatch(setSaveCompleted(true));
      const message = extractErrorMessage(error);
      handleError(
        `Form Viewer - Unable to save response ${error}`,
        `${message}`,
      );
    },
  });

  const prepareData = useGetCurrentPageData();

  function isPropertyIdAvailable() {
    let isAvailable = true;
    if (!propertyId) {
      isAvailable = false;
      handleError(
        `Form Viewer - Property ID not available`,
        `Property ID not available`,
      );
    }
    return isAvailable;
  }

  async function processSecondaryApplicants(data: any) {
    const newData = [...data]; // Create a copy of the 'data' object

    // Coapplicants
    // Get the 'coApplicant' object from the data array, if it has one
    const coappIndex = data.findIndex((a: any) => {
      if (
        Object.keys(a).findIndex(
          (e) => e === ResponseJsonDataName.Coapplicant,
        ) >= 0
      )
        return true;
      return false;
    });
    if (coappIndex >= 0) {
      const coappData = data[coappIndex].coApplicant.coApplicantList;
      for (let i = 0; i < coappData.length; i += 1) {
        const coapplicant = coappData[i];
        if (!coapplicant.responseId) {
          setLoadingText('Creating co-applicants...');
          // eslint-disable-next-line no-await-in-loop
          const coappResponseId = await createSecondaryResponse(
            coapplicant.first_name,
            coapplicant.last_name,
            coapplicant.email,
            coapplicant.phone_number,
            RelationshipType.Coapplicant,
            Number(coapplicant.relationship),
            coapplicant.date_of_birth,
          );
          setLoadingText('');
          // Save the responseId into the primary applicant response
          if (coappResponseId !== '')
            newData[coappIndex].coApplicant.coApplicantList[i].responseId =
              coappResponseId;
        }
      }
    }
    // Guarantors
    // Get the 'guarantor' object from the data array, if it has one
    const guarIndex = data.findIndex((a: any) => {
      if (
        Object.keys(a).findIndex((e) => e === ResponseJsonDataName.Guarantor) >=
        0
      )
        return true;
      return false;
    });
    if (guarIndex >= 0) {
      const guarData = data[guarIndex].guarantor.guarantorList;
      for (let i = 0; i < guarData.length; i += 1) {
        const guarantor = guarData[i];
        if (!guarantor.responseId) {
          setLoadingText('Creating guarantors...');
          // eslint-disable-next-line no-await-in-loop
          const guarResponseId = await createSecondaryResponse(
            guarantor.guarantor_first_name,
            guarantor.guarantor_last_name,
            guarantor.email,
            guarantor.contact_phone_number,
            RelationshipType.Guarantor,
            Number(guarantor.relationship),
            guarantor.dob,
          );
          setLoadingText('');
          // Save the responseId into the primary applicant response
          if (guarResponseId !== '')
            newData[guarIndex].guarantor.guarantorList[i].responseId =
              guarResponseId;
        }
      }
    }
    return newData; // Return the modified copy of 'data'
  }

  function validateSecondaryApplicantFields(data: any) {
    // Coapplicants
    // Get the 'coApplicant' object from the data array, if it has one
    const coappIndex = data.findIndex((a: any) => {
      if (
        Object.keys(a).findIndex(
          (e) => e === ResponseJsonDataName.Coapplicant,
        ) >= 0
      )
        return true;
      return false;
    });
    if (coappIndex >= 0) {
      const coappData = data[coappIndex].coApplicant.coApplicantList;
      for (let i = 0; i < coappData.length; i += 1) {
        const coapplicant = coappData[i];
        if (!coapplicant.responseId) {
          // If any of the required fields are missing for any coapplicant, return false
          const msg = checkRequiredFieldsForSecondaryAccount(
            coapplicant.first_name,
            coapplicant.last_name,
            coapplicant.email,
            coapplicant.phone_number,
            Number(coapplicant.relationship),
          );
          if (msg.length > 0) {
            notification(msg, NotificationStatus.ERROR);
            return false;
          }
        }
      }
    }
    // Guarantors
    // Get the 'guarantor' object from the data array, if it has one
    const guarIndex = data.findIndex((a: any) => {
      if (
        Object.keys(a).findIndex((e) => e === ResponseJsonDataName.Guarantor) >=
        0
      )
        return true;
      return false;
    });
    if (guarIndex >= 0) {
      const guarData = data[guarIndex].guarantor.guarantorList;
      for (let i = 0; i < guarData.length; i += 1) {
        const guarantor = guarData[i];
        if (!guarantor.responseId) {
          // If any of the required fields are missing for any guarantor, return false
          const msg = checkRequiredFieldsForSecondaryAccount(
            guarantor.guarantor_first_name,
            guarantor.guarantor_last_name,
            guarantor.email,
            guarantor.contact_phone_number,
            Number(guarantor.relationship),
          );
          if (msg.length > 0) {
            notification(msg, NotificationStatus.ERROR);
            return false;
          }
        }
      }
    }
    return true;
  }

  async function createSecondaryResponse(
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    relationshipType: RelationshipType,
    customerRelationshipId: number,
    dateOfBirth: string,
  ) {
    const msg = checkRequiredFieldsForSecondaryAccount(
      firstName,
      lastName,
      email,
      phoneNumber,
      customerRelationshipId,
    );

    if (msg.length > 0) {
      notification(msg, NotificationStatus.ERROR);
      return '';
    }
    const { data } = await createSecondaryApplicantResponse({
      applicationId,
      orgId,
      propertyId,
      relationshipType,
      customerRelationshipId,
      primaryApplicationResponseId: currentResponseId,
      applicant: {
        firstName,
        lastName,
        email,
        phoneNumber,
        birthDate: dateOfBirth,
      },
    });

    const secondaryResponseId = data.applicant.responseId;

    return secondaryResponseId;
  }

  async function saveBlankPage(targetIndex: number) {
    setIsLoading(true);
    dispatch(setLoadingState({ isLoading: true }));
    if (!isPropertyIdAvailable()) return false;

    try {
      const errorCount = await getErrorCount(errors);
      let data = prepareData();
      if (!validateSecondaryApplicantFields(data)) return false;
      data = await processSecondaryApplicants(data);

      const requestData: any = {
        currentPageId: pages[activeStepIndex].id,
        targetPageId: responseId
          ? pages[activeStepIndex].id
          : pages[targetIndex].id,
        applicationId: isLeadProfile ? LPApplicationId : applicationId,
        responses: responseId ? [...prepareData()] : [...data],
        propertyId,
        errorCount,
      };

      // Check if the form values have changed to avoid unnecessary updates
      const currentFormValues = deepCopy(getValues());
      const isFormDirty = !areObjectsEqual(
        currentFormValues,
        initialFormValues,
      );

      if (isFormDirty && responseId) {
        await updateResponseLeadProfile({
          responseId,
          responseData: requestData,
        });
      } else {
        dispatch(resetSaveTrigger());
        dispatch(setSaveCompleted(true));
      }

      if (!responseId) {
        await updateResponseForId({
          responseId: currentResponseId as string,
          responseData: requestData,
        });
      }

      dispatch(
        updateLeasingJourneyErrors({
          pageIndex: activeStepIndex,
          count: errorCount,
        }),
      );
      return true;
    } catch (error: any) {
      const msg = error?.message || 'Something went wrong';
      handleError(`Form Viewer - ${error}`, `${msg}`);
      return false;
    } finally {
      dispatch(setLoadingState({ isLoading: false }));
      setIsLoading(false);
      setLoadingText('');
    }
  }

  return {
    isLoading,
    loadingText,
    saveBlankPage,
  };
};
