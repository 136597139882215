"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Rate = void 0;
var Rate = /** @class */ (function () {
    function Rate() {
        this.chargeAmount = '';
        this.chargeCodeType = '';
        this.chargeTime = '';
    }
    return Rate;
}());
exports.Rate = Rate;
