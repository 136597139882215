/* istanbul ignore file */
import React, { useState } from 'react';
import { InfoIcon } from '@chakra-ui/icons';
import { Box, FormControl, FormLabel, Switch, Text } from '@chakra-ui/react';
import { setIsApplicationLocked } from 'state/slices/fieldSettingsSlice';
import { useDispatch } from 'state/storeHooks';

export const CustomDevTools = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const dispatch = useDispatch();

  const toggleDevTools = () => {
    setIsOpen(!isOpen);
  };

  const handleLockToggle = () => {
    const newLockState = !isLocked;
    setIsLocked(newLockState);
    dispatch(setIsApplicationLocked(newLockState));
  };

  const isDevelopment =
    process.env.NODE_ENV === 'development' ||
    window.location.hostname.includes('application-portal.entratadev');

  if (!isDevelopment) {
    return null;
  }

  return (
    <>
      <InfoIcon
        boxSize="16px"
        position="fixed"
        bottom="10px"
        left="10px"
        zIndex="9999"
        onClick={toggleDevTools}
        aria-label="Open DevTools"
      />

      {isOpen && (
        <Box
          position="fixed"
          bottom="30px"
          left="30px"
          zIndex="9999"
          p="4"
          bg="gray.700"
          color="white"
          borderRadius="md"
          boxShadow="md"
          width="300px"
          height="200px"
          overflow="auto"
        >
          <Text>Dev tools</Text>
          <FormControl display="flex" alignItems="center" mt={4}>
            <FormLabel htmlFor="lock-switch" mb="0" color="white">
              Lock Application Fields
            </FormLabel>
            <Switch
              id="lock-switch"
              isChecked={isLocked}
              onChange={handleLockToggle}
            />
          </FormControl>
        </Box>
      )}
    </>
  );
};
