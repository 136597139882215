"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sizeFormFields = sizeFormFields;
function sizeFormFields(type) {
    switch (type) {
        case 'small-dropdown':
            return 1;
        case 'dropdown':
        case 'state-dropdown':
        case 'date':
        case 'zip':
            return 2;
        case 'phone-number':
            return 3;
        default:
            return 4;
    }
}
