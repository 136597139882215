"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScreeningCharge = exports.ScreeningCondition = void 0;
var enums_1 = require("./enums");
var ScreeningCondition = /** @class */ (function () {
    function ScreeningCondition() {
        this.itemNumber = 1;
        this.subsetValueId = 0;
        this.chargeAmount = 0;
        this.name = '';
        this.type = enums_1.ScreeningConditionType.Unknown;
        this.isSatisfied = false;
    }
    return ScreeningCondition;
}());
exports.ScreeningCondition = ScreeningCondition;
var ScreeningCharge = /** @class */ (function () {
    function ScreeningCharge() {
        this.amount = 0;
        this.chargeType = enums_1.ChargeCodeType.Miscellaneous;
        this.itemNumber = 1;
        this.typeId = 0;
        this.subSetValueId = 0;
        this.chargeAmount = 0;
        this.name = '';
    }
    return ScreeningCharge;
}());
exports.ScreeningCharge = ScreeningCharge;
