export function calculateFileSize(bytes: number, decimalPoint = 1) {
  if (bytes === 0) return '0Bytes';
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = parseInt(
    Math.floor(Math.log(bytes) / Math.log(1024)).toString(),
    10,
  );
  if (i === 0) return `${bytes}${sizes[i]}`;
  return `${(bytes / 1024 ** i).toFixed(decimalPoint)}${sizes[i]}`;
}
