import { RelationshipType } from '@common/entities';
import { get, post } from 'config/api/config';

const APPLICANT_APPLICATIONS = '/viewer/applicant/applications';
const APPLICANT_RESET_PASSWORD = '/viewer/applicant/resetpassword';

export interface ApplicationStatusResponse {
  orgId: number;
  propertyName: string;
  propertyId: number;
  applicantId: string;
  relationshipType: RelationshipType;
  applicationId: number;
  moveInDate: string;
  applicationStatus: string;
  name: string;
  applicantCompletedOn: string;
}
export const getApplicantApplications = async () => {
  const response = await get<ApplicationStatusResponse[]>(
    APPLICANT_APPLICATIONS,
    {
      params: { useCurrentProperty: true },
    },
  );
  return response;
};

export const postResetPassword = async (
  token: string,
  orgId: number,
  propertyId: number,
  password: string,
) => {
  const response = await post(APPLICANT_RESET_PASSWORD, {
    token,
    orgId,
    propertyId,
    password,
  });
  return response;
};
