/* istanbul ignore file */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Accordion, Box, Heading, Spacer } from '@chakra-ui/react';
import { PageType, RelationshipType } from '@common/entities';
import styled from '@emotion/styled';
import { useQuery } from '@tanstack/react-query';
import { CartCharge, CartChargeType } from 'state/slices/shoppingCartSlice';
import { RootState } from 'state/store';

import { Loader } from 'components/Loader';
import { getUtilities } from 'services';
import handleError from 'utils/handleError';
import useRefreshScheduledCharges from 'utils/useRefreshScheduledCharges';

import { NewHomeCard } from './new-home-card/NewHomeCard';
import CartItem from './CartItem';
import { ChargesSection } from './ChargesSection';
import { SpecialsCard } from './SpecialsCard';

interface Props {
  chargesType: CartChargeType | null;
  loadFromBillingApi: boolean;
  handleDrawerClose?: () => void;
}

function CartSection({
  chargesType,
  loadFromBillingApi,
  handleDrawerClose,
}: Props) {
  const { isPreviewMode } = useSelector(
    (state: RootState) => state.auth ?? { isPreviewMode: false },
  );
  const { unit, leaseTerm } = useSelector(
    (state: RootState) => state.unitSelection,
  );
  const { charges } = useSelector((state: RootState) => state.shoppingCart);
  const { form, applicant, activeResponse } = useSelector(
    (state: RootState) => state.application,
  );
  const { propertyId } = applicant;
  const { responseId, leaseId, relationshipType } = activeResponse;
  const { pages } = form;

  const { pageId } = useParams();
  const isSecondaryApplicant =
    relationshipType !== undefined &&
    relationshipType !== RelationshipType.Primary;
  const refreshScheduledCharges = useRefreshScheduledCharges({
    propertyId,
    leaseId,
  });
  React.useEffect(() => {
    if (loadFromBillingApi) refreshScheduledCharges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadFromBillingApi, pageId]);

  const isFloorPlanPageAvailable = pages.findIndex(
    (page) => page.type === PageType.FloorPlans,
  );

  const filteredCharges = chargesType
    ? charges?.filter((c) => c.type === chargesType)
    : null;

  function getAllRecurringCharges() {
    return charges?.filter((a) => a.isRecurring === true);
  }

  function getChargesDueNow() {
    return charges?.filter((a) => a.dueNow === true);
  }

  function getChargesDueAtMoveIn() {
    return charges?.filter((a) => a.dueAtMoveIn === true);
  }

  const {
    isPending,
    data = [],
    error,
    fetchStatus,
  } = useQuery({
    queryKey: ['utility-responsibilities'],
    queryFn: () => getUtilities(responseId as string),
    enabled: !isPreviewMode,
  });

  useEffect(() => {
    if (error) handleError(`Form Viewer - ${error}`, `${error.message}`);
  }, [error]);

  if (isPending && fetchStatus === 'fetching') return <Loader />;

  return (
    <Box
      p={{ base: '0 20px', lg: '20px 56px 0 0', sm: '20px 15px' }}
      position="sticky"
      top="10px"
    >
      {!isSecondaryApplicant && isFloorPlanPageAvailable >= 0 && (
        <NewHomeCard handleDrawerClose={handleDrawerClose} />
      )}

      {/* Hide the shopping cart details if no unit an leaseTerm have been selected. */}
      {unit && leaseTerm && (
        <div>
          {!chargesType && (
            <>
              <p>Utility Responsibilities</p>
              <BadgeGroup>
                {data.map((item, index: number) => {
                  return (
                    <Badge key={`utility-responsibilities-${index}`}>
                      {item.name}
                    </Badge>
                  );
                })}
              </BadgeGroup>
              <Accordion defaultIndex={[0, 1, 2]} allowMultiple>
                <ChargesSection
                  recurringCharges={getAllRecurringCharges()}
                  dueNow={getChargesDueNow() ?? []}
                  dueAtMoveIn={getChargesDueAtMoveIn()}
                />
              </Accordion>
            </>
          )}
          {filteredCharges && filteredCharges.length > 0 && (
            <Box
              mt={20}
              borderWidth="2px"
              borderRadius="lg"
              padding={3}
              height={180}
            >
              <Heading size="md" fontWeight={500} marginTop={5}>
                Charges Due
              </Heading>

              <Box mt={2} borderTop="1px solid lightgrey" padding={3}>
                {filteredCharges.map((charge: CartCharge, i: number) => (
                  <CartItem
                    key={i}
                    label={charge.label}
                    priceNumber={charge.priceNumber}
                    priceFormatted={charge.priceFormatted}
                    currencyCode={charge.currencyCode}
                  />
                ))}
                <Spacer marginBottom={2} />
                <CartItem
                  key={filteredCharges?.length ?? 0}
                  label="Total Due"
                  total
                  priceNumber={filteredCharges?.reduce(
                    (a, b) => a + (b.priceNumber || 0),
                    0,
                  )}
                  currencyCode={filteredCharges[0].currencyCode}
                />
              </Box>
            </Box>
          )}
        </div>
      )}
      <SpecialsCard  />
    </Box>
  );
}

export default CartSection;

const BadgeGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 5px 0 20px 0;
  margin: 10px 0 0 0;
`;

const Badge = styled.span`
  margin: 5px 5px 0 0;
  background-color: ${({ theme }: any) => theme.colors.gray.light};
  border-radius: 4px;
  display: block;
  padding: 7px 8px;
  font-size: 10px;
`;
