import { ScreeningConditionSet } from '@common/entities';
import { get, post } from 'config/api/config';

import { Applicant } from 'types/applicant';
import { Form } from 'types/form';

const APPLICATION_ENDPOINT = '/applications';
const NEW_APPLICATION_ENDPOINT = '/viewer/newapplication';
const PROPERTY_APPLICATIONS_ENDPOINT = '/viewer/applications/property';
const SCREENING_CONDITIONS_ENDPOINT = '/viewer/screening-conditions';
const DECLINE_SCREENING_CONDITIONS_ENDPOINT =
  '/viewer/screening-conditions/decline';
const SATISFY_SCREENING_CONDITIONS_ENDPOINT =
  '/viewer/screening-conditions/satisfy';
const APPLICATION_COMPLETE_ENDPOINT = '/viewer/applications/complete';

interface GetApplication {
  applicationId: string;
  versionId: string;
  token?: string | null;
}

interface Payment {
  enabled: boolean;
  processed: boolean;
}
interface ApplicationComplete {
  propertyId: number;
  applicationId: string;
  versionId: string;
  emailAddress: string;
  payment: Payment;
  digitalSignature: string;
  ipAddress: string;
  responseId: string;
}

export const getApplication = async (data: GetApplication) => {
  const { applicationId, versionId } = data;
  const response = await get(
    `${APPLICATION_ENDPOINT}/${applicationId}/versions/${versionId}`,
  );

  return response;
};

export const getApplicationForm = async () => {
  const response = await get<{
    applicant: Applicant;
    form: Form;
  }>('/viewer/application');

  return response?.data;
};

/**
 * Start new application
 * @returns promise
 */
export const postNewApplication = async (
  newApplicationData: any,
): Promise<any> => {
  const response = await post(NEW_APPLICATION_ENDPOINT, newApplicationData);
  return response;
};

export const getPropertyApplications = async (propertyId: number) => {
  if (!propertyId) throw new Error('Property Id is missing.');
  const response = await get(`${PROPERTY_APPLICATIONS_ENDPOINT}/${propertyId}`);

  return response;
};

export const getScreeningConditions = async (responseId: string) => {
  const URL = `${SCREENING_CONDITIONS_ENDPOINT}/${responseId}`;
  const response = await get(URL);
  return response;
};

export const declineScreeningConditions = async (responseId: string) => {
  const URL = `${DECLINE_SCREENING_CONDITIONS_ENDPOINT}`;
  const response = await post(URL, { responseId });
  return response;
};

export const satisfyScreeningConditions = async (
  conditionSets: ScreeningConditionSet[],
  responseId: string,
) => {
  const URL = `${SATISFY_SCREENING_CONDITIONS_ENDPOINT}`;
  const response = await post(URL, {
    selectedConditionSets: conditionSets,
    responseId,
  });
  return response;
};

// Complete Application
export const applicationComplete = async (data: ApplicationComplete) => {
  const response = await post(APPLICATION_COMPLETE_ENDPOINT, data);
  return response;
};
