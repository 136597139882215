interface FormValue {
  [key: string]: any;
}
interface Page {
  pageId: string;
  responses: FormValue;
}
interface Data {
  applicationId: string;
  clientId: number;
  details: string;
  formId: string;
  id: string;
  primaryApplicationResponseId: string;
  relationshipType: string;
}

function getAllPageDataInObject(parsedDetails: any) {
  const returnObj: FormValue = {};
  const { pages } = parsedDetails;

  pages.forEach(({ responses }: Page) => {
    responses.forEach((item: FormValue) => {
      Object.entries(item).forEach(([key, value]) => {
        if (key && !(Array.isArray(value) && value.length === 0)) {
          if (!(key in returnObj)) {
            returnObj[key] = value;
          } else if (Array.isArray(value) && value.length) {
            returnObj[key] = [...returnObj[key], ...value];
          } else if (typeof value !== 'object') {
            returnObj[key] = value;
          } else {
            returnObj[key] = { ...returnObj[key], ...value };
          }
        }
      });
    });
  });
  return returnObj;
}

function transformObject(data: any) {
  if (!Object.keys(data)) return {};

  let returnData = {};
  Object.keys(data).forEach((key) => {
    if (key === 'applicantData') {
      returnData = { ...returnData, ...data[key] };
    } else {
      returnData = { ...returnData, [key]: data[key] };
    }
  });
  return returnData;
}

export function prepareFormInitializingData(data: Data) {
  const parsedDetails = JSON.parse(data?.details);

  if (!Object.keys(parsedDetails).length) return {};
  const returnObj = getAllPageDataInObject(parsedDetails);
  return transformObject(returnObj);
}

export function prepareApplicantResponseData(data: any) {
  if (!Object.keys(data).length) return {};
  const returnObj = getAllPageDataInObject(data);
  return transformObject(returnObj);
}
