"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Media = void 0;
var Media = /** @class */ (function () {
    function Media() {
        this.description = '';
        this.name = '';
        this.caption = '';
        this.format = '';
        this.width = 0;
        this.height = 0;
        this.src = '';
        this.fullsizeUri = '';
        this.thumbnailUri = '';
        this.isDefault = false;
    }
    return Media;
}());
exports.Media = Media;
