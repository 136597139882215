import React, { ReactElement } from 'react';
import { Box, Flex } from '@chakra-ui/react';

interface NotificationBannerProps {
  message: string;
  color: string;
  backgroundColor: string;
  icon: ReactElement;
}

const NotificationBanner: React.FC<NotificationBannerProps> = ({
  message,
  color,
  backgroundColor,
  icon,
}) => {
  return (
    <Flex
      fontSize="xl"
      color={color}
      bg={backgroundColor}
      p={4}
      alignItems="center"
      justifyContent="center"
    >
      <Box mr={2} display="flex" alignItems="center">
        {React.cloneElement(icon, { w: 6, h: 6, 'aria-label': 'icon' })}
      </Box>
      {message}
    </Flex>
  );
};

export default NotificationBanner;
