import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { updateLeasingJourneyErrors } from 'state/slices/navigationSlice';
import { RootState } from 'state/store';

import handleError from 'utils/handleError';

import { useSaveResponse } from './useSaveResponse';

interface Props {
  activeStepIndex: number;
}
export function useSaveSummaryPage({ activeStepIndex }: Props) {
  const { getValues, trigger } = useFormContext();

  const dispatch = useDispatch();
  const { pages } = useSelector((state: RootState) => state.application.form);
  const { leasingJourneyErrors } = useSelector(
    (state: RootState) => state.navigation,
  );
  const { propertyId } = useSelector(
    (state: RootState) => state.application.applicant,
  );
  const { applicationId } = useSelector(
    (state: RootState) => state.application.activeResponse,
  );

  const saveResponse = useSaveResponse();

  function applicationHasError() {
    const errorSteps = leasingJourneyErrors.filter(
      (error) => error && error > 0,
    );
    return errorSteps.length !== 0;
  }

  async function validateSummaryPage() {
    if (!applicationId) throw new Error('Application Id is not available.');
    if (!propertyId) throw new Error('Property Id is not available.');

    const acknowledgeField = getValues('application_acknowledgement');
    const digitalSignField = getValues('digital_signature');

    if (acknowledgeField && digitalSignField !== '') return true;

    await trigger();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    throw new Error('Please fill the pending fields.');
  }

  const checkApplicationError = (isMovingForward: boolean) => {
    const hasError = applicationHasError();
    if (!hasError) return false;

    if (isMovingForward) {
      throw new Error('Please complete all required information.');
    }
    return true;
  };

  const getCurrentAndTargetPageId = (isMovingForward: boolean) => {
    return {
      currentPageId: pages[activeStepIndex].id,
      targetPageId:
        pages[isMovingForward ? activeStepIndex + 1 : activeStepIndex - 1]?.id,
    };
  };

  async function saveSummaryPage(isMovingForward: boolean) {
    try {
      const allowForward = checkApplicationError(isMovingForward);
      if (allowForward) return true;

      validateSummaryPage();
      const { currentPageId, targetPageId } =
        getCurrentAndTargetPageId(isMovingForward);

      await saveResponse({
        currentPageId,
        targetPageId,
        applicationId,
        propertyId,
        responses: [
          {
            digital_signature: getValues('digital_signature'),
            application_acknowledgement: getValues(
              'application_acknowledgement',
            ),
          },
        ],
        errorCount: 0,
      });
      dispatch(
        updateLeasingJourneyErrors({ pageIndex: activeStepIndex, count: 0 }),
      );
      return true;
    } catch (e: any) {
      handleError(
        `Form Viewer - Error saving summary page: ${e}`,
        `Error saving summary page`,
      );
      return false;
    }
  }

  return saveSummaryPage;
}
