/* istanbul ignore file */
interface AxiosError extends Error {
  response?: {
    data?: {
      message?: string;
    };
    status?: number;
  };
}

export function extractErrorMessage(error: unknown): string {
  const axiosError = error as AxiosError;
  if (axiosError.response?.data?.message) {
    return axiosError.response.data.message;
  }

  const standardError = error as Error;
  return standardError.message || 'An unspecified error occurred.';
}
