"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentAccount = void 0;
var PaymentAccount = /** @class */ (function () {
    function PaymentAccount() {
        this.id = 0;
        this.paymentTypeId = 0;
        this.paymentTypeName = '';
        this.mask = '';
        this.name = '';
    }
    return PaymentAccount;
}());
exports.PaymentAccount = PaymentAccount;
